import React from "react";
import SVG from "../../../components/SVG";

const Chart13 = () => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.94 309.16">
      <g>
        <path
          d="M10.19,162.74v.84H2.12v-.84H5.58v-.9H3.14V161H5.58v-.81H3.51a6.06,6.06,0,0,1-1.09,1.4,5.77,5.77,0,0,0-.49-.9A5.87,5.87,0,0,1,.78,164a3.42,3.42,0,0,0-.78-.65,5.67,5.67,0,0,0,1-3.65v-2.14H2.39a6,6,0,0,0-.45-.44c.83-.14,1.79-.35,2.69-.57-.79-.22-1.58-.42-2.31-.57l.56-.43H1.07v-.81H5a6.39,6.39,0,0,0-.5-.71l.71-.34A5.64,5.64,0,0,1,6,154.75H9.69v.81H8.05l.49.31c-.45.21-1,.44-1.55.64.73.21,1.42.43,1.95.62l-.5.44H10v.84H2v1.31c0,.32,0,.66,0,1a5.64,5.64,0,0,0,1.39-2.18l.88.25a5.45,5.45,0,0,1-.26.62H5.58v-.82h1v.82H9.44v.81H6.59V161H9.15v.8H6.59v.9ZM8,157.57c-.58-.21-1.33-.44-2.12-.68-.9.27-1.83.49-2.68.68Zm-4.57-2c.8.18,1.67.4,2.51.64a11.38,11.38,0,0,0,1.69-.64Z"
          fill="#002a5e"
        />
        <path
          d="M15.21,162.16a9.56,9.56,0,0,1-3.27,1.42,4.84,4.84,0,0,0-.62-.75,9.41,9.41,0,0,0,3.15-1ZM21,160.49v.85H16.63v2.56h-1v-2.56H11.36v-.85h4.27v-.66H12.39v-.77h3.24v-.66H11.85v-.81h2.21a5.65,5.65,0,0,0-.49-.94l.64-.16h-2.8v-.81h1.64a8.46,8.46,0,0,0-.81-1.49l.89-.31a7.25,7.25,0,0,1,.88,1.58l-.58.22h1.23v-1.89h1v1.89h1.09v-1.89h1v1.89h1.2l-.43-.14a11,11,0,0,0,.77-1.63l1.05.27a15.19,15.19,0,0,1-.91,1.5h1.57v.81H18.05l.86.22c-.24.33-.49.63-.71.88h2.33v.81h-3.9v.66H20v.77H16.63v.66Zm-6.39-4a4.58,4.58,0,0,1,.51,1l-.49.13h3l-.39-.12a8.39,8.39,0,0,0,.61-1Zm3.32,5.25c1,.35,2.31.88,3.05,1.23l-.69.6c-.68-.34-2-.93-3-1.31Z"
          fill="#002a5e"
        />
        <path
          d="M29.21,154a6.93,6.93,0,0,0,3.12,4,4.88,4.88,0,0,0-.74.93,6.81,6.81,0,0,1-1-.79c0,.07,0,.15,0,.19-.14,3.34-.28,4.62-.67,5a1.18,1.18,0,0,1-.9.45,13.68,13.68,0,0,1-1.71,0,2.35,2.35,0,0,0-.33-1c.66.06,1.29.07,1.55.07a.6.6,0,0,0,.47-.15c.25-.26.39-1.3.5-3.86H27c-.28,2.13-1,4.07-3.82,5.1a3,3,0,0,0-.66-.91,4.48,4.48,0,0,0,3.37-4.19H23.78v-.76a7.25,7.25,0,0,1-.9.86,6.34,6.34,0,0,0-.87-.75,7.83,7.83,0,0,0,2.78-4l1.07.25A10.81,10.81,0,0,1,24,157.87h6.34a9.13,9.13,0,0,1-1.87-2.94H26.6v-1Z"
          fill="#002a5e"
        />
        <path
          d="M36.72,160.35c-.18-.36-.53-1-.87-1.5v5.05h-1v-4.79a10.67,10.67,0,0,1-1.35,2.83A4.81,4.81,0,0,0,33,161a10.86,10.86,0,0,0,1.75-3.93H33.3v-1h1.57v-2.3h1v2.3h1.57v1H35.85v.51c.4.5,1.28,1.67,1.48,2Zm6.45-1.88H41.66v5.43h-1v-5.43H38.9c0,1.66-.2,3.93-1.21,5.4a4,4,0,0,0-.85-.51c1-1.44,1.09-3.53,1.09-5.06V155a17.94,17.94,0,0,0,3.76-1.08l.87.8a19.28,19.28,0,0,1-3.66,1v1.78h4.27Z"
          fill="#002a5e"
        />
        <path
          d="M45.46,159.44H48v3.38H45.46v.72h-.91v-8.12h.91a11.71,11.71,0,0,0,.4-1.63l1.12.13c-.19.5-.39,1.05-.6,1.5h1.53v3.14H45.46ZM47,156.29H45.46v1.4H47Zm-1.53,4V162h1.63v-1.63Zm6.21-5v1.19h2.15v4.79c0,.42-.06.7-.34.85a2.72,2.72,0,0,1-1.23.16,3.07,3.07,0,0,0-.26-.88h.75c.11,0,.14,0,.14-.15v-3.86H51.67v6.5h-1v-6.5H49.6v4.87h-.93v-5.78h2v-1.19H48.28v-.93h5.85v.93Z"
          fill="#002a5e"
        />
        <path
          d="M4.83,176.32a6.86,6.86,0,0,0,2.44.31c.66,0,3.83,0,4.65,0a3.13,3.13,0,0,0-.42,1H7.28c-2.2,0-3.52-.32-4.34-1.69a6.46,6.46,0,0,1-.57,2,6.46,6.46,0,0,0-.83-.47c.61-1.08.69-2.81.72-4.72l.91.07c0,.53,0,1-.06,1.53a3.17,3.17,0,0,0,.81,1.43V172H1.84v-.91H3.75v-1.2H2.06V169H3.75V167.8h1V169H6.34v.89H4.7v1.2H6.55v.38A3.12,3.12,0,0,0,8,169.11H6.68v-.87h4.78s0,.25,0,.37c-.08,1.92-.2,2.69-.44,3a.92.92,0,0,1-.68.32,11,11,0,0,1-1.19,0,2.3,2.3,0,0,0-.26-.87,8.26,8.26,0,0,0,1,.05.43.43,0,0,0,.33-.11,4.49,4.49,0,0,0,.27-1.87H9a4.14,4.14,0,0,1-2,3.16l-.5-.41V172H4.83v1.4H6.42v.89H4.83ZM7,172.44h4.22v3.39H7ZM7.94,175H10.2v-1.72H7.94Z"
          fill="#002a5e"
        />
        <path
          d="M15.57,173.17a10.21,10.21,0,0,1-.58,2.32c.69,1.17,2,1.35,3.93,1.35,1.16,0,2.84-.05,4-.16a3.13,3.13,0,0,0-.35.94c-1.06.07-2.44.1-3.67.1-2.12,0-3.44-.22-4.29-1.41a5.37,5.37,0,0,1-1.35,1.62,3.27,3.27,0,0,0-.69-.7,5.24,5.24,0,0,0,2-3.42h-.93A1.13,1.13,0,0,0,13,174a5.64,5.64,0,0,0-.35-.87,1,1,0,0,0,.54-.34,15.94,15.94,0,0,0,1.21-1.62H12.78v-.82H15l.14-.06.64.29c-.43.63-1.17,1.7-1.77,2.45h1l.17,0ZM14.42,170a12.34,12.34,0,0,0-1.3-1.77l.78-.45a12.89,12.89,0,0,1,1.32,1.67Zm2.51,6.24H16V171.5h.81v-3.35h4.59v3.35h.84v3.81c0,.43-.08.67-.37.8a3.46,3.46,0,0,1-1.36.15,2.74,2.74,0,0,0-.24-.82c.39,0,.77,0,.88,0s.16,0,.16-.14v-3H16.93Zm3.63-1H18.48v.38h-.76V172.9h2.84Zm-2.81-3.76h.87v-1.78h1.9v-.79H17.75Zm2,2.08H18.48v1h1.31Zm.73-3.18H19.39v1.1h1.13Z"
          fill="#002a5e"
        />
        <path
          d="M24.19,176H26v-5.41H24.51v-.8a4.82,4.82,0,0,0,1.76-.62h1V176h1.58V177H24.19Z"
          fill="#002a5e"
        />
        <path
          d="M30,173.06c0-2.66,1-4.06,2.62-4.06s2.62,1.42,2.62,4.06-1,4.11-2.62,4.11S30,175.71,30,173.06Zm4,0c0-2.27-.59-3.06-1.41-3.06s-1.41.79-1.41,3.06.58,3.11,1.41,3.11S34.06,175.32,34.06,173.06Z"
          fill="#002a5e"
        />
        <path
          d="M36.3,173.06c0-2.66,1-4.06,2.62-4.06s2.62,1.42,2.62,4.06-1,4.11-2.62,4.11S36.3,175.71,36.3,173.06Zm4,0c0-2.27-.59-3.06-1.41-3.06s-1.41.79-1.41,3.06.58,3.11,1.41,3.11S40.33,175.32,40.33,173.06Z"
          fill="#002a5e"
        />
        <path
          d="M46.07,168.14A17.44,17.44,0,0,1,45,170.42v7.45H44V172a10.25,10.25,0,0,1-.9,1.09,6.69,6.69,0,0,0-.58-1,11.85,11.85,0,0,0,2.61-4.24Zm6.43,8.33v1H45.66v-1h3.82a41.13,41.13,0,0,0,1-5.3l1.07.18c-.3,1.66-.72,3.71-1.12,5.12Zm-.4-5.76h-6v-1h6Zm-4.61,5.34a43.65,43.65,0,0,0-.7-4.6l.92-.21a43.24,43.24,0,0,1,.79,4.51Zm1.25-6.38a10,10,0,0,0-.5-1.74l1-.24a14.33,14.33,0,0,1,.55,1.69Z"
          fill="#002a5e"
        />
        <circle
          cx="16.75"
          cy="122.58"
          r="2.29"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M21.43,130.66a4.68,4.68,0,1,0-9.35,0Z"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <circle
          cx="27.15"
          cy="131.54"
          r="2.29"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M31.82,139.62a4.68,4.68,0,0,0-9.35,0Z"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <circle
          cx="37.54"
          cy="122.58"
          r="2.29"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M42.21,130.66a4.67,4.67,0,1,0-9.34,0Z"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <circle
          cx="27.15"
          cy="116.31"
          r="2.29"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M31.82,124.39a4.68,4.68,0,1,0-9.35,0Z"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M29.27,104.47v2.61a19.85,19.85,0,0,1,0,39.48v2.61a22.45,22.45,0,0,0,0-44.7ZM4.69,126.82A22.47,22.47,0,0,0,25,149.17v-2.6a19.87,19.87,0,0,1,0-39.5v-2.6A22.48,22.48,0,0,0,4.69,126.82Z"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M245.59,158.93a7.89,7.89,0,0,1-3.12,4.82,4.54,4.54,0,0,0-.81-.77,6.39,6.39,0,0,0,2.64-3.34h-2.4v-1h2.84l.17,0Zm5.89-.89a27.35,27.35,0,0,1-2.79,2.28,6.78,6.78,0,0,0,3.17,2.53,4.21,4.21,0,0,0-.71.9,7.66,7.66,0,0,1-3.81-3.84v3.27c0,.58-.13.88-.53,1a5.29,5.29,0,0,1-1.92.19,3.72,3.72,0,0,0-.36-1c.6,0,1.27,0,1.46,0s.25-.07.25-.26v-5.63h-2.9v-1h4v.88a10.83,10.83,0,0,0,.86,2.11,15.85,15.85,0,0,0,2.34-2.17Zm-3.19-1.36a19.42,19.42,0,0,0-4-1.49l.51-.85a18.9,18.9,0,0,1,4,1.39Z"
          fill="#002a5e"
        />
        <path
          d="M254,161.54a14.47,14.47,0,0,1-.54,2.81,3.67,3.67,0,0,0-.77-.26,12.36,12.36,0,0,0,.51-2.7Zm1.79-.45c0-.18-.07-.38-.11-.61A10.4,10.4,0,0,0,253,161a6.12,6.12,0,0,0-.29-.85c.23-.07.46-.33.78-.75a11,11,0,0,0,.77-1.13,5.06,5.06,0,0,0-1.34.24,7.5,7.5,0,0,0-.28-.84c.19,0,.36-.26.57-.6a13.08,13.08,0,0,0,1.26-2.76l.86.35a17.12,17.12,0,0,1-1.46,2.83l.92-.08c.25-.48.52-1,.74-1.48l.79.46a21.45,21.45,0,0,1-2.22,3.5l1.34-.18c-.09-.33-.19-.67-.29-1l.71-.19a16.1,16.1,0,0,1,.6,2.3Zm-.81.28a20.11,20.11,0,0,1,.29,2.52l-.76.14a22,22,0,0,0-.23-2.54Zm1-.27a17.18,17.18,0,0,1,.57,2l-.69.22a16.87,16.87,0,0,0-.54-2Zm4.73,1.73c.78.28,1.61.61,2.15.85l-.42.73c-.59-.28-1.51-.7-2.33-1l.33-.58h-1.79l.61.57c-.84.38-1.91.79-2.67,1.07a5.09,5.09,0,0,0-.39-.71,19,19,0,0,0,2.23-.93h-1.6V159.6h5.45v3.23ZM259,155.55h-2.71v-.69H259v-.54h1v.54h2.57v.69H260V156h2.19v.66h-5.47V156H259Zm-2.66,1.52h6.19v2.11h-6.19Zm.85,1.45h1v-.79h-1Zm.43,2h3.6v-.4h-3.6Zm0,.83h3.6V161h-3.6Zm0,.87h3.6v-.44h-3.6Zm2.34-3.75v-.79H259v.79Zm1.68-.79h-.9v.79h.9Z"
          fill="#002a5e"
        />
        <path
          d="M273.71,162h-2.54v1.35c0,.49-.1.75-.5.9a6.39,6.39,0,0,1-1.8.13,3.34,3.34,0,0,0-.32-.88c.61,0,1.23,0,1.4,0s.22-.06.22-.19V162h-3.65a8.52,8.52,0,0,1,1.49,1.2l-.75.66a9.24,9.24,0,0,0-1.72-1.43L266,162H263.8v-.82h6.37v-.56l-5.9.12,0-.77,4,0v-.51H265v-3.05h3.28v-.52h-4.12v-.79h4.12v-.77h1v.77h4.06v.79h-4.06v.52h3.39v3.05h-1a8.39,8.39,0,0,1,1.7,1l-.6.55a6.09,6.09,0,0,0-.57-.41l-1,0v.6h2.54Zm-7.79-4.41h2.31v-.55h-2.31Zm2.31,1.2v-.57h-2.31v.57Zm1-1.75v.55h2.39v-.55Zm2.39,1.18h-2.39v.57h2.39ZM269.23,160l1.77,0a4.77,4.77,0,0,0-.67-.28l.26-.21h-1.36Z"
          fill="#002a5e"
        />
        <path
          d="M283.67,158.91c-.48.38-1,.77-1.56,1.14a5,5,0,0,0,2.75,3.08,3.64,3.64,0,0,0-.67.83,5.82,5.82,0,0,1-2.88-3.39l-.44.26c.35,1.37.09,2.68-.5,3.14a1.52,1.52,0,0,1-1.19.44c-.26,0-.59,0-.92,0a2.48,2.48,0,0,0-.27-1c.38,0,.74,0,1,0a.77.77,0,0,0,.66-.24,2,2,0,0,0,.38-1.56,16.19,16.19,0,0,1-4.57,2.24,4,4,0,0,0-.54-.84,13,13,0,0,0,4.94-2.35,5.05,5.05,0,0,0-.23-.61,18.64,18.64,0,0,1-4,1.86,4.81,4.81,0,0,0-.52-.81,13.54,13.54,0,0,0,4.13-1.72,4.16,4.16,0,0,0-.43-.53,15.17,15.17,0,0,1-3.1,1.23,5,5,0,0,0-.54-.81,12.3,12.3,0,0,0,3.73-1.41h-2.24v-.88h6.23v.88h-2.61a7.79,7.79,0,0,1-.71.54,4.64,4.64,0,0,1,1.05,1.54,18.17,18.17,0,0,0,2.25-1.76Zm-7.52-1.31h-1v-2.35h4a6.67,6.67,0,0,0-.34-.71l1.06-.31a7,7,0,0,1,.52,1h4v2.35h-1v-1.42h-7.21Z"
          fill="#002a5e"
        />
        <path
          d="M252,176.81c2.26-2.21,3.62-3.62,3.62-4.85a1.28,1.28,0,0,0-1.37-1.43,2.22,2.22,0,0,0-1.6.9l-.71-.7a3.15,3.15,0,0,1,2.46-1.21,2.26,2.26,0,0,1,2.44,2.37c0,1.45-1.31,2.9-3,4.66a12,12,0,0,1,1.32-.08h2.06v1.08H252Z"
          fill="#002a5e"
        />
        <path
          d="M258.23,176.81c2.26-2.21,3.62-3.62,3.62-4.85a1.28,1.28,0,0,0-1.37-1.43,2.22,2.22,0,0,0-1.6.9l-.71-.7a3.15,3.15,0,0,1,2.46-1.21,2.26,2.26,0,0,1,2.44,2.37c0,1.45-1.31,2.9-3,4.66a11.83,11.83,0,0,1,1.32-.08h2.06v1.08h-5.23Z"
          fill="#002a5e"
        />
        <path
          d="M268,168.66a17.37,17.37,0,0,1-1.06,2.28v7.46h-1v-5.86a8.29,8.29,0,0,1-.9,1.09,7.55,7.55,0,0,0-.57-1,12,12,0,0,0,2.61-4.24Zm6.44,8.33v1h-6.84v-1h3.81a39.33,39.33,0,0,0,1-5.29l1.07.17c-.29,1.66-.71,3.71-1.11,5.12Zm-.41-5.75h-6v-1h6Zm-4.61,5.33a41.3,41.3,0,0,0-.69-4.6l.91-.21a43.51,43.51,0,0,1,.8,4.51Zm1.26-6.38a11,11,0,0,0-.51-1.74l1-.24a14.33,14.33,0,0,1,.55,1.69Z"
          fill="#002a5e"
        />
        <path
          d="M248.72,120.53h6.72v1.84h-4.53v2.53h3.85v1.83h-3.85v2.93h4.69v1.84h-6.88Z"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M257.67,130.05l1.26-1.53a5.63,5.63,0,0,0,3.37,1.26c1.14,0,2.15-.38,2.15-1.18s-.95-1.17-2.1-1.56l-1.57-.54a3.38,3.38,0,0,1-2.59-3c0-1.82,1.73-3.17,4.16-3.17a6.06,6.06,0,0,1,4,1.45l-1.16,1.44a4.75,4.75,0,0,0-2.91-1c-1,0-1.93.37-1.93,1.17s1.07,1.1,2.15,1.45l1.55.54c1.45.48,2.58,1.42,2.58,3,0,1.87-1.67,3.28-4.4,3.28A7,7,0,0,1,257.67,130.05Z"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M268.66,126.07c0-3.61,2.34-5.73,5.25-5.73a4.62,4.62,0,0,1,3.39,1.4l-1.15,1.4a2.93,2.93,0,0,0-2.16-.92c-1.82,0-3.1,1.42-3.1,3.77s1.1,3.82,3.26,3.82a2.22,2.22,0,0,0,1.36-.41v-2.16h-1.94v-1.8h3.9v4.95a5.19,5.19,0,0,1-3.56,1.32C270.94,131.71,268.66,129.72,268.66,126.07Z"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M240.49,126.82a22.47,22.47,0,0,0,20.33,22.35v-2.61a19.85,19.85,0,0,1,0-39.48v-2.61A22.48,22.48,0,0,0,240.49,126.82Zm24.58-22.35v2.6a19.86,19.86,0,0,1,0,39.5v2.6a22.45,22.45,0,0,0,0-44.7Z"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M159.51,288.07a12.69,12.69,0,0,1-1.07,4.14,4.84,4.84,0,0,0,2,2.12,3.61,3.61,0,0,0-.65.8,5.33,5.33,0,0,1-1.84-2,6.54,6.54,0,0,1-2.44,2.1,3.75,3.75,0,0,0-.36-.59l-.3.26a10.88,10.88,0,0,0-1.36-.66,10.06,10.06,0,0,1-2.9,1,2.24,2.24,0,0,0-.41-.67,10.37,10.37,0,0,0,2.35-.66c-.53-.19-1.06-.36-1.54-.49a10.66,10.66,0,0,0,.63-.9H150.3v-.73H152c.11-.21.22-.4.32-.6h-1.58v-1.84h2v-.42H151v-1.21h-.75v-.76H151v-1.13h1.78v-.68h.8v.68h1.89v1.13h.6v.76h-.6v1.21h-1.89v.42h2.09v.53a15.68,15.68,0,0,0,1.46-4.73l.86.16c-.12.65-.27,1.3-.43,1.94h2.77v.9Zm-7.89,2.42h1.14v-.6h-1.14Zm.15-4.06v.51h1v-.51Zm1,1.74v-.58h-1v.58Zm-.24,4.27c-.11.19-.23.36-.33.53.38.11.76.24,1.14.37a1.89,1.89,0,0,0,.71-.9Zm2.38,0a2.51,2.51,0,0,1-.72,1.24,10,10,0,0,1,1.17.55,5.31,5.31,0,0,0,2.13-2.07,15.31,15.31,0,0,1-.74-2.6c-.17.39-.35.76-.53,1.09a4.84,4.84,0,0,0-.56-.53v1h-2.39c-.1.2-.21.39-.32.6h1.21v-.37H155v.37h1v.73Zm-1.34-6v.51h1.07v-.51Zm1.07,1.74v-.58h-1.07v.58Zm-1.07,1.72v.6h1.2v-.6Zm3.72-1.82,0,.09a16.55,16.55,0,0,0,.73,3,12.52,12.52,0,0,0,.66-3.06Z"
          fill="#002a5e"
        />
        <path
          d="M163.4,291v3.12c0,.49-.1.77-.38.92a3.36,3.36,0,0,1-1.42.19,4.29,4.29,0,0,0-.28-.94c.44,0,.85,0,1,0s.2,0,.2-.18v-2.84l-1.18.37-.24-1c.39-.09.89-.23,1.42-.38v-2.07h-1.24v-1h1.24v-2.14h.91v2.14h.95v1h-.95V290l1-.29.14.92Zm2.17-.82a9,9,0,0,1-1.17,5,3,3,0,0,0-.75-.52,7.9,7.9,0,0,0,1-4.53V287h2.43v-1.88h1v.6h2.54v.74h-2.54V287h2.33l.14,0,.64.14a11.52,11.52,0,0,1-.54,1.63l-.77-.18a6.49,6.49,0,0,0,.26-.81h-2.24v.52l1.64-.15.07.59-1.71.17v0c0,.25,0,.35.3.35h1.4a4.21,4.21,0,0,0,.83-.07c0,.23.05.44.07.64a3.44,3.44,0,0,1-.85.06H168c-.73,0-.93-.27-1-.81l-1.23.11-.08-.75,1.29-.12v-.59h-1.49Zm5.62,1.52c-.35.19-.76.39-1.18.57a3.38,3.38,0,0,0,1.42,2,2.72,2.72,0,0,0-.58.64,4.17,4.17,0,0,1-1.45-2.38l-.46.18a2.18,2.18,0,0,1-.21,2.2,1,1,0,0,1-.83.33h-.56a1.62,1.62,0,0,0-.22-.74l.64,0a.43.43,0,0,0,.39-.15,1.24,1.24,0,0,0,.18-1,10.1,10.1,0,0,1-2.64,1.48,4,4,0,0,0-.4-.58,8.17,8.17,0,0,0,2.9-1.51c-.05-.1-.1-.22-.16-.33a10.15,10.15,0,0,1-2.19,1.08,1.84,1.84,0,0,0-.36-.53,7.52,7.52,0,0,0,2.27-1l-.26-.27a7.86,7.86,0,0,1-1.51.59,4.86,4.86,0,0,0-.31-.53,9.1,9.1,0,0,0,2.2-.87h-2.05v-.69h5.34v.69h-2.32a8.71,8.71,0,0,1-.74.51,2.79,2.79,0,0,1,.59.71,19,19,0,0,0,1.92-1Z"
          fill="#002a5e"
        />
        <path
          d="M174.15,290a12.38,12.38,0,0,0-2-1.12l.53-.78a11,11,0,0,1,2,1.05Zm-1.7,4.26a31.08,31.08,0,0,0,1.88-3.41l.8.67c-.52,1.13-1.17,2.4-1.76,3.42Zm.81-9.06a14.43,14.43,0,0,1,1.94,1.09l-.6.83a11.89,11.89,0,0,0-1.91-1.16Zm8.67,8.72c0,.56-.11.86-.45,1a4,4,0,0,1-1.73.2,4.82,4.82,0,0,0-.32-1c.56,0,1.11,0,1.3,0s.22-.07.22-.25v-7.5h-4.67v8.74h-1v-9.66h6.62Zm-1.51-5.75h-3.58v-.84h3.58Zm-2.51,4.48v.67h-.8v-4.21h3v3.54Zm0-2.68v1.82h1.39V290Z"
          fill="#002a5e"
        />
        <path
          d="M191.84,294.1c.43,0,.49-.18.54-1.51a2.9,2.9,0,0,0,1,.42c-.12,1.65-.4,2.06-1.39,2.06h-1.63c-1.16,0-1.44-.31-1.44-1.34v-2h-1.59c-.25,1.75-.93,2.88-3.51,3.46a3.08,3.08,0,0,0-.58-.9c2.26-.44,2.79-1.25,3-2.56h-1.42v-6.3h7v6.3h-1.86v2c0,.33.08.37.52.37Zm-6.08-6.83h5v-.93h-5Zm0,1.77h5v-.94h-5Zm0,1.79h5v-.94h-5Z"
          fill="#002a5e"
        />
        <path
          d="M203.75,285.51v9.62h-1v-.51h-7.06v.56h-.94v-9.67Zm-1,8.3V286.3h-7.06v7.51Zm-.31-1.69H201V293c0,.36-.09.48-.35.56a5,5,0,0,1-1.33.09,2,2,0,0,0-.21-.55H200c.12,0,.15,0,.15-.14v-.85H196v-.59h4.08v-.37H201v.37h1.49Zm-1.22-2a6.78,6.78,0,0,1,1,1l-.54.42a5.77,5.77,0,0,0-.43-.47l-5.09.14,0-.59,2.6,0v-.45h-2.26v-2.22h2.26v-.39h-2.62v-.6h2.62v-.45h.84v.45h2.68v.6h-2.68v.39H202v2.22ZM198,293.47a5.4,5.4,0,0,0-.85-1l.63-.33a5.17,5.17,0,0,1,.92,1Zm-.64-4.67h1.45v-.41h-1.45Zm1.45.87v-.42h-1.45v.42Zm.84-1.28v.41h1.52v-.41Zm1.52,1.28v-.42h-1.52v.42Zm-.36.9-.2-.17.41-.26h-1.37v.45Z"
          fill="#002a5e"
        />
        <path
          d="M208.9,285.89c-.31.92-.7,2.06-1,2.92a3.39,3.39,0,0,1,.91,2.29,1.57,1.57,0,0,1-.46,1.32,1.64,1.64,0,0,1-.59.21,5.75,5.75,0,0,1-.73,0,2,2,0,0,0-.23-.88,3.61,3.61,0,0,0,.56,0,.56.56,0,0,0,.33-.1c.16-.11.21-.36.21-.69a3,3,0,0,0-.9-2.06c.29-.75.57-1.72.77-2.46h-1.18v8.72h-.9v-9.64h2.43l.15,0Zm6.17,3c-.42.31-.9.64-1.39.93a8.63,8.63,0,0,0,1.8,3.18,3.49,3.49,0,0,0-.66.67,10.57,10.57,0,0,1-1.85-3.42l-.13.06c.55,1.79.3,3.67-.33,4.25a1.4,1.4,0,0,1-1.17.48c-.21,0-.57,0-.87,0a2.39,2.39,0,0,0-.25-.91c.36,0,.71,0,.94,0a.67.67,0,0,0,.62-.27,2.91,2.91,0,0,0,.4-1.88,12.85,12.85,0,0,1-3,1.76,3.35,3.35,0,0,0-.61-.75,8.59,8.59,0,0,0,3.45-2,4.58,4.58,0,0,0-.18-.73,14.56,14.56,0,0,1-2.31,1.46A3.88,3.88,0,0,0,209,291a9.53,9.53,0,0,0,2.54-1.47,4.78,4.78,0,0,0-.46-.74,11.49,11.49,0,0,1-1.93,1.16,4.43,4.43,0,0,0-.6-.74,8.15,8.15,0,0,0,2.47-1.46h-2.22v-.89h2a6.75,6.75,0,0,0-1.17-1.24l.76-.48a6.1,6.1,0,0,1,1.31,1.35l-.55.37h1.12a9.13,9.13,0,0,0,1.1-1.77l1,.3a15.72,15.72,0,0,1-1,1.47h1.88v.88h-3a4.72,4.72,0,0,1-.5.5,4.86,4.86,0,0,1,.83,1.3,13.67,13.67,0,0,0,1.87-1.35Z"
          fill="#002a5e"
        />
        <path
          d="M219.34,293.59a6.83,6.83,0,0,0,2.44.31c.66,0,3.83,0,4.64,0a3.65,3.65,0,0,0-.42,1h-4.21c-2.2,0-3.52-.32-4.35-1.69a6.19,6.19,0,0,1-.57,2,4.88,4.88,0,0,0-.82-.46,10.94,10.94,0,0,0,.71-4.72l.91.06c0,.53,0,1,0,1.53a3.26,3.26,0,0,0,.8,1.43V289.3h-2.08v-.91h1.92v-1.2h-1.7v-.89h1.7v-1.23h.94v1.23h1.64v.89H219.2v1.2h1.85v.39a3.17,3.17,0,0,0,1.5-2.4h-1.37v-.87H226s0,.25,0,.37c-.09,1.92-.2,2.69-.44,3a1,1,0,0,1-.68.32,11.07,11.07,0,0,1-1.19,0,2.15,2.15,0,0,0-.27-.87,8.35,8.35,0,0,0,1,.05.4.4,0,0,0,.33-.11c.14-.16.21-.64.28-1.87h-1.44a4.14,4.14,0,0,1-2,3.16c-.11-.1-.32-.27-.51-.41v.17h-1.71v1.4h1.59v.89h-1.59Zm2.16-3.88h4.23v3.39H221.5Zm.95,2.56h2.26v-1.72h-2.26Z"
          fill="#002a5e"
        />
        <path
          d="M230.07,290.44a10.79,10.79,0,0,1-.57,2.32c.68,1.17,2,1.35,3.93,1.35,1.15,0,2.84-.05,4-.16a3.41,3.41,0,0,0-.34.94c-1.07.07-2.44.1-3.67.1-2.13,0-3.45-.22-4.29-1.41a5.14,5.14,0,0,1-1.36,1.62,3,3,0,0,0-.68-.7,5.24,5.24,0,0,0,2-3.42h-.94a1.09,1.09,0,0,0-.64.17,5.66,5.66,0,0,0-.36-.87.94.94,0,0,0,.54-.34,15.94,15.94,0,0,0,1.21-1.62h-1.58v-.82h2.22l.13-.06.64.29c-.43.63-1.16,1.7-1.76,2.45h.95l.16,0Zm-1.14-3.15a14.09,14.09,0,0,0-1.3-1.77l.78-.45a12.89,12.89,0,0,1,1.32,1.67Zm2.51,6.24h-.89v-4.75h.81v-3.36H236v3.36h.83v3.8c0,.43-.07.67-.36.8a3.52,3.52,0,0,1-1.36.15,3.1,3.1,0,0,0-.25-.82,5.2,5.2,0,0,0,.88,0c.13,0,.17,0,.17-.14v-3h-4.42Zm3.63-1H233v.38h-.76v-2.74h2.84Zm-2.81-3.75h.87V287H235v-.79h-2.76Zm2,2.07H233v1h1.31Zm.72-3.18H233.9v1.11H235Z"
          fill="#002a5e"
        />
        <path
          d="M165.79,307.33l.61-.81a2.65,2.65,0,0,0,1.92.89c.91,0,1.54-.5,1.54-1.29s-.57-1.43-2.4-1.43v-.94c1.6,0,2.12-.58,2.12-1.34a1.1,1.1,0,0,0-1.24-1.13,2.3,2.3,0,0,0-1.62.77l-.66-.79a3.35,3.35,0,0,1,2.32-1c1.46,0,2.48.74,2.48,2a1.9,1.9,0,0,1-1.4,1.84v.06a2,2,0,0,1,1.68,2c0,1.41-1.23,2.25-2.71,2.25A3.37,3.37,0,0,1,165.79,307.33Z"
          fill="#002a5e"
        />
        <path
          d="M172.27,304.33c0-2.66,1-4.06,2.62-4.06s2.61,1.42,2.61,4.06-1,4.11-2.61,4.11S172.27,307,172.27,304.33Zm4,0c0-2.27-.58-3.06-1.4-3.06s-1.41.79-1.41,3.06.58,3.11,1.41,3.11S176.29,306.59,176.29,304.33Z"
          fill="#002a5e"
        />
        <path
          d="M182,299.41a17.44,17.44,0,0,1-1.07,2.28v7.45h-1v-5.85a8.29,8.29,0,0,1-.9,1.09,6.61,6.61,0,0,0-.57-1,12,12,0,0,0,2.61-4.24Zm6.43,8.33v1h-6.84v-1h3.82a43.1,43.1,0,0,0,1-5.29l1.06.17c-.29,1.66-.71,3.71-1.11,5.12Zm-.4-5.76h-6v-1h6Zm-4.61,5.34a43.65,43.65,0,0,0-.7-4.6l.92-.21a43.24,43.24,0,0,1,.79,4.51Zm1.25-6.38a11,11,0,0,0-.51-1.74l1-.24a14.33,14.33,0,0,1,.55,1.69Z"
          fill="#002a5e"
        />
        <path
          d="M193.11,305.8a13.77,13.77,0,0,0-.86-1.45v4.81h-1V304.7a10.5,10.5,0,0,1-1.4,2.56,6.07,6.07,0,0,0-.5-.94,11,11,0,0,0,1.76-3.23H189.5v-.95h1.76v-1.48c-.47.09-.94.16-1.38.22a3,3,0,0,0-.29-.79,16.8,16.8,0,0,0,3.5-.89l.58.84a10.55,10.55,0,0,1-1.42.41v1.69h1.54v.95h-1.54v.16c.33.34,1.27,1.46,1.48,1.75Zm5.16.54v2.82h-1v-2.64l-3.47.58-.15-1,3.62-.61v-6.44h1v6.28l1.2-.2.16,1Zm-2.4-1.49a9.72,9.72,0,0,0-1.83-1.64l.65-.59a10.59,10.59,0,0,1,1.88,1.56Zm.28-2.89a8.89,8.89,0,0,0-1.71-1.59l.68-.59a9.22,9.22,0,0,1,1.74,1.53Z"
          fill="#002a5e"
        />
        <path
          d="M210.42,307.09h-4.28v1.05c0,.49-.11.73-.5.86a6.06,6.06,0,0,1-1.81.14,3.91,3.91,0,0,0-.33-.86c.6,0,1.24,0,1.41,0s.23-.05.23-.19v-1h-4.51v-.84h4.51v-.39a11.43,11.43,0,0,0,1.48-.75h-3.94v-.75h5l.22-.06.58.53a11.15,11.15,0,0,1-2.33,1.33v.09h4.28Zm-8.61-2h-1V303h.83l-.2-3.25a22,22,0,0,0,2.16-.56l.44.6a9.58,9.58,0,0,1-1.62.43l0,.44H204v.66h-1.47l0,.51h1.49v.65a6.4,6.4,0,0,0,.92-.52c-.23-.14-.48-.29-.71-.4l.49-.43c.24.12.52.27.77.42a3.56,3.56,0,0,0,.5-.54l.69.22a6.27,6.27,0,0,1-.61.67,6.5,6.5,0,0,1,.73.56l-.52.48a5,5,0,0,0-.73-.59,7,7,0,0,1-1,.65h3.84c0-.16,0-.33,0-.5H207v-.67h1.5l0-.51h-1.5v-.66h1.54l0-.5H207v-.67h2.62c-.06,1.12-.16,2.52-.25,3.51h.84v2.1h-1v-1.33h-7.41Zm.83-2.1h1.88a3.09,3.09,0,0,0-.52-.5h-1.38Zm3.64-2a4.13,4.13,0,0,0-.68-.52,7.22,7.22,0,0,1-.95.61,4.64,4.64,0,0,0-.56-.48,4.4,4.4,0,0,0,.9-.5,6.56,6.56,0,0,0-.69-.37l.49-.42c.24.11.5.24.74.38a2.92,2.92,0,0,0,.46-.48l.7.18a6.8,6.8,0,0,1-.57.65,5.85,5.85,0,0,1,.67.49Z"
          fill="#002a5e"
        />
        <path
          d="M220.44,303.66c-.48.38-1,.77-1.55,1.14a5,5,0,0,0,2.75,3.08,3.39,3.39,0,0,0-.67.83,5.83,5.83,0,0,1-2.89-3.39l-.44.26a3.25,3.25,0,0,1-.5,3.14,1.49,1.49,0,0,1-1.19.44,7.37,7.37,0,0,1-.91,0,2.51,2.51,0,0,0-.28-.95c.39,0,.74.05,1,.05a.76.76,0,0,0,.66-.24,2.05,2.05,0,0,0,.39-1.56,16.25,16.25,0,0,1-4.58,2.24,3.43,3.43,0,0,0-.54-.84,13,13,0,0,0,4.94-2.35,4.49,4.49,0,0,0-.23-.61,18.15,18.15,0,0,1-4,1.85,5.37,5.37,0,0,0-.51-.8,13.6,13.6,0,0,0,4.12-1.73,3.94,3.94,0,0,0-.43-.52,15.76,15.76,0,0,1-3.1,1.23,4.82,4.82,0,0,0-.54-.82,12.29,12.29,0,0,0,3.73-1.4H213.4v-.88h6.22v.88H217a7.87,7.87,0,0,1-.72.53,4.78,4.78,0,0,1,1.06,1.54A18.78,18.78,0,0,0,219.6,303Zm-7.52-1.31h-1V300h4a6.67,6.67,0,0,0-.34-.71l1.07-.31a7.47,7.47,0,0,1,.51,1h4v2.35h-1v-1.42h-7.21Z"
          fill="#002a5e"
        />
        <path
          d="M170.28,257.49a22.48,22.48,0,0,0,20.33,22.35v-2.61a19.85,19.85,0,0,1,0-39.48v-2.61A22.47,22.47,0,0,0,170.28,257.49Zm24.58-22.35v2.6a19.86,19.86,0,0,1,0,39.5v2.6a22.45,22.45,0,0,0,0-44.7Z"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M198.66,259.41H202a0,0,0,0,1,0,0v9.69a1.69,1.69,0,0,1-1.69,1.69h0a1.69,1.69,0,0,1-1.69-1.69v-9.69A0,0,0,0,1,198.66,259.41Z"
          transform="translate(-128.77 219.31) rotate(-45)"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M191.41,251h4.38a0,0,0,0,1,0,0v12.6a2.19,2.19,0,0,1-2.19,2.19h0a2.19,2.19,0,0,1-2.19-2.19V251A0,0,0,0,1,191.41,251Z"
          transform="translate(513.19 304.14) rotate(135)"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <circle
          cx="190.44"
          cy="255.2"
          r="9.83"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <circle
          cx="190.44"
          cy="255.2"
          r="7.39"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <polyline
          points="187.42 258.78 188.2 256.05 191.05 256.77 192.68 251.15"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <polygon
          points="192.76 250.64 191.27 252.07 193.26 252.64 192.76 250.64"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M99.5,235.14v2.61a19.85,19.85,0,0,1,0,39.48v2.61a22.45,22.45,0,0,0,0-44.7ZM74.91,257.49a22.48,22.48,0,0,0,20.33,22.35v-2.6a19.87,19.87,0,0,1,0-39.5v-2.6A22.47,22.47,0,0,0,74.91,257.49Z"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <rect
          x="87.6"
          y="246.72"
          width="18.8"
          height="25.14"
          transform="translate(-41.91 19.83) rotate(-9.61)"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <rect
          x="88.89"
          y="244.92"
          width="18.8"
          height="25.14"
          transform="translate(-41.59 20.02) rotate(-9.61)"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M101.81,254.21l-1,.17a2.77,2.77,0,0,0-5.46.92l-1,.17a3.79,3.79,0,0,1,7.48-1.26Z"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <rect
          x="94.31"
          y="254.56"
          width="8.84"
          height="8.31"
          transform="translate(-41.79 20.11) rotate(-9.61)"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <circle cx="98.83" cy="258.97" r="0.83" fill="#002a5e" />
        <rect
          x="98.89"
          y="258.94"
          width="0.52"
          height="3.89"
          transform="translate(-42.15 20.21) rotate(-9.61)"
          fill="#002a5e"
        />
        <path
          d="M144,179.42h14.85a0,0,0,0,1,0,0v42.67a7.42,7.42,0,0,1-7.42,7.42h0a7.42,7.42,0,0,1-7.42-7.42V179.42a0,0,0,0,1,0,0Z"
          transform="translate(96.24 -43.92) rotate(24)"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M158.88,133.59h19.29a0,0,0,0,1,0,0V189a9.65,9.65,0,0,1-9.65,9.65h0a9.65,9.65,0,0,1-9.65-9.65V133.59A0,0,0,0,1,158.88,133.59Z"
          transform="translate(254.92 386.44) rotate(-156)"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <circle
          cx="175.39"
          cy="139.63"
          r="51.14"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <circle
          cx="175.39"
          cy="139.63"
          r="33.61"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M174.89,167.77a28.23,28.23,0,0,1-12.45-2.87"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M200,126.08a28.43,28.43,0,0,1-20.42,41.31"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <circle
          cx="111.31"
          cy="139.63"
          r="51.14"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <polyline
          points="63.19 122.22 85.11 122.22 137.5 122.22 159.41 122.22"
          fill="none"
          stroke="#fff"
          strokeWidth="0.45"
        />
        <polyline
          points="63.55 157.9 85.28 157.9 137.32 157.9 159.06 157.9"
          fill="none"
          stroke="#fff"
          strokeWidth="0.45"
        />
        <polygon
          points="131.75 168 143.31 99.81 154.86 168 143.31 179.56 131.75 168"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <ellipse
          cx="111.31"
          cy="139.63"
          rx="22.27"
          ry="51.14"
          fill="none"
          stroke="#fff"
          strokeWidth="0.45"
        />
        <polygon
          points="148.29 106.08 138.32 106.08 136.96 92.94 149.65 92.94 148.29 106.08"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <polyline
          points="133.19 82.19 143.65 92.94 133.86 103.7 121.91 92.94 132.3 82.19"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <polyline
          points="153.43 82.19 142.97 92.94 152.76 103.7 164.7 92.94 154.31 82.19"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <circle
          cx="143.65"
          cy="62.79"
          r="29.91"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M7.08,243.93H11v.92H9.32a18.25,18.25,0,0,1-.64,2.42H10.8v4.84H8.65V253H7.79v-3.72a6.32,6.32,0,0,1-.52.76,5.16,5.16,0,0,0-.4-1.16,9.84,9.84,0,0,0,1.48-4H7.08Zm2.84,4.25H8.65v3H9.92Zm7.13.63H15.87v4.66h-1v-4.66H13.32a7,7,0,0,1-1.53,4.71,4.83,4.83,0,0,0-.78-.59,6.23,6.23,0,0,0,1.36-4.12H11.23v-1h1.15V244.9h-.85v-1h5.3v1h-1v2.94h1.18Zm-2.16-1V244.9H13.33v2.94Z"
          fill="#002a5e"
        />
        <path
          d="M26.78,252.26c.28,0,.34-.21.38-1.6a2.93,2.93,0,0,0,1,.43c-.11,1.73-.38,2.17-1.24,2.17H25.73c-.95,0-1.24-.3-1.24-1.37v-1.83h-1.7c-.21,1.29-1.12,2.66-4.07,3.46a5.17,5.17,0,0,0-.71-.88c2.52-.66,3.4-1.65,3.67-2.58h-2.9v-.95h3v-1.28h1.07v1.28h2.69v2.78c0,.31.05.37.34.37Zm-7.37-5.78h-1V244.4h4.14c-.09-.29-.2-.61-.3-.87l1.06-.19a10.53,10.53,0,0,1,.41,1.06h4v2H26.66v-1.07H24.72V247c0,.28.06.39.32.39h1.52a4.87,4.87,0,0,0,.89-.07c0,.27.05.63.09.86a4.08,4.08,0,0,1-.94.07H25c-1,0-1.26-.35-1.26-1.26v-1.64H22.3c-.22,1.61-.86,2.52-3.27,3a2.61,2.61,0,0,0-.55-.83c2.1-.36,2.58-1,2.74-2.18H19.41Z"
          fill="#002a5e"
        />
        <path
          d="M31.15,249.25v3.12c0,.49-.09.77-.38.92a3.36,3.36,0,0,1-1.42.19,3.64,3.64,0,0,0-.27-.94c.44,0,.84,0,1,0s.2,0,.2-.18v-2.84l-1.18.37-.24-1c.4-.09.89-.23,1.42-.38v-2.07H29v-1h1.24v-2.14h.91v2.14h1v1h-1v1.8l1-.3.15.93Zm2.17-.82a9,9,0,0,1-1.16,5,3.15,3.15,0,0,0-.75-.52,8,8,0,0,0,1-4.53v-3.15h2.43v-1.88h1v.6h2.54v.74H35.84v.54h2.33l.15,0,.64.14a14.82,14.82,0,0,1-.54,1.63l-.77-.18c.08-.21.17-.52.25-.81H35.67v.52l1.63-.15.07.58-1.7.18v0c0,.25,0,.35.29.35h1.4a4.39,4.39,0,0,0,.84-.07c0,.23,0,.44.06.64a4.06,4.06,0,0,1-.85,0H35.8c-.74,0-.94-.26-1-.8l-1.22.11-.09-.75,1.3-.12V246h-1.5ZM38.94,250c-.35.19-.76.39-1.17.57a3.33,3.33,0,0,0,1.42,2,2.76,2.76,0,0,0-.59.64,4.2,4.2,0,0,1-1.45-2.38c-.15.07-.31.12-.46.18a2.16,2.16,0,0,1-.21,2.2,1,1,0,0,1-.83.33h-.56a1.5,1.5,0,0,0-.22-.74l.64,0a.44.44,0,0,0,.4-.15,1.25,1.25,0,0,0,.17-1,10.23,10.23,0,0,1-2.64,1.48,4.91,4.91,0,0,0-.39-.58,8.12,8.12,0,0,0,2.89-1.51c0-.1-.1-.22-.15-.33a10.11,10.11,0,0,1-2.19,1.07,2,2,0,0,0-.37-.52,7.52,7.52,0,0,0,2.27-1l-.25-.27a9,9,0,0,1-1.52.59,4.07,4.07,0,0,0-.31-.53,8.94,8.94,0,0,0,2.2-.87h-2v-.69h5.33v.69H36.59a7.36,7.36,0,0,1-.74.51,2.84,2.84,0,0,1,.6.71,19.83,19.83,0,0,0,1.91-1Z"
          fill="#002a5e"
        />
        <path
          d="M39.89,253a6.28,6.28,0,0,0,.7-2l.68.26a6.58,6.58,0,0,1-.66,2ZM45,250.64H40.12v-.81h2v-.71H40.32v-.8h1.77v-.62H40.35v-4h4.43v4H43v.62h1.83v.8H43v.71h2ZM41.13,247H42.2v-2.53H41.13Zm.55-2.15a7.61,7.61,0,0,1,.35,1.6l-.49.15a7.55,7.55,0,0,0-.32-1.63Zm.56,6.36a9.89,9.89,0,0,1,.17,1.89l-.68.09a9,9,0,0,0-.14-1.9Zm1.09-.06a8.59,8.59,0,0,1,.47,1.76l-.66.16a8,8,0,0,0-.43-1.79Zm.65-6.68H42.86V247H44Zm-1,2a15.18,15.18,0,0,0,.45-1.67L44,245c-.21.54-.42,1.21-.61,1.61ZM44.44,251a7,7,0,0,1,.75,1.72l-.67.25a7.07,7.07,0,0,0-.72-1.74Zm3.38-4.37v1.9h1.83v4.87h-1V253H46.24v.48h-.93v-4.92h1.52v-5.16h1v2.32h2.23v.94Zm.86,2.82H46.24v2.59h2.44Z"
          fill="#002a5e"
        />
        <path
          d="M9.48,264a13.68,13.68,0,0,0-.78-1.46v5H7.77v-4.71a11.54,11.54,0,0,1-1.2,2.86A5.79,5.79,0,0,0,6,264.52a11.27,11.27,0,0,0,1.57-4H6.27v-.95h1.5v-2.24H8.7v2.24H10v.95H8.7v.69c.31.37,1.15,1.54,1.34,1.82Zm3,2.16a8.66,8.66,0,0,1-2.47,1.4,6.49,6.49,0,0,0-.6-.74,7,7,0,0,0,2.23-1.24Zm3.81-4.46H9.72v-.79h6.57Zm-5.1-2.56H9.85v-.77h1.34v-.8h.9v.8H13.8v-.8h.95v.8h1.34v.77H14.75v1.38H11.19Zm-.94,6.4v-3.36h5.48v3.36Zm.9-2h1.34v-.63H11.15Zm0,1.28h1.34v-.63H11.15Zm.94-5.67v.71H13.8v-.71Zm2.72,3.76H13.42v.63h1.39Zm0,1.28H13.42v.63h1.39Zm-.57,1.42a15,15,0,0,1,2.15,1.29l-.83.67a14.3,14.3,0,0,0-2-1.31Z"
          fill="#002a5e"
        />
        <path
          d="M25.55,259.58a5.79,5.79,0,0,0,1.87,1.85,4.28,4.28,0,0,0-.68.78A7.42,7.42,0,0,1,25.51,261v.67H22.63v-.56a7.9,7.9,0,0,1-1.23,1,4.74,4.74,0,0,0-.44-.87H20v1.38h1v.89H20v1.74l1.33-.36.13.88c-1.4.41-2.92.83-4.07,1.15l-.23-1,.49-.11V262.2h.8v3.47l.66-.17v-4.24H17.64v-3.44H21v3.35a6,6,0,0,0,1.79-1.59H21.41v-.89h1.91a7.7,7.7,0,0,0,.49-1.18l.89.23a6.44,6.44,0,0,1-.37.95h2.83v.89Zm-5.46-.88H18.46v1.69h1.63Zm1.23,3.79h5.79v.85H23.47c-.09.32-.18.65-.28,1h3.38l0,.38c-.15,1.51-.29,2.19-.61,2.49a1.32,1.32,0,0,1-1,.32c-.36,0-1.09,0-1.81,0a1.9,1.9,0,0,0-.38-.88c.77.06,1.59.07,1.9.07a.88.88,0,0,0,.5-.09c.17-.15.29-.57.39-1.44H22a15.15,15.15,0,0,0,.57-1.79H21.32Zm4.07-1.6a7.31,7.31,0,0,1-.82-1.31h-.71a7.28,7.28,0,0,1-1,1.31Z"
          fill="#002a5e"
        />
        <path
          d="M28.68,265.55h1.77v-5.41H29v-.8a4.82,4.82,0,0,0,1.76-.62h1v6.83H33.3v1.05H28.68Z"
          fill="#002a5e"
        />
        <path
          d="M35,265.55h1.77v-5.41H35.27v-.8a4.82,4.82,0,0,0,1.76-.62h1v6.83h1.58v1.05H35Z"
          fill="#002a5e"
        />
        <path
          d="M50.36,257.85v9.65h-1V267h-7.2v.49h-1v-9.65Zm-1,8.24v-7.32h-7.2v7.32Zm-.81-4.7A8.68,8.68,0,0,1,47.36,264c.18.47.43.77.71.83s.23-.41.3-1.09a4,4,0,0,0,.65.48c-.15,1.25-.62,1.68-1.25,1.43a1.73,1.73,0,0,1-1-.91,6.53,6.53,0,0,1-1.3,1.1,4.86,4.86,0,0,0-.58-.63,6.42,6.42,0,0,0,.85-.59c-1.08.17-2.19.38-3.07.54l-.11-.77c.8-.11,2-.3,3.19-.49l0,.68a5.38,5.38,0,0,0,.66-.69,15.76,15.76,0,0,1-.54-3.07H42.66v-.76h3.16c0-.35,0-.7-.07-1.05h.85c0,.35,0,.71,0,1.05h1.56a4.27,4.27,0,0,0-1.09-.76l.47-.47a4.34,4.34,0,0,1,1.12.73l-.48.5h.67v.76H46.72A21.32,21.32,0,0,0,47,263a6.93,6.93,0,0,0,.68-1.75Zm-3.11,0v2.12H43V261.4Zm-.71.59h-1v.94h1Z"
          fill="#002a5e"
        />
        <path
          d="M30.63,192.44v2.61a19.86,19.86,0,0,1,0,39.49v2.61a22.46,22.46,0,0,0,0-44.71ZM6.05,214.79a22.49,22.49,0,0,0,20.33,22.36v-2.61a19.86,19.86,0,0,1,0-39.5v-2.6A22.48,22.48,0,0,0,6.05,214.79Z"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <polygon
          points="36.53 208.78 38.39 212.56 28.85 212.56 28.85 205.01 38.39 205.01 36.53 208.78"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <rect
          x="27.01"
          y="204.62"
          width="1.83"
          height="14.65"
          rx="0.53"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M35.55,224.06c0-3.06-3.38-5.55-7.55-5.55s-7.56,2.49-7.56,5.55Z"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M227.28,243.5h2.1c2.49,0,3.91,1.38,3.91,4s-1.42,4.08-3.84,4.08h-2.17Zm2,7.06c1.74,0,2.68-1,2.68-3s-.94-3-2.68-3h-.73v6Z"
          fill="#002a5e"
        />
        <path
          d="M236.47,243.5h1.47l2.69,8.1h-1.36l-.68-2.31h-2.82l-.69,2.31h-1.3Zm-.4,4.79h2.21L238,247.2c-.27-.88-.51-1.78-.75-2.69h-.06c-.23.92-.48,1.81-.75,2.69Z"
          fill="#002a5e"
        />
        <path
          d="M241.13,247.58c0-2.64,1.63-4.23,3.77-4.23a3.21,3.21,0,0,1,2.41,1l-.68.81a2.22,2.22,0,0,0-1.68-.73c-1.51,0-2.51,1.17-2.51,3.07s.89,3.11,2.56,3.11a2,2,0,0,0,1.28-.4v-1.9h-1.61v-1.05h2.76v3.51a3.61,3.61,0,0,1-2.56.95C242.71,251.75,241.13,250.23,241.13,247.58Z"
          fill="#002a5e"
        />
        <path
          d="M251,250.87a7.42,7.42,0,0,1-1.8,1.6,7.91,7.91,0,0,0-.65-.64,5.54,5.54,0,0,0,1.6-1.47Zm2.83-.53h-5.15v-.83h.71v-4.78h1.32c0-.2.08-.42.11-.65h-1.92v-.8h2c0-.32.07-.64.09-.91l1,.06-.14.85h1.74v.8h-1.9c0,.23-.09.45-.13.65h1.7v4.78h.55Zm-3.59-5V246h2.16v-.65Zm2.16,1.21h-2.16v.64h2.16Zm-2.16,1.2v.6h2.16v-.6Zm0,1.75h2.16v-.58h-2.16Zm3.17,2.18a5.69,5.69,0,0,0,1.61-1.25l.85.53a7.14,7.14,0,0,1-2,1.5,6.17,6.17,0,0,0-.63-.61l-.27.22a8,8,0,0,0-1.2-1.19l.71-.5a8.87,8.87,0,0,1,1.22,1.11Zm2.12-7c.07-.29.12-.64.16-.93h-1.89v-.87h4.89v.87h-1.95c-.1.32-.19.65-.28.93h1.9v5.67h-4.25v-5.67Zm1.9.78H255v.87h2.43Zm0,1.61H255v.87h2.43Zm0,1.62H255v.87h2.43Zm-.25,1.82a12.57,12.57,0,0,1,1.69,1.4l-.8.56A12.42,12.42,0,0,0,256.4,251Z"
          fill="#002a5e"
        />
        <path
          d="M268.76,250.18a4.08,4.08,0,0,1-1.49,1.21,16.55,16.55,0,0,0,2.32.36,2.55,2.55,0,0,0-.46.71,15.5,15.5,0,0,1-3-.61,17.14,17.14,0,0,1-3.42.65,3,3,0,0,0-.39-.7,16.64,16.64,0,0,0,2.74-.36,8.62,8.62,0,0,1-1-.55,9.71,9.71,0,0,1-.94.38,4.64,4.64,0,0,0-.54-.55,8.76,8.76,0,0,0,.82-.33,5.19,5.19,0,0,1-.53-.52l.71-.27a3.14,3.14,0,0,0,.5.46,4.91,4.91,0,0,0,.79-.52h-1.17v-1.73a5.38,5.38,0,0,1-.6.58,4.46,4.46,0,0,0-.53-.47,6.82,6.82,0,0,1-.66.8v3.75h-.9V249.6c-.28.23-.58.46-.87.66a4,4,0,0,0-.56-.66,7.41,7.41,0,0,0,2.34-2.32l.76.26a5.53,5.53,0,0,0,1.36-1.81h-3.59v-1.85h2.44v-.44H260v-.71h9.48v.71h-3.05v.44H269v1.85h-4.76l.72.2a3.31,3.31,0,0,0-.15.33h4.47V247h-4.9a3.88,3.88,0,0,1-.23.33h4.57v2.26H266a4.31,4.31,0,0,1-.37.37h2.45l.18,0Zm-6.16-4.1a8,8,0,0,1-2.32,2.1c-.14-.17-.43-.48-.6-.64a6.36,6.36,0,0,0,2-1.75Zm-1.18-1h1.51v-.57h-1.51Zm2.43-1.21h1.61v-.44h-1.61Zm1.61,1.21v-.57h-1.61v.57Zm-.88,3.08h3.27v-.38h-3.27Zm0,.86h3.27v-.39h-3.27Zm.25,1.48a8,8,0,0,0,1.38.6,5,5,0,0,0,1.16-.6Zm1.59-6v.57h1.63v-.57Z"
          fill="#002a5e"
        />
        <path
          d="M276.77,247.8a10.67,10.67,0,0,0,4.06,3.24,6.34,6.34,0,0,0-.75.88,11.82,11.82,0,0,1-3.84-3.3v3.84h-1.07v-3.79a12.06,12.06,0,0,1-3.83,3.24,7.66,7.66,0,0,0-.75-.87,10.7,10.7,0,0,0,4-3.24h-3.72v-1h4.26v-1.68H271.7v-1h3.47v-1.73h1.07v1.73h3.56v1h-3.56v1.68h4.33v1Z"
          fill="#002a5e"
        />
        <path
          d="M290.82,248.7a21.22,21.22,0,0,0-1.58-1.43,5.33,5.33,0,0,1-.86,1.13,7,7,0,0,0-.83-.52,9.74,9.74,0,0,0,4.27,3.22,4.53,4.53,0,0,0-.77.92,10.59,10.59,0,0,1-3.83-3.06v3.5h-1.08V249a11.09,11.09,0,0,1-3.79,3,5,5,0,0,0-.73-.87,10.12,10.12,0,0,0,4.52-3.71v-2.61H282v-1h4.1v-1.46h1.08v1.46h4.24v1h-4.24v2.56c.1.16.2.32.31.47A5.18,5.18,0,0,0,289,245l1,.18a6.8,6.8,0,0,1-.39,1.23,22.27,22.27,0,0,1,1.9,1.59Zm-5.94-3.51a7.84,7.84,0,0,1-.41,1.24c.44.38.9.81,1.17,1.09l-.7.73a8.24,8.24,0,0,0-.9-.94,5.65,5.65,0,0,1-1.33,1.63,4.66,4.66,0,0,0-.79-.67,5.55,5.55,0,0,0,2-3.27Z"
          fill="#002a5e"
        />
        <path
          d="M232.34,263a9.17,9.17,0,0,0,4.5,2.2,5.29,5.29,0,0,0-.65.9,9.58,9.58,0,0,1-3.85-2v2.4h-1.09V264a9.45,9.45,0,0,1-3.89,2.11,4.37,4.37,0,0,0-.64-.86,8.42,8.42,0,0,0,4.53-2.43V262h-2.44v2h-1.08v-2.89h3.52v-.72H227v-.89h4.25v-.65h.89a5.44,5.44,0,0,0-.68-.53,4.64,4.64,0,0,0,1.37-2l.93.23c-.06.19-.15.38-.23.58h3.16v.8h-2.16a8.57,8.57,0,0,1,1,.85l-.69.6a9.89,9.89,0,0,0-1.13-1.06l.48-.39h-1.07a5.51,5.51,0,0,1-.78,1h0v.65h4.08v.89h-4.08v.72h3.49v1.93c0,.46-.1.67-.42.8A4,4,0,0,1,234,264a3.75,3.75,0,0,0-.35-.82,6.2,6.2,0,0,0,1,0c.13,0,.18,0,.18-.14V262h-2.45Zm-3.8-5.09a8.1,8.1,0,0,1-1.13,1.42,6.3,6.3,0,0,0-.69-.67,6.39,6.39,0,0,0,1.61-2.36l.91.26c-.08.19-.15.36-.25.55h2.63v.8h-1.85a10.41,10.41,0,0,1,.88.87l-.66.58a11.73,11.73,0,0,0-1.06-1.11l.41-.34Z"
          fill="#002a5e"
        />
        <path
          d="M247.33,257.78a8.41,8.41,0,0,1-1.8,2.81A8,8,0,0,0,248,262a3.72,3.72,0,0,0-.66.85l-.23-.1v3.67h-1v-.34h-2.7v.35h-1v-3.64l-.4.18a4.93,4.93,0,0,0-.32-.47v1.75h-2.92v.95h-.77V257h3.69v2.22a7.49,7.49,0,0,0,2.06-2.89l1,.28a8.78,8.78,0,0,1-.42.88h2.14l.18-.05Zm-8.49.12v2.18h.72V257.9Zm0,5.47h.72v-2.43h-.72ZM241,257.9h-.74v2.18H241Zm0,5.47v-2.43h-.74v2.43Zm.8-1.31a9.37,9.37,0,0,0,2.42-1.48,9.11,9.11,0,0,1-1-1.35,7.2,7.2,0,0,1-.95,1c-.1-.12-.29-.3-.46-.46Zm4.77.4a9,9,0,0,1-1.68-1.21,10.76,10.76,0,0,1-1.73,1.21Zm-.38,2.71v-1.81h-2.7v1.81Zm-2.31-6.82-.1.15a7.36,7.36,0,0,0,1.1,1.44,6.77,6.77,0,0,0,1.15-1.59Z"
          fill="#002a5e"
        />
        <path
          d="M254,265.69a4.9,4.9,0,0,0,1.33-1.2l.85.49a8,8,0,0,1-1.81,1.52,4.41,4.41,0,0,0-.66-.63l.12-.07h-2.7v.55h-.77v-3l-.26.33a2.09,2.09,0,0,0-.18-.27,12.36,12.36,0,0,1-.59,3,2.66,2.66,0,0,0-.78-.42,16.07,16.07,0,0,0,.62-5.26v-3.51h0a21.41,21.41,0,0,0,3.83-.64l.75.68a20.46,20.46,0,0,1-3.74.64v.44h3.78v2.38h-3.78c0,.53,0,1.15,0,1.8a6.27,6.27,0,0,0,.81-1.78l.73.19c-.08.25-.18.5-.27.75h.81c-.09-.23-.2-.49-.31-.71l.68-.17a4.47,4.47,0,0,1,.43.88H254v.67H252.8v.5h1v.61h-1v.5h1v.62h-1v.52H254Zm-4-6.57v.81h2.86v-.81Zm2,3.23h-.95v.5h.95Zm0,1.11h-.95v.5h.95Zm-.95,1.64h.95v-.52h-.95Zm4.58-6.49c0-.28.09-.6.12-.87h-1.71v-.87h4.49v.87h-1.76c-.07.29-.15.59-.23.87h1.66v5.65h-3.88v-5.65Zm1.69.79h-2.15v.85h2.15Zm0,1.59h-2.15v.87h2.15Zm0,1.61h-2.15v.87h2.15Zm0,1.9a9.45,9.45,0,0,1,1.38,1.38l-.77.53a9.71,9.71,0,0,0-1.34-1.44Z"
          fill="#002a5e"
        />
        <path
          d="M261.23,260.75v5.72h-1v-9.66h4v3.94Zm0-3.12v.77h2.07v-.77ZM263.3,260v-.83h-2.07V260Zm.25,5v.64h-.95v-3.94h4.15v3.3Zm0-2.44v1.59h2.24v-1.59Zm5.73,2.68c0,.62-.14.94-.54,1.12a6.48,6.48,0,0,1-2,.17,3.58,3.58,0,0,0-.36-1c.7,0,1.41,0,1.63,0s.27-.08.27-.28v-4.4h-3.11v-3.95h4.15Zm-3.17-7.55v.78h2.13v-.78Zm2.13,2.34v-.84h-2.13V260Z"
          fill="#002a5e"
        />
        <path
          d="M280.21,256.81v9.63h-1v-.52h-7.07v.56h-.93v-9.67Zm-1,8.31v-7.51h-7.07v7.51Zm-.31-1.7h-1.5v.89c0,.36-.09.48-.35.56a5.52,5.52,0,0,1-1.32.08,2,2,0,0,0-.22-.55c.37,0,.77,0,.88,0s.15-.05.15-.15v-.85h-4.08v-.59h4.08v-.36h.86v.36h1.5Zm-1.22-2a7.13,7.13,0,0,1,1,1l-.54.42a4.37,4.37,0,0,0-.43-.46c-1.87.05-3.75.11-5.09.13l0-.58,2.59,0v-.45H273v-2.23h2.25v-.38h-2.62v-.61h2.62v-.45h.85v.45h2.67v.61h-2.67v.38h2.34v2.23Zm-3.27,3.33a5.59,5.59,0,0,0-.86-1l.64-.33a5.4,5.4,0,0,1,.91,1Zm-.65-4.68h1.45v-.4h-1.45Zm1.45.87v-.42h-1.45V261Zm.85-1.27v.4h1.52v-.4Zm1.52,1.27v-.42h-1.52V261Zm-.36.9-.2-.16.4-.26h-1.36v.45Z"
          fill="#002a5e"
        />
        <path
          d="M285.36,257.2c-.31.91-.69,2.06-1,2.91a3.37,3.37,0,0,1,.9,2.29,1.55,1.55,0,0,1-.45,1.32,1.53,1.53,0,0,1-.59.21,5.78,5.78,0,0,1-.73,0,2.21,2.21,0,0,0-.23-.88,3.6,3.6,0,0,0,.56,0,.54.54,0,0,0,.33-.1.85.85,0,0,0,.21-.69,3.1,3.1,0,0,0-.9-2.06c.28-.74.57-1.71.77-2.45H283v8.71h-.9v-9.63h2.43l.14,0Zm6.17,3a14.38,14.38,0,0,1-1.38.92,8.54,8.54,0,0,0,1.79,3.18,4.65,4.65,0,0,0-.66.67,10.65,10.65,0,0,1-1.85-3.42l-.13.07c.55,1.78.31,3.66-.33,4.25a1.4,1.4,0,0,1-1.17.48c-.2,0-.57,0-.86,0a2.46,2.46,0,0,0-.26-.92,9.15,9.15,0,0,0,.95.05.69.69,0,0,0,.62-.28,2.91,2.91,0,0,0,.39-1.88,12,12,0,0,1-3,1.76,3,3,0,0,0-.6-.75,8.54,8.54,0,0,0,3.45-2,4.79,4.79,0,0,0-.19-.72A14.12,14.12,0,0,1,286,263a3.84,3.84,0,0,0-.55-.74,9,9,0,0,0,2.54-1.46,3.87,3.87,0,0,0-.46-.75,11.48,11.48,0,0,1-1.92,1.17,4.05,4.05,0,0,0-.61-.74,8.54,8.54,0,0,0,2.48-1.46h-2.23v-.89h2a6.4,6.4,0,0,0-1.16-1.25l.76-.47a6.56,6.56,0,0,1,1.31,1.34l-.55.38h1.12a10.36,10.36,0,0,0,1.1-1.77l1,.29a14.68,14.68,0,0,1-1,1.48h1.88v.88h-3a4.53,4.53,0,0,1-.49.49,5,5,0,0,1,.82,1.31,13.67,13.67,0,0,0,1.87-1.35Z"
          fill="#002a5e"
        />
        <path
          d="M235.74,214.79a22.5,22.5,0,0,0,20.33,22.36v-2.61a19.86,19.86,0,0,1,0-39.49v-2.61A22.49,22.49,0,0,0,235.74,214.79Zm24.59-22.35V195a19.87,19.87,0,0,1,0,39.5v2.61a22.46,22.46,0,0,0,0-44.71Z"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M249.66,216.33l-1.31-.46,2.17-4.62s-1.57-3.24,2.12-6.31,8.25-2.81,10.37-1.56,4.61,3.37,4.29,7.09-2.66,6.7-2.72,8.22a33.67,33.67,0,0,0,.6,4.94H254.59l-.43-2.28-2.38.29a1.33,1.33,0,0,1-1.49-1.13Z"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M258.63,213.95h.91a.75.75,0,0,1,.75.75V216a1.2,1.2,0,0,1-1.2,1.2h0a1.2,1.2,0,0,1-1.2-1.2v-1.29a.75.75,0,0,1,.75-.75Z"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M262.5,210.12a3.42,3.42,0,1,0-5.67,2.56,2.49,2.49,0,0,1,.82,1.86v.32h2.87v-.32a2.48,2.48,0,0,1,.82-1.86A3.41,3.41,0,0,0,262.5,210.12Z"
          fill="#fff"
          stroke="#002a5e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.45"
        />
        <path
          d="M258.18,207.87a2.5,2.5,0,0,1,1-.22,2.44,2.44,0,0,1,2.44,2.44,2.49,2.49,0,0,1-.37,1.29"
          fill="#fff"
          stroke="#002a5e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.45"
        />
        <rect
          x="27.68"
          y="61.49"
          width="75.57"
          height="38.71"
          rx="19.36"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M47.83,77.71H52v.92H42.71v-.92h4V76.09h-3V75.2h3V73.7H44.29v-.89a13.53,13.53,0,0,1-1.43.88,4,4,0,0,0-.64-.84,13.19,13.19,0,0,0,3.88-2.77H44.35v-1h3.51a10.55,10.55,0,0,0,4.6,3.6,4.68,4.68,0,0,0-.69.93,11.23,11.23,0,0,1-1.42-.81v.86H47.83v1.5h3v.89h-3ZM44.31,72.8h6a13.63,13.63,0,0,1-3-2.72A15.75,15.75,0,0,1,44.31,72.8Z"
          fill="#fff"
        />
        <path
          d="M64.06,72.6a28.67,28.67,0,0,1-1.85,2.27,6.07,6.07,0,0,0,2.14,2.46,2.3,2.3,0,0,0-.63.79,7.13,7.13,0,0,1-2.33-2.86v2.46c0,.55-.13.83-.46,1a4.14,4.14,0,0,1-1.71.21,4.11,4.11,0,0,0-.35-1c.6,0,1.12,0,1.29,0s.24-.07.24-.24V75.47c-.85.85-1.72,1.67-2.44,2.33l-.63-.89.16-.12c-1.14.38-2.33.75-3.25,1l-.22-1c.39-.1.87-.25,1.39-.4V73.59H54.23v-1h1.18V70.46H54.15v-.95h3.4v.95h-1.2v2.18h1v1h-1v2.58l1.26-.39.13.81c.72-.58,1.73-1.43,2.66-2.23V71.6H57.79v-.95H60.4V68.83h1v1.82h2.78v.95H61.39v.45a13.54,13.54,0,0,0,.46,2,15.73,15.73,0,0,0,1.32-1.87Zm-5.35-.4a8.65,8.65,0,0,1,1.14,2l-.85.41a7.81,7.81,0,0,0-1.08-2Zm4.4-1.56a7.48,7.48,0,0,0-1.28-1.2l.57-.52A6.61,6.61,0,0,1,63.72,70Z"
          fill="#fff"
        />
        <path
          d="M76,77.76v.84H68v-.84H71.4v-.9H69v-.81H71.4v-.8H69.33a6.06,6.06,0,0,1-1.09,1.4,5,5,0,0,0-.49-.9A5.89,5.89,0,0,1,66.6,79a3.18,3.18,0,0,0-.78-.65,5.7,5.7,0,0,0,1-3.66V72.59h1.41a4.58,4.58,0,0,0-.45-.44c.84-.14,1.79-.35,2.7-.57-.8-.22-1.59-.42-2.32-.58l.57-.42H66.89v-.81h3.92a6.44,6.44,0,0,0-.51-.71l.72-.34a6.27,6.27,0,0,1,.77,1h3.73v.81H73.88l.48.3c-.45.22-1,.44-1.55.65.74.21,1.42.43,2,.62l-.51.44h1.6v.83H67.79v1.31c0,.32,0,.66,0,1a5.85,5.85,0,0,0,1.4-2.18l.88.25c-.08.21-.17.41-.27.62H71.4v-.82h1v.82h2.85v.81H72.41v.8H75v.81H72.41v.9Zm-2.23-5.17c-.59-.21-1.33-.44-2.13-.68-.9.27-1.82.49-2.68.68Zm-4.58-2c.8.18,1.67.4,2.51.63a10.41,10.41,0,0,0,1.69-.63Z"
          fill="#fff"
        />
        <path
          d="M81.86,77.18a9.53,9.53,0,0,1-3.28,1.42,4.27,4.27,0,0,0-.61-.75,9.28,9.28,0,0,0,3.14-1Zm5.76-1.68v.86H83.28v2.56h-1V76.36H78V75.5h4.27v-.66H79v-.77h3.25v-.66H78.5v-.8h2.21a5.65,5.65,0,0,0-.49-.94l.64-.16h-2.8V70.7h1.63a7.78,7.78,0,0,0-.8-1.49l.89-.31a7.7,7.7,0,0,1,.88,1.58l-.58.22h1.23V68.8h1v1.9h1.09V68.8h1v1.9h1.2l-.43-.15a10.46,10.46,0,0,0,.77-1.62l1,.27A17.39,17.39,0,0,1,86,70.7h1.57v.81H84.7l.86.22c-.24.33-.5.63-.72.88h2.34v.8h-3.9v.66h3.38v.77H83.28v.66Zm-6.4-4a4.67,4.67,0,0,1,.52,1l-.49.13h3l-.4-.12a8,8,0,0,0,.62-1Zm3.33,5.25c1,.35,2.31.88,3,1.23l-.68.59c-.68-.34-2-.92-3-1.3Z"
          fill="#fff"
        />
        <path
          d="M42.39,83.38h3.9v.92H44.64A19.93,19.93,0,0,1,44,86.72h2.12v4.84H44v.85h-.86V88.69a6.32,6.32,0,0,1-.52.76,4.75,4.75,0,0,0-.41-1.16,9.84,9.84,0,0,0,1.49-4H42.39Zm2.85,4.25H44v3h1.27Zm7.13.63H51.18v4.66h-1V88.26H48.64A7,7,0,0,1,47.11,93a4.69,4.69,0,0,0-.78-.59,6.16,6.16,0,0,0,1.35-4.12H46.55v-1H47.7v-3h-.85v-1h5.3v1h-1v3h1.19Zm-2.17-1v-3H48.65v3Z"
          fill="#fff"
        />
        <path
          d="M62.92,91.71c.29,0,.34-.21.39-1.6a3,3,0,0,0,1,.43c-.11,1.73-.38,2.17-1.24,2.17H61.88c-1,0-1.25-.3-1.25-1.37V89.5H58.94c-.22,1.3-1.12,2.67-4.07,3.47a5.79,5.79,0,0,0-.72-.88c2.52-.66,3.4-1.65,3.68-2.59H54.92v-.94h3V87.28H59v1.28H61.7v2.78c0,.31,0,.37.34.37Zm-7.37-5.78h-1V83.85h4.13c-.08-.29-.2-.61-.29-.87l1.05-.19a8.58,8.58,0,0,1,.41,1.06h4.05v2H62.8V84.76H60.86v1.65c0,.28.06.39.32.39H62.7a4.87,4.87,0,0,0,.89-.07c0,.27.06.63.09.86a4,4,0,0,1-.94.07H61.12c-1,0-1.27-.36-1.27-1.26V84.76H58.44c-.22,1.61-.86,2.52-3.26,3a2.64,2.64,0,0,0-.56-.83c2.11-.36,2.58-1,2.75-2.18H55.55Z"
          fill="#fff"
        />
        <path
          d="M75.93,90.53H73.39v1.35c0,.49-.1.75-.49.89a6.51,6.51,0,0,1-1.81.13,3.13,3.13,0,0,0-.32-.88c.61,0,1.24,0,1.4,0s.22-.06.22-.19V90.53H68.74a8.16,8.16,0,0,1,1.49,1.2l-.74.66A9,9,0,0,0,67.76,91l.49-.43H66V89.7h6.37v-.56c-2.2.06-4.33.1-5.9.12l0-.77,4,0V88H67.18v-3h3.28v-.52H66.33V83.6h4.13v-.77h1v.77h4.06v.79H71.46v.52h3.38v3h-1a9.94,9.94,0,0,1,1.71,1l-.61.55a5,5,0,0,0-.57-.4l-1,0v.59h2.54Zm-7.79-4.41h2.32v-.55H68.14Zm2.32,1.2v-.58H68.14v.58Zm1-1.75v.55h2.38v-.55Zm2.38,1.17H71.46v.58h2.38Zm-2.38,1.73,1.77,0a6.46,6.46,0,0,0-.67-.29l.25-.21H71.46Z"
          fill="#fff"
        />
        <path
          d="M86.71,87.4c-.47.39-1,.77-1.55,1.15a5,5,0,0,0,2.75,3.08,3.34,3.34,0,0,0-.67.82,5.79,5.79,0,0,1-2.88-3.39l-.44.27c.35,1.36.09,2.67-.51,3.13a1.49,1.49,0,0,1-1.19.44,7.2,7.2,0,0,1-.91,0,2.58,2.58,0,0,0-.27-1c.38,0,.73.06,1,.06a.77.77,0,0,0,.66-.24,2.07,2.07,0,0,0,.38-1.56,16.12,16.12,0,0,1-4.58,2.24,4,4,0,0,0-.53-.85,12.77,12.77,0,0,0,4.93-2.34,3.89,3.89,0,0,0-.23-.62,17.86,17.86,0,0,1-4,1.86,4.74,4.74,0,0,0-.52-.8,13.92,13.92,0,0,0,4.13-1.73,6.27,6.27,0,0,0-.43-.53,15.2,15.2,0,0,1-3.1,1.24,5.5,5.5,0,0,0-.54-.82,12.14,12.14,0,0,0,3.73-1.41H79.67v-.88H85.9v.88H83.29a6.32,6.32,0,0,1-.71.54,4.64,4.64,0,0,1,1,1.54,19,19,0,0,0,2.25-1.76ZM79.2,86.09h-1V83.75h4a5.21,5.21,0,0,0-.35-.71L83,82.73a6.54,6.54,0,0,1,.52,1h4v2.34h-1V84.67H79.2Z"
          fill="#fff"
        />
        <rect
          x="185.4"
          y="61.49"
          width="75.57"
          height="38.71"
          rx="19.36"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.45"
        />
        <path
          d="M202.3,77.31a7.83,7.83,0,0,1-1.79,1.61,6.56,6.56,0,0,0-.65-.64,5.81,5.81,0,0,0,1.59-1.48Zm2.84-.53H200V76h.71V71.17H202c0-.2.08-.42.11-.65H200.2v-.8h2c0-.32.07-.64.09-.9l1,.05-.14.85h1.75v.8H203c-.05.23-.09.45-.14.65h1.71V76h.55Zm-3.6-5v.65h2.17V71.8ZM203.71,73h-2.17v.64h2.17Zm-2.17,1.2v.59h2.17v-.59Zm0,1.75h2.17v-.59h-2.17Zm3.17,2.17a5.75,5.75,0,0,0,1.62-1.25l.84.53a7.31,7.31,0,0,1-2,1.51,7.65,7.65,0,0,0-.64-.62l-.26.22a9,9,0,0,0-1.2-1.19l.7-.49A7.38,7.38,0,0,1,205,78Zm2.12-7c.07-.3.12-.64.17-.94h-1.89v-.87H210v.87h-2c-.1.32-.18.65-.27.94h1.89v5.66h-4.25V71.12Zm1.91.78H206.3v.87h2.44Zm0,1.6H206.3v.87h2.44Zm0,1.62H206.3V76h2.44ZM208.48,77a12.68,12.68,0,0,1,1.7,1.39l-.81.56a12.5,12.5,0,0,0-1.65-1.46Z"
          fill="#fff"
        />
        <path
          d="M220.91,76.63a4.29,4.29,0,0,1-1.49,1.21,17.93,17.93,0,0,0,2.32.35,2.7,2.7,0,0,0-.46.71,15.5,15.5,0,0,1-3-.61,16.58,16.58,0,0,1-3.42.65,3.21,3.21,0,0,0-.39-.7,16.64,16.64,0,0,0,2.74-.36,7.35,7.35,0,0,1-1-.55,9.77,9.77,0,0,1-.94.39,3.5,3.5,0,0,0-.54-.55,8.76,8.76,0,0,0,.82-.33,5.3,5.3,0,0,1-.53-.53l.7-.27a4.58,4.58,0,0,0,.51.47,4.93,4.93,0,0,0,.79-.53h-1.17V74.25a5.38,5.38,0,0,1-.6.58,4.46,4.46,0,0,0-.53-.47,6.09,6.09,0,0,1-.66.8v3.76h-.9V76a10.49,10.49,0,0,1-.87.66,5,5,0,0,0-.56-.66,7.41,7.41,0,0,0,2.34-2.32l.76.27a5.68,5.68,0,0,0,1.36-1.82h-3.59V70.32h2.44v-.44h-3v-.71h9.49v.71h-3.05v.44h2.6v1.85h-4.76l.72.2c-.06.1-.1.22-.16.33h4.48v.69h-4.9a3.88,3.88,0,0,1-.23.33h4.57V76h-2.76a4.31,4.31,0,0,1-.37.37h2.45l.18,0Zm-6.16-4.11a7.89,7.89,0,0,1-2.32,2.1,7.11,7.11,0,0,0-.6-.63,6.62,6.62,0,0,0,2-1.75Zm-1.19-1h1.52V71h-1.52ZM216,70.32h1.61v-.44H216Zm1.61,1.21V71H216v.57Zm-.88,3.08H220v-.38h-3.27Zm0,.86H220v-.38h-3.27ZM217,77a8.42,8.42,0,0,0,1.39.59,5,5,0,0,0,1.16-.59Zm1.6-6v.57h1.63V71Z"
          fill="#fff"
        />
        <path
          d="M229.75,74.24a10.58,10.58,0,0,0,4.06,3.24,5.36,5.36,0,0,0-.75.89,11.77,11.77,0,0,1-3.84-3.31V78.9h-1.08V75.11a12,12,0,0,1-3.83,3.24,7.53,7.53,0,0,0-.75-.87,10.53,10.53,0,0,0,4-3.24h-3.72v-1h4.26V71.55h-3.47v-1h3.47V68.82h1.08v1.72h3.55v1h-3.55v1.68h4.32v1Z"
          fill="#fff"
        />
        <path
          d="M244.62,75.14A19.74,19.74,0,0,0,243,73.71a4.78,4.78,0,0,1-.85,1.13,5.89,5.89,0,0,0-.83-.51,9.79,9.79,0,0,0,4.27,3.21,4.53,4.53,0,0,0-.77.92,10.48,10.48,0,0,1-3.83-3V78.9h-1.08V75.44a11,11,0,0,1-3.79,3,5,5,0,0,0-.73-.87,10.1,10.1,0,0,0,4.52-3.7V71.26h-4.1v-1h4.1V68.82H241v1.45h4.24v1H241v2.56a5.46,5.46,0,0,0,.31.47,5.19,5.19,0,0,0,1.51-2.84l1,.17a6.8,6.8,0,0,1-.39,1.23,22.27,22.27,0,0,1,1.9,1.59Zm-5.94-3.51a8.31,8.31,0,0,1-.41,1.25c.44.37.9.8,1.17,1.08l-.7.73a12,12,0,0,0-.9-.94,5.65,5.65,0,0,1-1.33,1.63,6.49,6.49,0,0,0-.79-.67,5.53,5.53,0,0,0,2-3.26Z"
          fill="#fff"
        />
        <path
          d="M205.35,92.13a5.05,5.05,0,0,0,1.33-1.2l.85.49a8,8,0,0,1-1.82,1.52,4,4,0,0,0-.66-.63l.12-.07h-2.69v.55h-.77v-3l-.27.33a1.88,1.88,0,0,0-.17-.27,12.35,12.35,0,0,1-.6,3,3.06,3.06,0,0,0-.78-.42,15.81,15.81,0,0,0,.63-5.26V83.65h0a21.54,21.54,0,0,0,3.84-.64l.75.69a21.72,21.72,0,0,1-3.74.63v.44h3.77v2.38h-3.77c0,.53,0,1.16,0,1.81a6.79,6.79,0,0,0,.82-1.79l.72.19c-.07.25-.17.51-.26.75h.8c-.09-.23-.2-.49-.31-.71l.69-.17a4.43,4.43,0,0,1,.42.88h1.06v.67h-1.14v.51h1v.6h-1v.51h1V91h-1v.52h1.22Zm-4-6.56v.8h2.86v-.8Zm2,3.22h-.94v.51h.94Zm0,1.11h-.94v.51h.94Zm-.94,1.64h.94V91h-.94Zm4.57-6.49c0-.28.09-.59.12-.87h-1.7v-.87H210v.87H208.2c-.08.3-.16.59-.23.87h1.66V90.7h-3.89V85.05Zm1.7.79h-2.16v.85h2.16Zm0,1.6h-2.16v.87h2.16Zm0,1.6h-2.16v.87h2.16Zm0,1.91a8.85,8.85,0,0,1,1.37,1.37l-.77.53A9.66,9.66,0,0,0,208,91.41Z"
          fill="#fff"
        />
        <path
          d="M213.38,87.19v5.73h-1V83.26h4v3.93Zm0-3.12v.77h2.07v-.77Zm2.07,2.32v-.82h-2.07v.82Zm.25,5V92h-.95V88.07h4.15v3.31Zm0-2.45v1.6h2.24v-1.6Zm5.73,2.69c0,.61-.14.93-.54,1.11a6.48,6.48,0,0,1-2,.17,3.58,3.58,0,0,0-.36-1c.7,0,1.41,0,1.63,0s.27-.08.27-.27v-4.4h-3.11V83.26h4.15Zm-3.17-7.56v.78h2.13v-.78Zm2.13,2.34v-.83h-2.13v.83Z"
          fill="#fff"
        />
        <path
          d="M233.19,83.26v9.62h-1v-.51h-7.06v.56h-.94V83.26Zm-1,8.3V84.05h-7.06v7.51Zm-.31-1.69h-1.49v.88c0,.36-.09.48-.36.56a4.88,4.88,0,0,1-1.32.09,1.76,1.76,0,0,0-.22-.55h.88c.13,0,.16,0,.16-.15v-.84h-4.08v-.6h4.08v-.36h.86v.36h1.49Zm-1.22-2a6.32,6.32,0,0,1,1,1l-.54.41c-.11-.14-.27-.29-.43-.46-1.87.06-3.75.11-5.09.13l0-.58,2.6,0v-.45H226V85.67h2.26v-.39H225.6v-.61h2.62v-.45h.84v.45h2.68v.61h-2.68v.39h2.35v2.22Zm-3.27,3.33a5.11,5.11,0,0,0-.85-1l.63-.33a4.88,4.88,0,0,1,.92,1Zm-.65-4.68h1.46v-.4h-1.46Zm1.46.87V87h-1.46v.41Zm.84-1.27v.4h1.52v-.4Zm1.52,1.27V87h-1.52v.41Zm-.36.91-.2-.17.41-.26h-1.37v.45Z"
          fill="#fff"
        />
        <path
          d="M239.16,83.64c-.31.91-.69,2.06-1,2.92a3.39,3.39,0,0,1,.9,2.28,1.59,1.59,0,0,1-.45,1.33,1.77,1.77,0,0,1-.6.2,3.4,3.4,0,0,1-.72,0,2.26,2.26,0,0,0-.23-.88,2.76,2.76,0,0,0,.56,0,.61.61,0,0,0,.33-.1.88.88,0,0,0,.21-.7,3,3,0,0,0-.91-2c.29-.75.58-1.72.78-2.46h-1.18v8.72h-.9V83.29h2.43l.14,0Zm6.17,3a16.57,16.57,0,0,1-1.38.93,8.7,8.7,0,0,0,1.79,3.18,3.49,3.49,0,0,0-.66.67A10.76,10.76,0,0,1,243.23,88l-.13.06c.55,1.78.31,3.67-.33,4.25a1.4,1.4,0,0,1-1.17.48c-.21,0-.57,0-.87,0a2.22,2.22,0,0,0-.25-.91c.36,0,.72,0,1,0a.66.66,0,0,0,.61-.27,2.87,2.87,0,0,0,.4-1.89,12,12,0,0,1-3,1.76,2.68,2.68,0,0,0-.61-.74,8.71,8.71,0,0,0,3.46-2,6.38,6.38,0,0,0-.19-.72,14.12,14.12,0,0,1-2.31,1.46,3.84,3.84,0,0,0-.55-.74,9.1,9.1,0,0,0,2.54-1.46,4.59,4.59,0,0,0-.46-.75,11.23,11.23,0,0,1-1.92,1.17,4.05,4.05,0,0,0-.61-.74,8.54,8.54,0,0,0,2.48-1.46h-2.23V84.6h2a6.7,6.7,0,0,0-1.16-1.25l.76-.47a6.48,6.48,0,0,1,1.3,1.34l-.55.38h1.13a10.61,10.61,0,0,0,1.1-1.77l1,.29a14.71,14.71,0,0,1-1,1.48h1.88v.88h-3A3.78,3.78,0,0,1,242,86a5.32,5.32,0,0,1,.82,1.31,13.67,13.67,0,0,0,1.87-1.35Z"
          fill="#fff"
        />
        <path
          d="M112.57,17.47l3.49-2.27.51.78-7.79,5-.5-.77,3.38-2.2-.87-1.35-2.49,1.61-.48-.75L110.3,16l-.81-1.25L107.42,16l-.48-.75a11.79,11.79,0,0,1-.73,1.51,3.93,3.93,0,0,0-1-.35A13.56,13.56,0,0,0,107,12l-1.47,1-.52-.8,2.94-1.91a10.53,10.53,0,0,0,5.81.52,5.42,5.42,0,0,0-.08,1.15A9.53,9.53,0,0,1,112,12l.46.72-2.11,1.37.82,1.25,2.5-1.62.49.75-2.51,1.62ZM107,15.27,112,12a13.81,13.81,0,0,1-4-.66A15.65,15.65,0,0,1,107,15.27Z"
          fill="#002a5e"
        />
        <path
          d="M125.08,6a28.61,28.61,0,0,1-.91,2.79,6.13,6.13,0,0,0,2.9,1.51,2.2,2.2,0,0,0-.3,1,7.11,7.11,0,0,1-3.21-1.82l.89,2.29c.2.51.18.82-.07,1.1a3.85,3.85,0,0,1-1.51.81,4.47,4.47,0,0,0-.68-.78c.56-.2,1-.4,1.2-.46s.2-.15.14-.31L122.71,10c-.48,1.1-1,2.18-1.42,3.06l-.91-.6.1-.17c-.92.76-1.89,1.54-2.65,2.14l-.56-.83c.32-.23.72-.54,1.14-.87l-1-2.67-1.09.43-.35-.88,1.1-.43-.8-2-1.17.46-.34-.88,3.17-1.24.34.88-1.12.44.8,2,1-.38.35.88-1,.38.94,2.4,1-.82.42.71c.46-.81,1.09-2,1.67-3.05l-1-2.57-2.43,1-.34-.88,2.43-1-.67-1.7.92-.36.67,1.7,2.59-1,.34.88-2.59,1,.17.42a13.24,13.24,0,0,0,1.14,1.66,15.73,15.73,0,0,0,.55-2.23Zm-5.14,1.58A8.71,8.71,0,0,1,121.72,9l-.64.69a7.82,7.82,0,0,0-1.75-1.48Zm3.53-3a6.75,6.75,0,0,0-1.62-.66l.34-.7a7.65,7.65,0,0,1,1.64.57Z"
          fill="#002a5e"
        />
        <path
          d="M132.26,9.63c.08.47.05.78-.21,1a3,3,0,0,1-1.33.42,3.89,3.89,0,0,0-.43-.9l.92-.14c.13,0,.16-.09.14-.23l-.45-2.59-1,.86-.44-.65a11.92,11.92,0,0,1-.06,4,3.2,3.2,0,0,0-.9-.31,14.47,14.47,0,0,0-.2-5.37l-.67-3.87,3.16-.55Zm-3.55-7L129,4.1l.29-.3a9.5,9.5,0,0,1,1.2.84l-.4-2.3Zm.5,2.94c.09.48.17,1,.25,1.61l1.29-.82-.22-1.23-.35.41a14.61,14.61,0,0,0-1.13-1Zm4.22-3.47a3.1,3.1,0,0,1-1.16,3.18,3.66,3.66,0,0,0-.77-.59,2.29,2.29,0,0,0,1-2.44L132.3,1l3.43-.59.42,2.42c0,.31.08.42.22.39l.52-.09a1.67,1.67,0,0,0,.48-.15l.21.8a1.57,1.57,0,0,1-.53.16l-.63.11c-.83.14-1.07-.17-1.23-1.06l-.26-1.49-1.55.27Zm3.95,2.75A6.88,6.88,0,0,1,136,8.31a7.22,7.22,0,0,0,2.42.58,3.49,3.49,0,0,0-.5,1,7.49,7.49,0,0,1-2.61-.77,9.16,9.16,0,0,1-2.27,1.59,3.65,3.65,0,0,0-.69-.77,8.38,8.38,0,0,0,2.1-1.35,7.92,7.92,0,0,1-1.95-2.08l.34-.17-.68.12L132,5.5l4.48-.78.17-.06Zm-3.91,1.42a5.7,5.7,0,0,0,1.66,1.62,5.69,5.69,0,0,0,1.07-2.09Z"
          fill="#002a5e"
        />
        <path
          d="M143.24,7.58a8.87,8.87,0,0,1-2.59,1.92,8.47,8.47,0,0,0-.75-.7,7,7,0,0,0,2.4-1.58Zm6.6-1.16,0,.82-4.41-.12-.08,2.73-1,0,.07-2.73L140,7l0-.83ZM143.34.91l-3-.08,0-.83,9.43.26,0,.83L146.73,1l0,.73,2.5.07-.07,2.58-8.25-.23L141,1.57l2.37.06Zm-2,4.65,0-.8,7.15.2,0,.8Zm.48-2.15,1.4,0,0-1.06-1.4,0Zm2.35.07,1.47,0,0-1.06-1.47,0ZM145.76,1l-1.47,0,0,.72,1.48,0Zm1.39,6.41a17,17,0,0,1,2.54,1.74l-.82.57a17,17,0,0,0-2.48-1.81Zm-.46-4.9,0,1,1.49,0,0-1.06Z"
          fill="#002a5e"
        />
        <path
          d="M155.52,6.64c-.37,0-.75.05-1.11.05l-.66,2.81c-.12.52-.29.75-.63.82a3.58,3.58,0,0,1-1.56-.18,4,4,0,0,0-.08-1l1.05.25c.15,0,.22,0,.26-.16l.59-2.52-1.33,0,0-1,1.6,0,.49-2.12-1.37-.32.21-.92,1.37.32L154.8.63l1,.22-.48,2.09,1.24.29-.21.92-1.25-.29-.43,1.87,1,0Zm5.83.65a6.81,6.81,0,0,1-2.69,2.62,8.52,8.52,0,0,0,2.45,1.6,4.15,4.15,0,0,0-.85.77,8.58,8.58,0,0,1-2.59-1.92,10.71,10.71,0,0,1-3.33.55,2.82,2.82,0,0,0-.28-1A8.61,8.61,0,0,0,157,9.52a8.28,8.28,0,0,1-1.11-2.66l.3,0-.53-.13.21-.93,4.72,1.1h.18Zm-2.84-4.16a3.07,3.07,0,0,1-2.34,2.49,3.8,3.8,0,0,0-.49-.88,2.25,2.25,0,0,0,1.91-1.86l.27-1.16,3.52.82-.55,2.4c-.08.3-.08.43.1.47l.71.17a2.76,2.76,0,0,0,.57.06c-.05.26-.1.62-.12.85a1.9,1.9,0,0,1-.63-.08l-.81-.18c-.88-.21-1-.6-.77-1.52l.35-1.46-1.65-.39ZM156.89,7A5.94,5.94,0,0,0,158,9.15a5.83,5.83,0,0,0,1.9-1.47Z"
          fill="#002a5e"
        />
        <path
          d="M168.89,14a31,31,0,0,1,2.32,2.39l-1.08.12A21.27,21.27,0,0,0,168,14.14l.8-.21-3-1.37.65.76a14.93,14.93,0,0,1-3.63-.15,7.79,7.79,0,0,0-.37-1,11.6,11.6,0,0,0,3.31.39l-1.61-.74,1.84-4,6.52,3-1.85,4Zm2.86-6a1.86,1.86,0,0,1-.13.23,5.08,5.08,0,0,0,2.48,2.32,2.77,2.77,0,0,0-.85.53,5.41,5.41,0,0,1-2.17-2.33c-.57.29-1.56.36-3.39-.19a2.79,2.79,0,0,0-.15-.9L165,6.46l.33-.73,2.9,1.33-.3.66c2.3.72,2.78.26,3-.17l.06-.13L170.14,7a5.14,5.14,0,0,1-1.16.6,4.39,4.39,0,0,0-.48-.79,4.59,4.59,0,0,0,2-1.32l.75.56c-.14.15-.27.28-.42.42l3.12,1.43.15,0,.54.5a9.53,9.53,0,0,1-1.55,1.08L172.47,9a6.44,6.44,0,0,0,.74-.5l-1.39-.64Zm-6.46,3.66L170,13.78l.26-.58-4.69-2.15Zm.53-1.15,4.68,2.15.26-.56-4.69-2.15Zm2.55-4.36L165.92,5l.32-.7,2.45,1.12Zm-2,3.24L171,11.51l.26-.57-4.69-2.15Z"
          fill="#002a5e"
        />
        <path
          d="M181.88,20.5a16.66,16.66,0,0,1-2.55-.21A7.29,7.29,0,0,0,181,22.85a4.16,4.16,0,0,0-1.06.37,6.72,6.72,0,0,1-1.74-6.13l-1.34-1-1.58,2.15,2.55,1.12a6.78,6.78,0,0,0-.59.68,37.47,37.47,0,0,0-3.54-1.4h-.1a4.14,4.14,0,0,0,.26-1.09.88.88,0,0,0,.9-.32L176,15.51l-1.37-1,.53-.73,1.53,1.12L179.56,11,185,15l-.5.68-4.65-3.41-.47.64,4.4,3.22-.49.66-4.39-3.22-.45.62,4.39,3.22-.48.65L178,14.87l-.47.63,5.54,4.07-.54.73-1-.73ZM179,17.72a5.51,5.51,0,0,0,.05,1.72,16.14,16.14,0,0,0,2.44.11Z"
          fill="#002a5e"
        />
        <path
          d="M111.06,28.18l1.14-.95,7.24,4.53-1,.86-2-1.34-2.17,1.8,1,2.22-1,.83Zm2.76,3.94,1.7-1.41-.94-.64c-.77-.5-1.53-1-2.3-1.59l0,0c.41.86.78,1.71,1.14,2.55Z"
          fill="#002a5e"
        />
        <path
          d="M120,29.83l-3.89-6.24,1.07-.67,3.93,6.29c.18.29.36.32.49.24l.17-.13.64.72a1.91,1.91,0,0,1-.52.45C121.12,31,120.53,30.66,120,29.83Z"
          fill="#002a5e"
        />
        <path
          d="M122.42,26.48a2.93,2.93,0,0,1,1.19-4c1.46-.67,2.73,0,3.44,1.57a4.53,4.53,0,0,1,.21.62l-3.58,1.63a1.83,1.83,0,0,0,2.47.91,2.64,2.64,0,0,0,1.13-1l.73.55a3.6,3.6,0,0,1-1.6,1.42A2.9,2.9,0,0,1,122.42,26.48ZM126,24.26c-.43-.94-1.11-1.3-1.91-.93a1.63,1.63,0,0,0-.73,2.13Z"
          fill="#002a5e"
        />
        <path
          d="M130.45,23.13l-2.53-2.3,1.32-.37,1,1c.26.28.54.56.83.83h0l.21-1.12.29-1.34,1.27-.35L132,22.85l2.69,2.38-1.33.37-1.11-1c-.28-.28-.59-.59-.89-.86l-.05,0c-.09.38-.17.78-.25,1.18l-.34,1.43-1.28.35Z"
          fill="#002a5e"
        />
        <path
          d="M140.93,17.45l-2.38.08,0-1.08,6-.2,0,1.08-2.39.08.24,7-1.27,0Z"
          fill="#002a5e"
        />
        <path
          d="M145.69,21.4a2.94,2.94,0,0,1,3.1-2.85c1.59.18,2.32,1.43,2.13,3.12a3.47,3.47,0,0,1-.14.64l-3.9-.44a1.83,1.83,0,0,0,1.65,2,2.59,2.59,0,0,0,1.48-.28l.35.84a3.73,3.73,0,0,1-2.1.41A2.91,2.91,0,0,1,145.69,21.4Zm4.17-.08c.11-1-.29-1.68-1.16-1.78A1.64,1.64,0,0,0,147,21Z"
          fill="#002a5e"
        />
        <path
          d="M152.87,22.36c.55-1.88,2.08-2.7,3.38-2.32a2.28,2.28,0,0,1,1.37,1.11l.23-1,.63-2.15,1.22.35-2.46,8.42-1-.29.1-.68h0a2.57,2.57,0,0,1-1.92.29C152.92,25.68,152.3,24.29,152.87,22.36Zm3.61,2.5.83-2.84a1.9,1.9,0,0,0-1.12-.9c-.81-.24-1.69.34-2.06,1.6s-.08,2.2.83,2.46A1.75,1.75,0,0,0,156.48,24.86Z"
          fill="#002a5e"
        />
        <path
          d="M160.45,24.77c.87-1.75,2.53-2.29,3.74-1.69a2.36,2.36,0,0,1,1.15,1.34l.4-.92,1-2,1.14.56L164,29.91l-.92-.47.22-.65,0,0a2.58,2.58,0,0,1-2-.05C159.9,28,159.54,26.57,160.45,24.77Zm3.1,3.1,1.33-2.64a1.87,1.87,0,0,0-.95-1.09c-.75-.38-1.72,0-2.31,1.2s-.47,2.15.37,2.58A1.79,1.79,0,0,0,163.55,27.87Z"
          fill="#002a5e"
        />
        <path
          d="M167.42,28.49a3,3,0,0,1,4.11-.93c1.29,1,1.31,2.4.3,3.77a2.8,2.8,0,0,1-.44.49l-3.17-2.33a1.83,1.83,0,0,0,.42,2.6,2.54,2.54,0,0,0,1.42.49l-.12.91a3.63,3.63,0,0,1-2-.7A2.9,2.9,0,0,1,167.42,28.49Zm3.66,2c.61-.83.59-1.6-.12-2.13a1.64,1.64,0,0,0-2.22.41Z"
          fill="#002a5e"
        />
        <path
          d="M175.51,31.06l.74.72-.7.85,0,0a2.06,2.06,0,0,1,2.09.32,1.53,1.53,0,0,1,.4.57l-.94.62a1.82,1.82,0,0,0-.36-.49,1.69,1.69,0,0,0-2-.1l-2.65,2.69-.9-.88Z"
          fill="#002a5e"
        />
        <path
          d="M68.9,291.72c-.2-.36-.67-1-1.06-1.56v4.92h-1v-4.59a11.51,11.51,0,0,1-1.69,2.73,6.49,6.49,0,0,0-.51-1,11.07,11.07,0,0,0,2-3.23H65v-.95h1.86v-1.43l-1.61.23a2.58,2.58,0,0,0-.29-.8,19.72,19.72,0,0,0,4-.94l.57.85a13.66,13.66,0,0,1-1.67.46v1.63h1.71V289H67.84v.09c.34.32,1.43,1.49,1.67,1.78Zm5,3.09c-.08-.34-.19-.74-.33-1.18-3.57.56-4.06.67-4.41.84a8.91,8.91,0,0,0-.36-1c.3-.11.52-.64.83-1.5a46.56,46.56,0,0,0,1.66-6.68l1.09.2a48.27,48.27,0,0,1-2,7.64l2.92-.41c-.32-1-.73-2-1.1-2.88l.95-.34a32.52,32.52,0,0,1,1.8,4.89Z"
          fill="#002a5e"
        />
        <path
          d="M83.54,291.48A7.43,7.43,0,0,0,86,292.6a4,4,0,0,0-.66.81,6.53,6.53,0,0,1-1.5-.69c-.12,1.27-.24,1.85-.49,2.08a1,1,0,0,1-.71.28,8.9,8.9,0,0,1-1.28,0,1.8,1.8,0,0,0-.26-.8c.48,0,1,.05,1.13.05s.28,0,.36-.09a2.2,2.2,0,0,0,.28-1.12H80.62c-.46,1-1.38,1.6-3.27,2a2.72,2.72,0,0,0-.48-.76,4,4,0,0,0,2.69-1.23h-2v-.39a8.75,8.75,0,0,1-1.3.57,2.55,2.55,0,0,0-.58-.72,7.93,7.93,0,0,0,2.26-1.1H76v-.81h2.85a3.11,3.11,0,0,0,.35-.54h-2v-3.06H84.5v3.06H80.27a4.74,4.74,0,0,1-.31.54h5.73v.81Zm-5-5H76v-.78H78.5V285h1v.69h1.2v.78h-1.2v.45h-1Zm-.3,1.84h5.28v-.6H78.2Zm0,1.2h5.28v-.6H78.2Zm5.1,2.86a6.87,6.87,0,0,1-1-.88h-3a6.79,6.79,0,0,1-1.07.88h1.71a4.8,4.8,0,0,0,.15-.7h1a4.8,4.8,0,0,1-.15.7Zm-1.17-5.9H81v-.78h1.09V285h1v.69h2.52v.78H83.14v.45h-1Z"
          fill="#002a5e"
        />
        <path
          d="M90.65,293.88c0,.47-.09.77-.37.93a2.9,2.9,0,0,1-1.39.19,3.81,3.81,0,0,0-.26-1h.92c.13,0,.18-.05.18-.2v-2.63l-1.11.69-.32-.72a12.41,12.41,0,0,1-.74,3.91,3.17,3.17,0,0,0-.84-.47,14.39,14.39,0,0,0,.72-5.32v-3.93h3.21Zm-2.3-4.58c0,.49,0,1.06,0,1.63l1.41-.59v-1.25l-.42.35a13,13,0,0,0-1-1.13Zm0-3v1.54l.34-.24a10.12,10.12,0,0,1,1,1v-2.33ZM96.51,290a6.85,6.85,0,0,1-1.92,3.24,7,7,0,0,0,2.29,1,3.38,3.38,0,0,0-.66.89,7.42,7.42,0,0,1-2.44-1.2,9.15,9.15,0,0,1-2.51,1.17,3.62,3.62,0,0,0-.55-.88,8.48,8.48,0,0,0,2.3-1,8.28,8.28,0,0,1-1.57-2.38l.37-.11h-.7v-1h4.56l.17,0Zm-3.42-3.38a3.1,3.1,0,0,1-1.68,2.94,3.18,3.18,0,0,0-.66-.71,2.31,2.31,0,0,0,1.41-2.24V285.4h3.47v2.47c0,.31,0,.42.16.42h.53a2.07,2.07,0,0,0,.49-.07l.08.83a1.85,1.85,0,0,1-.55.06H95.7c-.85,0-1-.35-1-1.25v-1.51H93.09Zm-.67,4.12a5.73,5.73,0,0,0,1.37,1.88,5.71,5.71,0,0,0,1.4-1.88Z"
          fill="#002a5e"
        />
        <path
          d="M100.91,290.93c-.13-.29-.37-.78-.62-1.22v5.38H99.4v-5.16a12.75,12.75,0,0,1-1.23,3,5.15,5.15,0,0,0-.48-1A11.43,11.43,0,0,0,99.25,288H98v-.94H99.4V285h.89v2.07h1.11V288h-1.11v.53c.25.33,1,1.41,1.18,1.67Zm4.28,3h2.6v.76h-4.92v.38H102v-3.31l-.56.55a4.57,4.57,0,0,0-.52-.84,6.13,6.13,0,0,0,1.66-2.09h-.88v-2.16h2.61v2.16H103l.41.14c-.11.22-.23.46-.36.68h1.21c-.06-.18-.12-.37-.19-.53l.88-.24c.09.24.2.52.29.77h2.32v.68h-2.39v.56h2v.68h-2v.56h2v.69h-2Zm-2.07-7.39h-1.83v-.77h1.83V285h.9v2.06h-.9Zm-.69,2.18h1v-.84h-1Zm1.87,2.72v-.56h-1.43v.56Zm0,.68h-1.43v.56h1.43ZM102.87,294h1.43v-.56h-1.43Zm4.53-4.55h-2.64v-2.16h2.64Zm.47-3.61v.77h-2V287H105V285h.89v.81Zm-1.26,2.11h-1.08v.84h1.08Z"
          fill="#002a5e"
        />
        <path
          d="M110.52,291.7c0,.14,0,.28-.07.43h4.11V295h-.9v-.38H111.3v.4h-.87v-2.76a7.27,7.27,0,0,1-.84,2.41,3.79,3.79,0,0,0-.88-.43,8.64,8.64,0,0,0,1-4.47v-1.38h4.63v3.31Zm2.3-6.32-.19.29c.83.49,1.8,1.09,2.32,1.48l-.53.86c-.53-.43-1.52-1.08-2.36-1.62a9.67,9.67,0,0,1-.93.88h2.1v.64H111v-.48a10.69,10.69,0,0,1-1.69,1.11,5.5,5.5,0,0,0-.55-1,7.49,7.49,0,0,0,3.16-2.55Zm.56,5.58v-.62h-2.76c0,.2,0,.41,0,.62Zm-2.75-1.84v.59h2.75v-.59Zm.67,3.73v1h2.36v-1Zm5-.64h-1v-6h1Zm2-7.18v8.77c0,.59-.13.89-.48,1a4.35,4.35,0,0,1-1.8.21,4.83,4.83,0,0,0-.33-1c.62,0,1.19,0,1.37,0s.25-.05.25-.25V285Z"
          fill="#002a5e"
        />
        <path
          d="M123.33,290.69c-.35.1-.71.22-1.07.3v2.89c0,.52-.11.79-.42.93a3.66,3.66,0,0,1-1.57.19,3.83,3.83,0,0,0-.29-.93h1.07c.16,0,.22-.05.22-.21v-2.59l-1.27.35-.28-1,1.55-.38v-2.18h-1.4v-.95h1.4V285h1v2.14h1.28v.95h-1.28V290l.94-.25Zm5.83-.69a6.84,6.84,0,0,1-2,3.16,8.56,8.56,0,0,0,2.74,1,4.2,4.2,0,0,0-.65.95,9,9,0,0,1-3-1.29,10.37,10.37,0,0,1-3.11,1.29,2.77,2.77,0,0,0-.51-.94,9,9,0,0,0,2.78-1,8.13,8.13,0,0,1-1.68-2.34l.29-.1h-.55v-1h4.84l.17,0Zm-3.7-3.41a3.09,3.09,0,0,1-1.72,3,4.33,4.33,0,0,0-.67-.75,2.25,2.25,0,0,0,1.43-2.24v-1.19h3.62v2.47c0,.3,0,.42.21.42h.72a2.62,2.62,0,0,0,.57-.06c0,.26,0,.63.08.86a2.31,2.31,0,0,1-.64.06h-.82c-.9,0-1.09-.36-1.09-1.29v-1.51h-1.69Zm-.71,4.13a5.94,5.94,0,0,0,1.54,1.86,6,6,0,0,0,1.52-1.86Z"
          fill="#002a5e"
        />
        <path
          d="M79.07,305.66c0-.15-.07-.34-.11-.55a13.83,13.83,0,0,0-2.85.54c-.06-.18-.2-.67-.31-.92.24-.06.48-.32.81-.72a8.66,8.66,0,0,0,.81-1.1,5.92,5.92,0,0,0-1.39.26,7.68,7.68,0,0,0-.3-.88c.2-.05.38-.27.61-.6A12.78,12.78,0,0,0,77.71,299l.92.38A17.12,17.12,0,0,1,77,302.12l1-.08a14,14,0,0,0,.77-1.43l.86.51a20.29,20.29,0,0,1-2.35,3.4l1.48-.19c-.1-.33-.21-.67-.33-1l.76-.2a16.51,16.51,0,0,1,.7,2.24Zm-2,.54a14.09,14.09,0,0,1-.54,2.75,4.25,4.25,0,0,0-.8-.3,11.27,11.27,0,0,0,.49-2.58Zm1.09-.14a20.17,20.17,0,0,1,.29,2.27l-.8.16a20.51,20.51,0,0,0-.26-2.3Zm1-.32a14.44,14.44,0,0,1,.54,1.77l-.74.26a13.75,13.75,0,0,0-.5-1.8Zm6.62,2.16v.93H79.58v-.93h2.66v-1.85H80.15v-.91h5.24v.91H83.26v1.85Zm-4.78-3a10.51,10.51,0,0,0-1.29-2.16,17.27,17.27,0,0,0,1.13-2l.9.22c-.31.56-.71,1.23-1.07,1.76a12.88,12.88,0,0,1,1.17,1.83Zm4.67-4.43H79.88v-.92h5.84Zm-2.08.5a15.8,15.8,0,0,1-1.09,1.74,12.17,12.17,0,0,1,1.22,1.79l-.83.37a10.33,10.33,0,0,0-1.35-2.14,16.56,16.56,0,0,0,1.17-2Zm1.24,3.93a11.09,11.09,0,0,0-1.46-2.16,16.15,16.15,0,0,0,1.21-2l.9.23c-.33.56-.75,1.22-1.12,1.75a13.16,13.16,0,0,1,1.28,1.79Z"
          fill="#002a5e"
        />
        <path
          d="M86.7,308.14a12,12,0,0,0,.47-2.38l.55.18a14,14,0,0,1-.44,2.42ZM91,304.79c-.08,2.66-.16,3.61-.39,3.92a.68.68,0,0,1-.57.31,4,4,0,0,1-.87,0,2.12,2.12,0,0,0-.22-.77c.31,0,.57,0,.69,0a.29.29,0,0,0,.28-.12,3.67,3.67,0,0,0,.18-1.18l-.4.16c-.11-.38-.35-1-.54-1.44l.39-.13a10.68,10.68,0,0,1,.55,1.35c0-.41.05-1,.07-1.65H87.28v-5.83H91v.84H89.73v.88h1.06v.41a7.85,7.85,0,0,0,2.85-2.71l.94.29a2.67,2.67,0,0,1-.22.36,9.42,9.42,0,0,0,2.56,1.76,5.29,5.29,0,0,0-.41.91,11.61,11.61,0,0,1-1-.63v.64H92.27v-.53a9.27,9.27,0,0,1-.87.57,4,4,0,0,0-.62-.61V302H89.73v.82h1.09v.79H89.73v.87H91Zm-2.69,1.14a18,18,0,0,1,.14,2L88,308c0-.54,0-1.36-.1-2Zm-.25-5.63v.88h.83v-.88Zm.83,1.66h-.83v.82h.83Zm-.83,2.48h.83v-.87h-.83Zm.9,1.36a17.58,17.58,0,0,1,.37,1.77l-.46.1a17.88,17.88,0,0,0-.36-1.8Zm4.06.17c-.07.31-.16.59-.24.87a5.84,5.84,0,0,1,1.13.88l-.53.67a6.44,6.44,0,0,0-.91-.79,5.35,5.35,0,0,1-1,1.49,6.87,6.87,0,0,0-.68-.59,4.78,4.78,0,0,0,1.4-2.69Zm-1.64-3h2.24v2.58H91.41Zm.69,1.9h.83v-1.22H92.1Zm3.2-3.43a12.43,12.43,0,0,1-1.43-1.25,9.23,9.23,0,0,1-1.25,1.25Zm.49,4.49a8.35,8.35,0,0,1-.32,1.22,9.46,9.46,0,0,1,1.36,1.1l-.61.7a7.51,7.51,0,0,0-1.1-1,4.44,4.44,0,0,1-.82,1.14,5.3,5.3,0,0,0-.75-.59,4.68,4.68,0,0,0,1.33-2.74Zm-1.7-3h2.34v2.58H94.09Zm.71,1.9h.89v-1.22H94.8Z"
          fill="#002a5e"
        />
        <path
          d="M104.88,299.17a7,7,0,0,0,3.13,4,4.88,4.88,0,0,0-.74.93,6.81,6.81,0,0,1-1-.79c0,.07,0,.15,0,.19-.14,3.34-.29,4.62-.67,5.05a1.22,1.22,0,0,1-.9.45,13.7,13.7,0,0,1-1.71,0,2.35,2.35,0,0,0-.33-1c.66.06,1.29.07,1.55.07a.61.61,0,0,0,.47-.15c.25-.27.39-1.3.5-3.86h-2.53c-.28,2.13-1,4.07-3.82,5.1a3,3,0,0,0-.66-.91,4.48,4.48,0,0,0,3.37-4.19H99.46v-.76a7.25,7.25,0,0,1-.9.86,6.34,6.34,0,0,0-.87-.75,7.83,7.83,0,0,0,2.78-4l1.07.25a10.81,10.81,0,0,1-1.88,3.45H106a9,9,0,0,1-1.87-2.94h-1.85v-.95Z"
          fill="#002a5e"
        />
        <path
          d="M118.72,307h-4.37v1.07c0,.52-.16.75-.6.88a9.26,9.26,0,0,1-2.07.12,3.34,3.34,0,0,0-.36-.85c.69,0,1.5,0,1.67,0s.28,0,.28-.22v-1H109v-.87h4.32v-.57h0a17.41,17.41,0,0,0,1.87-.55H110v-.81h6.78l.24,0,.65.58a15.85,15.85,0,0,1-3.31,1.27v.14h4.37Zm-.15-6.28h-9.51v-.87h4.23a7.92,7.92,0,0,0-.31-.78l1.06-.19a8.35,8.35,0,0,1,.42,1h4.11Zm-8.16,2.9v-2.29h6.92v2.29Zm1-.73h4.83v-.82h-4.83Z"
          fill="#002a5e"
        />
      </g>
    </SVG>
  );
};

export default Chart13;
