import React from "react";
import SVG from '../../../components/SVG'

const Chart23 = () => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327.94 277.75">
      <g>
        <ellipse
          cx="160.03"
          cy="132.9"
          rx="53.76"
          ry="89.33"
          transform="translate(2.85 269.17) rotate(-80.64)"
          fill="none"
          stroke="#002a5e"
          strokeWidth="1.17"
        />
        <circle
          cx="92"
          cy="97.82"
          r="22.5"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="0.84"
        />
        <path
          d="M91.16,94.3v1H85.31v-1h.75V87.21h-.58v.7H84.12v1.62a3.39,3.39,0,0,1,0,.44h1.56v1H84c0,.27-.08.56-.13.84.45.46,1.44,1.65,1.7,2l-.65.84c-.27-.45-.86-1.22-1.32-1.81a6,6,0,0,1-1.87,2.85,3.15,3.15,0,0,0-.71-.7,5.73,5.73,0,0,0,2-4h-2V90h2a3.62,3.62,0,0,0,0-.46v-1.6h-.58a7.74,7.74,0,0,1-.66,1.68,5.33,5.33,0,0,0-.82-.55,9.36,9.36,0,0,0,1-3.46l1,.16c-.07.41-.14.83-.22,1.23h2.66v-.71H91v.95h-4v1.48h3.58v3.82H87.06V94.3Zm-4.1-4.65v1.91h2.6V89.65Z"
          fill="#fff"
        />
        <path
          d="M95.75,86.4c-.29.94-.69,2.08-1,2.95a3.34,3.34,0,0,1,.91,2.3A1.53,1.53,0,0,1,95.17,93a1.18,1.18,0,0,1-.59.23,3.72,3.72,0,0,1-.75,0,2.1,2.1,0,0,0-.24-.91,3.74,3.74,0,0,0,.57,0,.6.6,0,0,0,.33-.09.87.87,0,0,0,.21-.69,3.06,3.06,0,0,0-.89-2.06c.27-.76.56-1.76.77-2.5H93.4v8.71h-.9V86h2.42l.16,0Zm6.4,7.75H99.31v1.53h-1V94.15h-2.9v-.91h2.9v-.92H96v-4h2.32v-.88H95.75v-.89h2.57v-1h1v1H102v.89H99.31v.88h2.41v4H99.31v.92h2.84ZM96.93,90h1.45v-.85H96.93Zm0,1.58h1.45V90.7H96.93Zm3.81-2.43H99.25V90h1.49Zm0,1.58H99.25v.85h1.49Z"
          fill="#fff"
        />
        <path
          d="M90.35,101.58a12.39,12.39,0,0,1-1.06,4.14,4.78,4.78,0,0,0,2,2.12,3.45,3.45,0,0,0-.65.81,5.35,5.35,0,0,1-1.84-2,6.7,6.7,0,0,1-2.44,2.1,4.46,4.46,0,0,0-.37-.59l-.29.26a11.84,11.84,0,0,0-1.37-.66,9.68,9.68,0,0,1-2.89,1A2.28,2.28,0,0,0,81,108a10.64,10.64,0,0,0,2.36-.66c-.53-.19-1.06-.36-1.54-.49.19-.25.41-.57.62-.9H81.15v-.73h1.72c.11-.2.22-.4.32-.6H81.62v-1.84h2v-.42H81.83v-1.21h-.75v-.76h.75V99.26h1.78v-.68h.79v.68h1.89v1.13h.61v.76h-.61v1.21H84.4v.42h2.09v.53A15.4,15.4,0,0,0,88,98.58l.86.16c-.12.65-.26,1.3-.43,1.94h2.78v.9ZM82.47,104h1.14v-.6H82.47Zm.15-4.06v.51h1v-.51Zm1,1.74v-.58h-1v.58ZM83.37,106c-.11.19-.23.36-.33.53.37.11.76.24,1.13.37a1.82,1.82,0,0,0,.72-.9Zm2.37,0a2.45,2.45,0,0,1-.71,1.24,11.63,11.63,0,0,1,1.16.55,5.34,5.34,0,0,0,2.14-2.07,15.3,15.3,0,0,1-.75-2.6,9.21,9.21,0,0,1-.53,1.09,4.88,4.88,0,0,0-.56-.53v1H84.1c-.09.2-.2.4-.31.6H85v-.37h.81v.37h1V106Zm-1.34-6v.51h1.08v-.51Zm1.08,1.74v-.58H84.4v.58ZM84.4,103.4v.6h1.2v-.6Zm3.72-1.82,0,.09a15.75,15.75,0,0,0,.72,3,12.52,12.52,0,0,0,.66-3.06Z"
          fill="#fff"
        />
        <path
          d="M92.29,99.11h5.4v.94H95.14c-.13.48-.29,1-.46,1.46H96.6l.18,0,.62.21c-.57,3.59-2.09,5.87-4.1,7a3.66,3.66,0,0,0-.77-.75,6.5,6.5,0,0,0,2.61-2.52,18.72,18.72,0,0,0-1.81-1.26,5.56,5.56,0,0,1-.76.9,5,5,0,0,0-.71-.67,9.16,9.16,0,0,0,2.21-4.35H92.29Zm2,3.33a9.32,9.32,0,0,1-.47,1,18.74,18.74,0,0,1,1.77,1.16,10.24,10.24,0,0,0,.66-2.11Zm4.75,3.36h-1v-6h1Zm2.62-7v8.62c0,.62-.14.91-.53,1.08a5.94,5.94,0,0,1-2,.19,4.26,4.26,0,0,0-.35-1c.72,0,1.39,0,1.6,0s.27-.07.27-.27V98.79Z"
          fill="#fff"
        />
        <circle
          cx="96.59"
          cy="158.02"
          r="22.5"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="0.84"
        />
        <path
          d="M87.45,152.8A9.71,9.71,0,0,1,86.34,155a5.37,5.37,0,0,0-.7-.48,6.78,6.78,0,0,0,1-2Zm1.42-.71v3.73h-1v-3.73H85.77v-.87h2.11v-1H85.71v-.86h1.2a5.67,5.67,0,0,0-.39-1.38l.84-.21a6.82,6.82,0,0,1,.4,1.36l-.81.23h1.74a10.76,10.76,0,0,0,.54-1.6l.94.2c-.18.49-.37,1-.56,1.4H90.9v.86h-2v1h1.87v.87Zm1.85-4.34H85.88v-.84h2c-.13-.31-.27-.66-.4-.94l1-.26a12.79,12.79,0,0,1,.55,1.2h1.66Zm-.78,4.72a9.25,9.25,0,0,1,.91,1.65l-.71.43a11.56,11.56,0,0,0-.88-1.7Zm5.79-2.09H94.61v5.45h-1v-5.45H92.32v.27c0,1.58-.15,3.78-1.12,5.18a3.55,3.55,0,0,0-.79-.61,8.36,8.36,0,0,0,1-4.57v-3.81a17.32,17.32,0,0,0,3.37-.89l.8.75a18.34,18.34,0,0,1-3.21.81v1.93h3.41Z"
          fill="#fff"
        />
        <path
          d="M98.17,149.68v6.2h-1v-9.71h4.08v3.51Zm0-2.81v.71h2.12v-.71Zm2.12,2.09v-.69H98.17V149Zm3.34,5.43v1.19h-.93v-1.19h-4v-.74h1V150.9H99v-.74h5.39v.74h-.73v2.75h.9v.74Zm-3-3.49v.51h2.08v-.51Zm0,1.62h2.08V152h-2.08Zm2.08,1.13v-.52h-2.08v.52Zm3.56,1.12c0,.51-.1.76-.41.91a3.76,3.76,0,0,1-1.51.18,2.89,2.89,0,0,0-.3-.9c.43,0,.89,0,1,0s.18-.05.18-.2v-5.09H102.1v-3.51h4.16Zm-3.18-7.9v.71h2.17v-.71Zm2.17,2.09v-.69h-2.17V149Z"
          fill="#fff"
        />
        <path
          d="M92.52,160.43a.89.89,0,0,1,0,.27,5.1,5.1,0,0,0,3.23,1.06,3,3,0,0,0-.55.84,5.37,5.37,0,0,1-3-1.21c-.4.51-1.27,1-3.16,1.24a2.73,2.73,0,0,0-.52-.75H85.75v-.81h3.19v.73c2.39-.31,2.64-.93,2.64-1.4v-.14h-.91a5,5,0,0,1-.81,1,4.14,4.14,0,0,0-.77-.52,4.42,4.42,0,0,0,1.27-2l.91.2c-.06.18-.13.36-.21.55H94.5l.14-.05.7.23a9.75,9.75,0,0,1-1,1.63l-.81-.28c.15-.2.31-.49.47-.76H92.52Zm-.09,6.67a28.65,28.65,0,0,1,3.1,1.2l-.92.56a21.9,21.9,0,0,0-3-1.24l.63-.52H89l.91.42a14.68,14.68,0,0,1-3.37,1.37,7.87,7.87,0,0,0-.76-.77,11.81,11.81,0,0,0,3.17-1H87.18v-4.42h7.17v4.42Zm-3.75-6.94H86v-.77h2.7ZM88.17,164h5.16v-.62H88.17Zm0,1.24h5.16v-.62H88.17Zm0,1.26h5.16v-.64H88.17Z"
          fill="#fff"
        />
        <path
          d="M100.68,161.43h-4v-.81H98.6a12.9,12.9,0,0,0-.75-1.48l.74-.41a12.74,12.74,0,0,1,.76,1.44l-.73.45h2.06Zm-3.52,1.46v-.81h3.12v.81Zm0,.65h3.13v.79H97.16Zm3.13,4.71H98v.49h-.8V165h3.13ZM98,165.85v1.57h1.52v-1.57Zm7.69-6.47c-.08,4.07-.09,8.16.43,8.31a4.67,4.67,0,0,0,.18-1.43,4.73,4.73,0,0,0,.56.79c-.14,1.56-.59,1.91-1.16,1.67-1.07-.38-1-4.06-1-8.37H103v3.1h1.29v.94H103v4.49h-1v-4.49h-1.36v-.94H102v-3.1h-1.23v-1Z"
          fill="#fff"
        />
        <circle
          cx="234.74"
          cy="168.95"
          r="22.5"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="0.84"
        />
        <path
          d="M226.79,165.12a6.16,6.16,0,0,0,2.71.43c.63,0,3.66,0,4.45,0a3.32,3.32,0,0,0-.42,1h-4c-2.26,0-3.62-.35-4.47-1.87a6.77,6.77,0,0,1-.76,2.13,2.48,2.48,0,0,0-.65-.61,9.11,9.11,0,0,0,.75-4.52l.84.05c0,.4,0,.83,0,1.26a4.27,4.27,0,0,0,.68,1.42v-3.53h-2V160h1.73v-1.11h-1.45v-.91h1.45v-1.23h.91v1.23h1.36v.75a5.38,5.38,0,0,0,1.41-2l.88.22c-.07.15-.15.3-.22.46h3.6s0,.21,0,.33c-.11,1.92-.21,2.64-.45,2.92a.81.81,0,0,1-.63.27,6.9,6.9,0,0,1-1,0,1.73,1.73,0,0,0-.19-.65c.37,0,.69,0,.83,0a.31.31,0,0,0,.28-.1,5,5,0,0,0,.31-2.1h-3.12a5.09,5.09,0,0,1-.31.45h.09v.83H230v-1h.68v1h.67v-.83h.71v1.42h-1.43a1.5,1.5,0,0,1-.88.88l.34.09a4.09,4.09,0,0,1-.23.47h3.75s0,.24,0,.36c-.1,2.09-.21,2.86-.48,3.15a.79.79,0,0,1-.62.28,8.91,8.91,0,0,1-1.07,0,1.77,1.77,0,0,0-.19-.68c.37,0,.7,0,.84,0a.31.31,0,0,0,.3-.1,6.27,6.27,0,0,0,.34-2.33h-3.27c-.12.18-.24.34-.36.49h.14v.86h.69v-1h.74v1h.69v-.86h.72v1.49h-1.51c-.2.52-.73,1-2,1.29a2,2,0,0,0-.44-.54,2.44,2.44,0,0,0,1.59-.75h-1.16v-.9l-.11.1a4.81,4.81,0,0,0-.43-.32V163h-1.23Zm0-4.26v1.26H228v.3a5.37,5.37,0,0,0,1-1.36l-.42.09a1.52,1.52,0,0,0-.37-.51c1-.15,1.46-.43,1.66-.73h-1.23v-.73l-.12.12c-.16-.12-.44-.32-.66-.45h-1.35V160h1.61v.9Z"
          fill="#fff"
        />
        <path
          d="M237,159.14a2.13,2.13,0,0,1-1.71,1.51,2.73,2.73,0,0,0-.4-.6c.68-.15,1.18-.42,1.34-.91H235v-.68h2V158h-1.56v-.65h1.56v-.6h.93v.6h1.59V158h-1.59v.5h2v.68h-1.37v.32c0,.21,0,.29.17.29h.48a1.82,1.82,0,0,0,.51-.06c0,.2,0,.41.05.61a2.15,2.15,0,0,1-.55,0h-.69c-.63,0-.75-.28-.75-.9v-.31Zm6.94-1.17c0,2.36,0,4.19.35,4.19.14,0,.19-.56.2-1.31a2.83,2.83,0,0,0,.58.57c-.08,1.14-.23,1.62-.86,1.62-1,0-1.15-1.76-1.18-4.25h-1a12.31,12.31,0,0,1-.22,1.52c.43.25.82.52,1.09.71l-.47.73c-.22-.16-.54-.4-.89-.64a3.41,3.41,0,0,1-1.4,1.72,2.44,2.44,0,0,0-.52-.64v.37c-1.7.14-3.46.26-4.7.35l-.06-.75c.56,0,1.27-.07,2-.11v-.64h-1.57v-.65h1.57v-.56h.93v.56h1.62v.65h-1.62V162l1.8-.13v.3a3,3,0,0,0,1.17-1.52l-.93-.58.44-.64.73.43a10.5,10.5,0,0,0,.14-1.06h-1.09V158h1.13c0-.39,0-.82,0-1.26h.86q0,.66,0,1.26Zm0,5.54a3.55,3.55,0,0,1,0,.41c-.17,1.56-.35,2.25-.66,2.55a1.2,1.2,0,0,1-.84.32c-.32,0-.92,0-1.55,0a2.18,2.18,0,0,0-.3-.91c.59,0,1.2.06,1.43.06a.59.59,0,0,0,.43-.1c.17-.14.29-.58.4-1.52h-2.87c-.5,1.32-1.64,2.13-4.47,2.52A3,3,0,0,0,235,166c2.36-.28,3.37-.81,3.85-1.69h-3.23v-.8h3.52c0-.2.08-.41.11-.63h1a5.89,5.89,0,0,1-.11.63Z"
          fill="#fff"
        />
        <path
          d="M223.71,178.87a12.86,12.86,0,0,0,.47-2.38l.55.18a14.92,14.92,0,0,1-.44,2.41Zm4.32-3.35c-.08,2.65-.15,3.61-.39,3.92a.69.69,0,0,1-.57.31,4,4,0,0,1-.87,0A2.12,2.12,0,0,0,226,179c.31,0,.58,0,.7,0s.21,0,.27-.12a3.56,3.56,0,0,0,.19-1.19l-.41.17a14.63,14.63,0,0,0-.54-1.44l.4-.14a10.21,10.21,0,0,1,.55,1.36c0-.41,0-1,.07-1.65h-2.92v-5.83H228V171h-1.26v.88h1.07v.42a7.87,7.87,0,0,0,2.85-2.72l.93.3a1.86,1.86,0,0,1-.22.35,9.25,9.25,0,0,0,2.57,1.76,4.62,4.62,0,0,0-.41.91,8.51,8.51,0,0,1-1-.62v.64h-3.25v-.53c-.28.21-.57.39-.86.57a4.54,4.54,0,0,0-.62-.62v.32h-1.07v.83h1.09v.79h-1.09v.87H228Zm-2.68,1.13c.08.64.13,1.45.14,2l-.5.06c0-.54,0-1.36-.1-2Zm-.26-5.63v.88h.84V171Zm.84,1.66h-.84v.83h.84Zm-.84,2.49h.84v-.87h-.84Zm.9,1.35c.15.56.31,1.3.38,1.77l-.46.1a17.9,17.9,0,0,0-.37-1.79Zm4.06.18a7.94,7.94,0,0,1-.24.87,5.49,5.49,0,0,1,1.14.88l-.53.67a5.54,5.54,0,0,0-.92-.79,4.94,4.94,0,0,1-1,1.48,6.84,6.84,0,0,0-.68-.58,4.89,4.89,0,0,0,1.39-2.7Zm-1.63-3h2.24v2.59h-2.24Zm.69,1.91h.82v-1.23h-.82Zm3.2-3.44a10.79,10.79,0,0,1-1.43-1.24,9.16,9.16,0,0,1-1.25,1.24Zm.48,4.49a6.21,6.21,0,0,1-.32,1.22,12.08,12.08,0,0,1,1.37,1.1l-.62.71a7,7,0,0,0-1.1-1,4.35,4.35,0,0,1-.81,1.13,6.72,6.72,0,0,0-.75-.58,4.79,4.79,0,0,0,1.32-2.74Zm-1.69-3h2.33v2.59H231.1Zm.7,1.91h.89v-1.23h-.89Z"
          fill="#fff"
        />
        <path
          d="M238.52,172.36h-3.73v-.81h1.89a11,11,0,0,0-.84-1.49l.7-.43a10.92,10.92,0,0,1,.86,1.41l-.71.51h1.83Zm-.29,6.81H236v.5h-.8V176h3Zm0-5.35h-3V173h3Zm-3,.64h3v.8h-3Zm.77,2.32v1.57h1.43v-1.57Zm4.32-3.59A5.26,5.26,0,0,1,239,174.5a4.14,4.14,0,0,0-.62-.7,4.28,4.28,0,0,0,1.18-1.15,7.78,7.78,0,0,0-.95-.7l.54-.65a6.63,6.63,0,0,1,.88.57,4.75,4.75,0,0,0,.4-1H239v-.81h1.81l.16,0,.61.2a8,8,0,0,1-1,2.52h2.28a9.74,9.74,0,0,1-1.13-2.82l.85-.15c.07.28.16.55.26.84l.73-.7.6.57a10.79,10.79,0,0,1-1,.83c.09.19.19.38.28.55a8.34,8.34,0,0,0,.86-.68l.59.57c-.34.25-.73.52-1.06.72a4.62,4.62,0,0,0,1.25,1.13,3.83,3.83,0,0,0-.7.75,4.8,4.8,0,0,1-1.28-1.3v.51h-2.75Zm3.35,4c-.25.52-.54,1.1-.8,1.54h1.92v.83H238.6v-.83h1.78a5.22,5.22,0,0,0-.58-1.51l.87-.23a5.07,5.07,0,0,1,.65,1.52l-.77.22h1.38a10.67,10.67,0,0,0,.73-1.79Zm-4.17-3h4.36v2.68h-4.36Zm.88,1.87h2.57V175h-2.57Z"
          fill="#fff"
        />
        <ellipse
          cx="163.08"
          cy="135.63"
          rx="81.81"
          ry="144.98"
          transform="translate(4.13 276.08) rotate(-81.21)"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.9"
        />
        <circle
          cx="35.83"
          cy="88.14"
          r="20"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="1.11"
        />
        <path
          d="M25.08,88.48c-.36.1-.71.22-1.08.33v3.56H22.91V89.14c-.54.16-1,.32-1.5.44l-.29-1.13c.5-.11,1.11-.27,1.79-.46V86.08h-.67a9.09,9.09,0,0,1-.37,1.28,8.7,8.7,0,0,0-.84-.55,12.5,12.5,0,0,0,.57-3.22l1,.17c0,.4-.09.79-.15,1.19h.47V83H24V85h.83v1.13H24v1.61l.92-.26Zm5.38-3.91s0,.4,0,.54c-.19,4.46-.37,6.09-.75,6.59a1.1,1.1,0,0,1-.78.52,5.72,5.72,0,0,1-1.13,0,3.32,3.32,0,0,0-.33-1.16,8.11,8.11,0,0,0,.89,0,.42.42,0,0,0,.37-.19c.25-.32.43-1.68.59-5.27h-.05c-.49,2.52-1.52,5.12-3.13,6.45a4.44,4.44,0,0,0-1-.69c1.6-1.15,2.6-3.42,3.1-5.76h-.47a10.11,10.11,0,0,1-2.29,4.38,4.2,4.2,0,0,0-.91-.67,7.76,7.76,0,0,0,2.21-3.71h-.51a7.45,7.45,0,0,1-1,1.79,6.79,6.79,0,0,0-.9-.67A8.28,8.28,0,0,0,26,83l1.08.2c-.1.46-.23.91-.37,1.35Z"
          fill="#002a5e"
        />
        <path
          d="M34.62,90.36c-1.1.39-2.3.77-3.26,1.08l-.27-1.21,1.22-.33V87.55h-1V86.46h1V84.7h-1.1V83.59h3.3V84.7H33.44v1.76h.9v1.09h-.9v2l1-.31Zm5.94.61v1.08H34.13V91h2.76V90H34.82V89h2.07v-.86h-2V83.44h5.22v4.65h-2V89h2.12V90H38.1v1ZM36,85.29h1v-.84H36Zm0,1.79h1v-.86H36Zm3-2.63H38v.84h1Zm0,1.77H38v.86h1Z"
          fill="#002a5e"
        />
        <path
          d="M50.34,90.53H46.52v.72c0,.53-.12.79-.53,1a5.72,5.72,0,0,1-1.71.15,3.85,3.85,0,0,0-.36-1c.47,0,1.07,0,1.22,0s.21,0,.21-.15v-.68H41.3v-.94h4.05V89.3a7.29,7.29,0,0,0,1.1-.58H43.24v-.83h4.35l.25-.06.67.61a14,14,0,0,1-1.91,1.15h3.74Zm-7.73-1.88H41.48V86.6h.75l-.18-3a13.91,13.91,0,0,0,2-.56l.49.69a9.69,9.69,0,0,1-1.37.38l0,.29h1.27v.74H43.25l0,.34H44.5V86a6.64,6.64,0,0,0,.7-.38l-.56-.33.55-.47.63.34a3.81,3.81,0,0,0,.39-.42L47,85a4.22,4.22,0,0,1-.53.6,4.83,4.83,0,0,1,.61.46l-.6.52a6.66,6.66,0,0,0-.58-.49,7.34,7.34,0,0,1-.81.52h3.24l0-.35H47.18v-.74h1.25l0-.34H47.23v-.74H48.5l0-.33H47.18v-.75h2.5c0,1.05-.13,2.32-.21,3.25h.74v2.05H49V87.46H42.61Zm.72-2.05H45a6.38,6.38,0,0,0-.45-.46v.11H43.31Zm3.1-1.84a5.22,5.22,0,0,0-.52-.42,5.08,5.08,0,0,1-.81.5,4.76,4.76,0,0,0-.62-.53,4.55,4.55,0,0,0,.76-.39c-.19-.11-.36-.21-.54-.3l.53-.46.61.32a3.33,3.33,0,0,0,.36-.4l.78.23a3.66,3.66,0,0,1-.5.55c.2.14.38.28.52.4Z"
          fill="#002a5e"
        />
        <circle
          cx="207.93"
          cy="218.65"
          r="20"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="1.11"
        />
        <path
          d="M207.44,214.65v1.17h-9v-1.17h3.86v-2h-2.71v-1.15h2.71v-1.76h-2.07a8.24,8.24,0,0,1-.93,1.45,9.46,9.46,0,0,0-1.07-.71A8.62,8.62,0,0,0,200,206.9l1.23.27c-.14.48-.3,1-.47,1.41h1.54v-1.8h1.24v1.8h3.37v1.15h-3.37v1.76h3v1.15h-3v2Z"
          fill="#002a5e"
        />
        <path
          d="M212.18,212.22c-.36.1-.71.22-1.08.33v3.56H210v-3.23c-.54.16-1,.32-1.5.44l-.29-1.13c.5-.11,1.11-.27,1.79-.46v-1.91h-.67a9.09,9.09,0,0,1-.37,1.28,8.7,8.7,0,0,0-.84-.55,12.42,12.42,0,0,0,.57-3.21l1,.17c0,.4-.09.78-.15,1.19H210v-1.92h1.09v1.92h.83v1.12h-.83v1.61l.92-.26Zm5.37-3.9s0,.39,0,.53c-.19,4.46-.37,6.09-.75,6.59a1.1,1.1,0,0,1-.78.52,5.72,5.72,0,0,1-1.13,0,3.32,3.32,0,0,0-.33-1.16,8.11,8.11,0,0,0,.89,0,.42.42,0,0,0,.37-.19c.25-.32.43-1.68.59-5.26h0c-.49,2.51-1.52,5.11-3.12,6.44a4.44,4.44,0,0,0-1-.69c1.6-1.15,2.59-3.42,3.09-5.75h-.47a10.12,10.12,0,0,1-2.28,4.37,4.2,4.2,0,0,0-.91-.67,7.77,7.77,0,0,0,2.21-3.7h-.51a7.57,7.57,0,0,1-1,1.78,6.79,6.79,0,0,0-.9-.67,8.24,8.24,0,0,0,1.64-3.76l1.07.2c-.1.46-.23.91-.36,1.35Z"
          fill="#002a5e"
        />
        <path
          d="M199.61,220.54a4.66,4.66,0,0,0,2.95.85,3.85,3.85,0,0,0-.66,1,4.78,4.78,0,0,1-2.62-1c-.38.44-1.2.83-2.88,1a3.37,3.37,0,0,0-.4-.65h-2.65v-.91h3v.61c1.91-.19,2.15-.65,2.15-1v-.14h-.59a4,4,0,0,1-.7.85,5.67,5.67,0,0,0-.88-.6,4.08,4.08,0,0,0,1.17-1.78l1,.23c-.05.15-.11.3-.17.44h2.83l.18,0,.81.25a9,9,0,0,1-.92,1.57l-.92-.33a5.91,5.91,0,0,0,.35-.58h-1.11v.3Zm.27,5.88c.92.34,1.9.74,2.49,1l-1.07.64a18.76,18.76,0,0,0-2.59-1.18l.6-.49H196.5l.87.4a13,13,0,0,1-3.12,1.3,8,8,0,0,0-.88-.87,11.4,11.4,0,0,0,2.73-.83h-1.44v-4.06h6.68v4.06Zm-3.74-6.3h-2.56v-.86h2.56Zm-.33,3.38h4.32v-.43h-4.32Zm0,1.1h4.32v-.43h-4.32Zm0,1.12h4.32v-.45h-4.32Z"
          fill="#002a5e"
        />
        <path
          d="M207,221.35h-3.67v-.93h1.59a8.92,8.92,0,0,0-.59-1.26l.85-.43a10.36,10.36,0,0,1,.67,1.33l-.61.36H207Zm-3.26,1.35v-.9h2.9v.9Zm0,.44h2.92v.9h-2.92Zm2.92,4.41h-2V228h-.91v-3.45h2.91Zm-2-2.08v1.12h1.06v-1.12Zm7-6.19c-.09,3.28-.08,7.38.29,7.5a5.34,5.34,0,0,0,.13-1.27,5.5,5.5,0,0,0,.62,1c-.15,1.46-.66,1.77-1.28,1.51-1-.4-1-3.86-1-7.57h-1.2V223h.95v1.11h-.95v3.94h-1.11v-3.94H207V223h1.12v-2.63h-1v-1.13Z"
          fill="#002a5e"
        />
        <path
          d="M222.43,226.27h-3.81V227c0,.53-.12.79-.53.95a5.72,5.72,0,0,1-1.71.15,3.85,3.85,0,0,0-.36-1c.47,0,1.07,0,1.22,0s.21,0,.21-.15v-.68h-4v-.94h4V225a7.29,7.29,0,0,0,1.1-.58h-3.21v-.83h4.34l.25-.06.67.61a14,14,0,0,1-1.91,1.15h3.74Zm-7.72-1.88h-1.13v-2h.75l-.18-3a13.91,13.91,0,0,0,2-.56l.49.69a9.69,9.69,0,0,1-1.37.38l0,.29h1.27v.74h-1.23l0,.33h1.23v.51a6.64,6.64,0,0,0,.7-.38l-.56-.33.55-.47.63.34a3.08,3.08,0,0,0,.39-.42l.78.24a4.87,4.87,0,0,1-.53.59,4.05,4.05,0,0,1,.6.46l-.6.52a5.21,5.21,0,0,0-.57-.49,7.34,7.34,0,0,1-.81.52h3.23l0-.35h-1.18v-.74h1.25l0-.33h-1.22v-.74h1.27l0-.33h-1.34v-.75h2.5c-.05,1.05-.13,2.32-.21,3.24h.74v2h-1.18V223.2h-6.41Zm.72-2h1.62a6.38,6.38,0,0,0-.45-.46V222h-1.19Zm3.09-1.84c-.13-.12-.31-.26-.51-.42a4,4,0,0,1-.81.5,5.66,5.66,0,0,0-.62-.52,4.55,4.55,0,0,0,.76-.39l-.54-.3.53-.46.61.32a3.33,3.33,0,0,0,.36-.4l.77.23a4.2,4.2,0,0,1-.49.55c.19.14.38.28.52.4Z"
          fill="#002a5e"
        />
        <circle
          cx="43.31"
          cy="163.81"
          r="20"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="1.11"
        />
        <path
          d="M35,157.32H33.92v-2.25H37.7v-.4H34.37v-.78h7.84v.78H38.86v.4h3.86v2.25H41.57l-.21.71a15.75,15.75,0,0,0-2.31-.55l.23-.67a13.48,13.48,0,0,1,2.25.47v-1.43H41l.36.51a10,10,0,0,1-1.78.48l-.37-.56a11.89,11.89,0,0,0,1.37-.43H38.86v2.08H37.7v-2.08H35.79a10.89,10.89,0,0,1,1.56.41l-.32.64a12.5,12.5,0,0,0-1.72-.49l.28-.56h-.54v1.37c.61-.07,1.45-.19,2.31-.32l0,.68-2.12.48Zm6.38,4.51c.51,0,.6-.16.67-1a3.08,3.08,0,0,0,1,.37c-.14,1.2-.47,1.51-1.56,1.51h-2.1c-1.36,0-1.75-.27-1.75-1.31v0H36v.43H34.84v-3.63h6.93v3.2h-3v0c0,.36.12.42.72.42ZM36,159v.45h1.66V159Zm1.66,1.6v-.45H36v.45Zm3-1.6H38.78v.45h1.81Zm0,1.6v-.45H38.78v.45Z"
          fill="#002a5e"
        />
        <path
          d="M47,161.65c0,.51-.08.81-.38,1a2.61,2.61,0,0,1-1.36.21,4.12,4.12,0,0,0-.29-1.05c.37,0,.74,0,.87,0s.17,0,.17-.19V159.3l-.61.63-.28-.68a11.8,11.8,0,0,1-.7,3.67,3.36,3.36,0,0,0-.8-.67,14.38,14.38,0,0,0,.58-4.8v-3.57H47Zm-1.83-4.2c0,.43,0,.91,0,1.43.25-.17.55-.4.87-.64v-1l-.38.26a5.12,5.12,0,0,0-.46-.69Zm0-2.5v1.25l.31-.2c.17.19.37.41.53.62V155Zm3.23,2.51a11,11,0,0,0-1.24-2,16.16,16.16,0,0,0,1.08-1.87l1,.24c-.3.55-.67,1.13-1,1.62a12.84,12.84,0,0,1,1.09,1.64Zm2.27-.18c-.12.19-.25.38-.38.56h2.48v5h-1.1v-.35H48.48v.39H47.42v-5h1.64a7.51,7.51,0,0,0,.31-.79l.7.13a10.55,10.55,0,0,0-1.16-1.76A15.36,15.36,0,0,0,50,153.65l1,.25c-.3.51-.65,1-1,1.51a10,10,0,0,1,1.08,1.53l-.71.3Zm1,1.52h-1l.79.42a6.57,6.57,0,0,1-.63.81,10.74,10.74,0,0,1,.81.71l-.67.66a6.76,6.76,0,0,0-.8-.73,7.24,7.24,0,0,1-1.07.77,3.37,3.37,0,0,0-.58-.62v.7h3.14Zm-3.14,0v2a6.9,6.9,0,0,0,1-.67c-.29-.21-.56-.41-.83-.58l.62-.57c.27.16.56.35.83.53a3.86,3.86,0,0,0,.54-.7ZM52,157.31a9.77,9.77,0,0,0-1.33-1.89,15.5,15.5,0,0,0,1.09-1.76l1,.25c-.3.5-.65,1-1,1.49A11.24,11.24,0,0,1,53,156.88Z"
          fill="#002a5e"
        />
        <path
          d="M36.93,171.91a10.83,10.83,0,0,0-.6-1.1v4.05H35.18v-3.59a9.31,9.31,0,0,1-1.09,2,6.56,6.56,0,0,0-.55-1.13A9.38,9.38,0,0,0,35,169.44H33.67v-1.11h1.51V167.2c-.39.06-.76.12-1.13.16a3.6,3.6,0,0,0-.32-.92,15.88,15.88,0,0,0,3.22-.82l.67,1a8.92,8.92,0,0,1-1.29.37v1.37h1.32v1.11H36.33v.08c.29.28,1.11,1.22,1.3,1.45Zm4.93.43v2.51H40.69v-2.3l-3,.52-.19-1.13,3.21-.55v-5.86h1.17v5.66l1-.17.19,1.11ZM39.35,171a9,9,0,0,0-1.59-1.53l.76-.68a8.81,8.81,0,0,1,1.64,1.45Zm.26-2.64a7.5,7.5,0,0,0-1.49-1.5l.78-.68a8,8,0,0,1,1.54,1.44Z"
          fill="#002a5e"
        />
        <path
          d="M52.82,173H49v.71c0,.54-.12.79-.53,1a6.14,6.14,0,0,1-1.71.14,3.78,3.78,0,0,0-.36-1c.47,0,1.07,0,1.22,0s.21,0,.21-.16V173h-4v-.94h4v-.29a7.29,7.29,0,0,0,1.1-.58H45.72v-.83h4.35l.25-.06L51,171a14,14,0,0,1-1.91,1.15h3.74Zm-7.73-1.88H44v-2h.75l-.18-3a13.91,13.91,0,0,0,2-.56l.49.69a9.69,9.69,0,0,1-1.37.38l0,.29H47v.74H45.73l0,.34H47v.51a6.64,6.64,0,0,0,.7-.38l-.56-.33.55-.47.63.34a3.81,3.81,0,0,0,.39-.42l.78.23a4.22,4.22,0,0,1-.53.6,4.83,4.83,0,0,1,.61.46l-.6.52a6.66,6.66,0,0,0-.58-.49,7.34,7.34,0,0,1-.81.52H50.8l0-.35H49.66V168h1.25l0-.34H49.71v-.74H51l0-.33H49.66v-.75h2.5c0,1-.13,2.32-.21,3.25h.74v2H51.51V170H45.09Zm.72-2h1.62a6.38,6.38,0,0,0-.45-.46v.11H45.79Zm3.1-1.84a5.22,5.22,0,0,0-.52-.42,5.08,5.08,0,0,1-.81.5,4.76,4.76,0,0,0-.62-.53,4.55,4.55,0,0,0,.76-.39l-.54-.3.53-.46.61.32a3.33,3.33,0,0,0,.36-.4l.78.23a3.66,3.66,0,0,1-.5.55c.2.14.38.28.52.4Z"
          fill="#002a5e"
        />
        <circle
          cx="286.94"
          cy="181.32"
          r="20"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="1.11"
        />
        <path
          d="M280.62,170.91a15.58,15.58,0,0,1-1,2.08v6.84h-1.2v-5c-.21.25-.42.5-.62.72a8.13,8.13,0,0,0-.66-1.27,10.4,10.4,0,0,0,2.28-3.74Zm3.17,2.36v1h2.59v1.08h-2.59v1.13h2.72v1.1h-2.72v2.21h-1.22v-6.57h-.38a11.1,11.1,0,0,1-1.31,1.94,8.09,8.09,0,0,0-.91-.83,9.93,9.93,0,0,0,2.12-3.77l1.15.29a11.82,11.82,0,0,1-.49,1.24h3.88v1.13Z"
          fill="#002a5e"
        />
        <path
          d="M291.1,178.29a7.73,7.73,0,0,1-2.91,1.33,4.63,4.63,0,0,0-.7-.86,8.38,8.38,0,0,0,2.77-.85Zm5.3-1.69v1h-3.88v2.28h-1.18v-2.28h-3.81v-1h3.81v-.44h-2.88v-.86h2.88v-.45H288V174h1.84a5.56,5.56,0,0,0-.37-.73l.67-.15h-2.55v-.93h1.36a9.14,9.14,0,0,0-.64-1.24l1-.34a6.5,6.5,0,0,1,.76,1.4l-.48.18h.92v-1.63h1.1v1.63h.75v-1.63h1.12v1.63h.89L294,172a8.78,8.78,0,0,0,.64-1.44l1.25.29c-.28.46-.55.91-.8,1.26h1.29v.93h-2.64l.94.24-.61.64H296v.9h-3.47v.45h3v.86h-3v.44Zm-5.77-3.53a4.64,4.64,0,0,1,.4.75l-.54.13h2.73l-.36-.11c.16-.23.37-.53.51-.77Zm3.12,4.78c.81.3,2,.8,2.63,1.12l-.84.65c-.58-.33-1.75-.86-2.59-1.21Z"
          fill="#002a5e"
        />
        <path
          d="M279.57,184.06a16.68,16.68,0,0,1-.54,2h1.87v4.6H279v.73h-1v-3.1c-.13.19-.26.37-.4.54a6.65,6.65,0,0,0-.41-1.42,8.39,8.39,0,0,0,1.27-3.34H277.4V183H281v1.08Zm.32,3H279v2.5h.9Zm6.72.6h-1.05v4.16h-1.13V187.7h-1.14a6.49,6.49,0,0,1-1.38,4.22,4.72,4.72,0,0,0-.9-.68,5.61,5.61,0,0,0,1.17-3.54h-.93v-1.12h.95v-2.47h-.69V183h4.9v1.1h-.85v2.47h1.05Zm-2.18-1.12v-2.47h-1.12v2.47Z"
          fill="#002a5e"
        />
        <path
          d="M295.27,190.51c.23,0,.27-.18.31-1.42a3.7,3.7,0,0,0,1.12.51c-.12,1.64-.43,2.07-1.29,2.07h-.94c-1,0-1.29-.34-1.29-1.51v-1.29h-1.31c-.25,1.17-1.12,2.38-3.71,3a5.77,5.77,0,0,0-.84-1c2.07-.52,2.91-1.27,3.21-2h-2.45v-1.1h2.62v-1.1H292v1.1h2.47v2.38c0,.32,0,.36.27.36Zm-6.4-5.08h-1.2v-2h3.65c-.07-.25-.14-.5-.21-.71l1.26-.2c.1.28.21.61.3.91h3.64v2h-1.25v-.88h-1.41v1.28c0,.26,0,.37.3.37h1.21A3.79,3.79,0,0,0,296,186c0,.32.06.72.1,1a3.87,3.87,0,0,1-.92.08h-1.35c-1.13,0-1.4-.4-1.4-1.38v-1.27h-1c-.19,1.42-.76,2.27-3.05,2.75a2.87,2.87,0,0,0-.62-1c1.89-.31,2.28-.84,2.43-1.78h-1.32Z"
          fill="#002a5e"
        />
        <circle
          cx="291.6"
          cy="110.82"
          r="20"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="1.11"
        />
        <path
          d="M290.6,102.72a12.06,12.06,0,0,1-.93,3.69,4.33,4.33,0,0,0,1.72,1.82,3.73,3.73,0,0,0-.75.92,5,5,0,0,1-1.58-1.73,5.79,5.79,0,0,1-2.16,1.78,4.88,4.88,0,0,0-.33-.58l-.3.27a9,9,0,0,0-1.2-.58,8.39,8.39,0,0,1-2.76.86,3,3,0,0,0-.46-.77,9.57,9.57,0,0,0,2.09-.52c-.46-.16-.94-.3-1.38-.41a8.33,8.33,0,0,0,.55-.76h-1.06v-.8h1.57a4.92,4.92,0,0,0,.24-.45h-1.39v-1.77h1.77v-.29h-1.6v-1.1H282v-.84h.69v-1h1.6v-.61h.9v.61h1.72v1h.52v.84h-.52v1.1h-1.72v.29H287v.4a16,16,0,0,0,1.21-4.26l1,.19c-.09.55-.21,1.11-.35,1.66h2.43v1Zm-7.15,2.08h.79v-.44h-.79Zm.07-3.63v.35h.72v-.35Zm.72,1.48v-.41h-.72v.41Zm-.05,4.06c-.07.12-.15.23-.22.34.32.09.64.18.95.29a1.73,1.73,0,0,0,.52-.63Zm2.24,0a2.17,2.17,0,0,1-.54,1l.91.4a4.54,4.54,0,0,0,1.78-1.72,13.58,13.58,0,0,1-.59-2.06,8.46,8.46,0,0,1-.41.85,6.24,6.24,0,0,0-.58-.59v.88h-2.1c-.07.15-.15.3-.23.45h.89v-.29h.93v.29h.83v.8Zm-1.29-5.54v.35h.78v-.35Zm.78,1.48v-.41h-.78v.41Zm-.78,1.71v.44H286v-.44Zm3.42-1.64a14,14,0,0,0,.57,2.41,12,12,0,0,0,.45-2.41Z"
          fill="#002a5e"
        />
        <path
          d="M301.11,107.34h-3.82v.72c0,.53-.12.79-.53,1a5.72,5.72,0,0,1-1.71.15,3.85,3.85,0,0,0-.36-1c.47,0,1.07,0,1.22,0s.21,0,.21-.15v-.68h-4.05v-.94h4.05v-.29a7.29,7.29,0,0,0,1.1-.58H294v-.83h4.35l.25-.06.67.61a14,14,0,0,1-1.91,1.15h3.74Zm-7.73-1.88h-1.13v-2H293l-.18-3a15.3,15.3,0,0,0,2-.56l.49.69A13.39,13.39,0,0,1,294,101l0,.29h1.27V102H294l0,.34h1.23v.51a6.64,6.64,0,0,0,.7-.38l-.56-.33.55-.47.63.34a3.81,3.81,0,0,0,.39-.42l.78.23a4.22,4.22,0,0,1-.53.6,4.83,4.83,0,0,1,.61.46l-.6.52a6.66,6.66,0,0,0-.58-.49,7.34,7.34,0,0,1-.81.52h3.24l0-.35H298v-.74h1.25l0-.34H298v-.73h1.27l0-.33H298v-.75h2.5c-.05,1-.13,2.31-.21,3.24H301v2H299.8v-1.19h-6.42Zm.72-2h1.62a6.38,6.38,0,0,0-.45-.46v.11h-1.19Zm3.1-1.84a5.22,5.22,0,0,0-.52-.42,5.08,5.08,0,0,1-.81.5,4.76,4.76,0,0,0-.62-.53,5.39,5.39,0,0,0,.76-.38,6,6,0,0,0-.54-.3L296,100c.19.09.4.19.61.31a2.64,2.64,0,0,0,.36-.39l.78.22a3.23,3.23,0,0,1-.5.55c.2.14.38.29.52.41Z"
          fill="#002a5e"
        />
        <path
          d="M278.14,118.55a11.38,11.38,0,0,1-.44,2.44,4,4,0,0,0-.88-.32,8.62,8.62,0,0,0,.4-2.27Zm1.78-.59c0-.14-.05-.28-.09-.45a11.34,11.34,0,0,0-2.56.57,7.14,7.14,0,0,0-.37-1.06c.27-.07.48-.28.78-.66a7.28,7.28,0,0,0,.61-.79,3.72,3.72,0,0,0-1.1.21c-.06-.23-.24-.75-.36-1,.19,0,.36-.24.56-.54a11.84,11.84,0,0,0,1.17-2.44l1.06.46a16.63,16.63,0,0,1-1.39,2.48l.66,0a16.3,16.3,0,0,0,.77-1.5l1,.61a16.68,16.68,0,0,1-2.07,3.07l1-.16a6.33,6.33,0,0,0-.3-.74l.8-.31a8.57,8.57,0,0,1,.7,2Zm-.74.35a18.55,18.55,0,0,1,.27,2.06l-.89.22a20.27,20.27,0,0,0-.21-2.06Zm1.06-.29a16.09,16.09,0,0,1,.5,1.62l-.84.31a12.73,12.73,0,0,0-.46-1.66Zm4.82,1.92c.18,0,.21-.19.24-1.36a3.11,3.11,0,0,0,1,.44c-.1,1.54-.37,2-1.12,2h-.72c-.91,0-1.11-.35-1.11-1.4v-3l-.57.07c-.08,2.14-.35,3.64-2,4.53a3.1,3.1,0,0,0-.72-.93c1.32-.68,1.52-1.78,1.58-3.45a2.37,2.37,0,0,0-.68.16,10.61,10.61,0,0,0-.4-1.14c.24-.07.44-.31.69-.72a8,8,0,0,0,.6-1h-1.19V113h1.7c.16-.39.32-.78.45-1.19l1.29.33c-.12.3-.24.58-.37.86h2.35v1.11h-2.91a17.84,17.84,0,0,1-.95,1.58l2.11-.2c-.16-.27-.32-.53-.46-.78l1-.43a13.36,13.36,0,0,1,1.35,2.48l-1,.48a7,7,0,0,0-.31-.78l-.39,0v3.1c0,.31,0,.35.19.35Z"
          fill="#002a5e"
        />
        <path
          d="M291.4,114.42h-4.21v-.94h1.75a12.44,12.44,0,0,0-.65-1.25l.93-.43a9.86,9.86,0,0,1,.73,1.32l-.65.36h2.1Zm-3.77,1.35v-.9H291v.9Zm3.34,1.34h-3.34v-.9H291Zm0,3.51h-2.33V121h-1v-3.45H291Zm-2.33-2.08v1.12H290v-1.12Zm7.53-2.22h-1.85v4.85h-1.24v-4.85h-1.75v-1.17h1.75V112h1.24v3.13h1.85Z"
          fill="#002a5e"
        />
        <path
          d="M306.11,119.34h-3.82v.72c0,.53-.12.79-.53,1a5.72,5.72,0,0,1-1.71.15,3.85,3.85,0,0,0-.36-1c.47,0,1.07,0,1.22,0s.21,0,.21-.15v-.68h-4.05v-.94h4.05v-.29a7.29,7.29,0,0,0,1.1-.58H299v-.83h4.35l.25-.06.67.61a14,14,0,0,1-1.91,1.15h3.74Zm-7.73-1.88h-1.13v-2H298l-.18-3a15.3,15.3,0,0,0,2-.56l.49.69A13.39,13.39,0,0,1,299,113l0,.29h1.27V114H299l0,.34h1.23v.51a6.64,6.64,0,0,0,.7-.38l-.56-.33.55-.47.63.34a3.81,3.81,0,0,0,.39-.42l.78.23a4.22,4.22,0,0,1-.53.6,4.83,4.83,0,0,1,.61.46l-.6.52a6.66,6.66,0,0,0-.58-.49,7.34,7.34,0,0,1-.81.52h3.24l0-.35H303v-.74h1.25l0-.34H303v-.73h1.27l0-.33H303v-.75h2.5c-.05,1-.13,2.31-.21,3.24H306v2H304.8v-1.19h-6.42Zm.72-2h1.62a6.38,6.38,0,0,0-.45-.46v.11h-1.19Zm3.1-1.84a5.22,5.22,0,0,0-.52-.42,5.08,5.08,0,0,1-.81.5,4.76,4.76,0,0,0-.62-.53,5.39,5.39,0,0,0,.76-.38,6,6,0,0,0-.54-.3L301,112c.19.09.4.19.61.31a2.64,2.64,0,0,0,.36-.39l.78.22a3.23,3.23,0,0,1-.5.55c.2.14.38.29.52.41Z"
          fill="#002a5e"
        />
        <circle
          cx="204.93"
          cy="57.84"
          r="20"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="1.11"
        />
        <path
          d="M197.69,52.71a9.15,9.15,0,0,0-.41-1v4.64h-1V52.54a9.39,9.39,0,0,1-.68,1.77,7,7,0,0,0-.47-1.17A9.81,9.81,0,0,0,196.2,50h-.82v-1.1h.88V47.05h1v1.86H198V50h-.68v.44c.22.3.79,1.23.93,1.47Zm6.31.69a5.62,5.62,0,0,1-1.09,1.47,1,1,0,0,0,.62.36c.19,0,.31-.34.38-1a3.07,3.07,0,0,0,.75.63c-.26,1.24-.66,1.59-1.09,1.56a2.05,2.05,0,0,1-1.47-.85,7.1,7.1,0,0,1-1.57.83,4.73,4.73,0,0,0-.61-.76,6.38,6.38,0,0,0,1.72-.9,8.37,8.37,0,0,1-.51-1.6h-1.36c0,.07,0,.15,0,.22.41.33,1,.79,1.27,1.07l-.72.71c-.17-.2-.46-.46-.75-.73a4,4,0,0,1-1.36,2,3.69,3.69,0,0,0-.79-.68,3.25,3.25,0,0,0,1.36-2.6h-.59v-.91H201a32.47,32.47,0,0,1-.34-3.5,13.57,13.57,0,0,1-1.36,2.08l.56-.06c0-.16-.09-.32-.13-.45l.69-.18a9,9,0,0,1,.39,1.44l-.73.22a2.73,2.73,0,0,0-.06-.34,6,6,0,0,0-1.57.31,8.51,8.51,0,0,0-.27-.81,1,1,0,0,0,.49-.45,7.11,7.11,0,0,0,.48-.72,2.73,2.73,0,0,0-.8.17,7,7,0,0,0-.28-.8.72.72,0,0,0,.36-.36,7.14,7.14,0,0,0,.66-1.6l.86.23a10.38,10.38,0,0,1-.86,1.66l.45,0c.1-.21.2-.42.28-.62l.83.27c0-.48,0-1-.06-1.52h1a33,33,0,0,0,.42,5.06h.91l-.51-.43.44-.37a2.27,2.27,0,0,0-.7.18,6.15,6.15,0,0,0-.28-.82,1.15,1.15,0,0,0,.5-.46,6.27,6.27,0,0,0,.45-.71,2.81,2.81,0,0,0-.78.17c0-.18-.18-.57-.27-.8.12,0,.22-.16.34-.34a8,8,0,0,0,.59-1.51l.85.23a8.86,8.86,0,0,1-.77,1.54l.44,0c.09-.2.18-.42.26-.62l.86.28a12.14,12.14,0,0,1-1.34,2.12l.56-.07c-.05-.14-.09-.29-.14-.41L204,50a11.82,11.82,0,0,1,.46,1.45l-.71.24c0-.11,0-.25-.09-.4l-.63.1a8,8,0,0,1,.76.61l-.26.24h.8v.91h-1.16Zm-1.86-.3c.08.33.17.61.26.87a4.17,4.17,0,0,0,.59-.87Z"
          fill="#002a5e"
        />
        <path
          d="M212.72,52.12a10.89,10.89,0,0,1,1.92,1.36l-.8.69-.27-.26v2.41h-1.09v-.24h-1.17v.3h-1V53.17h2.33a12.1,12.1,0,0,0-1.81-1.05h-1.89a7.14,7.14,0,0,1-1.23,1.05h1.92v3.15h-1.08v-.24h-1.15v.3h-1V54l-.54.24a3.52,3.52,0,0,0-.62-.9,8.28,8.28,0,0,0,2.18-1.17h-2v-1h3a5,5,0,0,0,.51-.76H206.1V47.46h3.38v2.73l.67.13a6.41,6.41,0,0,1-.45.81h2.22a6.41,6.41,0,0,0-.91-.45l.32-.31h-.93V47.46h3.4v2.91h-1.65a5.79,5.79,0,0,1,.85.5l-.26.26h1.71v1Zm-4.34-3.73h-1.24v1h1.24Zm.14,5.71h-1.15v1h1.15Zm4,1v-1h-1.17v1Zm-1-5.7h1.29v-1h-1.29Z"
          fill="#002a5e"
        />
        <path
          d="M204.43,66.56h-3.81v.72c0,.53-.12.79-.53,1a5.72,5.72,0,0,1-1.71.15,3.85,3.85,0,0,0-.36-1c.47,0,1.07,0,1.22,0s.21,0,.21-.15v-.68h-4v-.94h4v-.29a7.29,7.29,0,0,0,1.1-.58h-3.21v-.83h4.34l.25-.06.67.61a13.29,13.29,0,0,1-1.9,1.15h3.73Zm-7.72-1.88h-1.13V62.63h.75l-.18-3a13.91,13.91,0,0,0,2-.56l.49.69a9.69,9.69,0,0,1-1.37.38l0,.29h1.27v.74h-1.23l0,.34h1.23v.51a6.64,6.64,0,0,0,.7-.38l-.56-.33.55-.47.63.34a3.81,3.81,0,0,0,.39-.42l.78.23a4.22,4.22,0,0,1-.53.6,4.83,4.83,0,0,1,.61.46l-.6.52a6.66,6.66,0,0,0-.58-.49,7.34,7.34,0,0,1-.81.52h3.24l0-.35h-1.17v-.74h1.24l0-.34h-1.22v-.74h1.27v-.33h-1.33v-.75h2.49c-.05,1-.13,2.32-.21,3.25h.74v2.05h-1.18V63.49h-6.41Zm.72-2.05h1.62a6.38,6.38,0,0,0-.45-.46v.11h-1.19Zm3.1-1.84a5.22,5.22,0,0,0-.52-.42,5.08,5.08,0,0,1-.81.5,4.76,4.76,0,0,0-.62-.53,4.55,4.55,0,0,0,.76-.39l-.54-.3.53-.46.61.32a3.33,3.33,0,0,0,.36-.4l.78.23a3.66,3.66,0,0,1-.5.55c.2.14.38.28.52.4Z"
          fill="#002a5e"
        />
        <path
          d="M208.08,62.58c-.8.35-1.65.7-2.36,1l-.41-1c.65-.2,1.63-.54,2.59-.87Zm2.49.89a8.25,8.25,0,0,1-.39.73h3.16v4.21H212.1v-.36h-4.27v.36h-1.18V64.2H209c.08-.19.15-.4.21-.58h0a3.87,3.87,0,0,1-1.36.13,3.12,3.12,0,0,0-.3-.84h.84c.11,0,.15,0,.15-.14V60.41h-1.93a6.1,6.1,0,0,1,1.11.75l-.6.76a5.39,5.39,0,0,0-1.26-.91l.5-.6h-.76v-.92h4V62.8a1.26,1.26,0,0,1-.08.52Zm1.53,1.63h-4.27v.58h4.27Zm0,2.05v-.58h-4.27v.58Zm2-4.38c0,.48-.1.72-.44.87a3.67,3.67,0,0,1-1.46.15,3.55,3.55,0,0,0-.31-.9c.38,0,.81,0,.93,0s.17,0,.17-.15V60.41h-1.9a5.83,5.83,0,0,1,1.1.71l-.53.72.91-.32.18.85c-.85.33-1.77.67-2.51.94l-.4-.89c.46-.14,1.07-.33,1.72-.55a6.19,6.19,0,0,0-1.26-.87l.48-.59h-.65v-.92h4Z"
          fill="#002a5e"
        />
        <circle
          cx="114.82"
          cy="50.34"
          r="20"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="1.11"
        />
        <path
          d="M102.14,49.56a26.37,26.37,0,0,1-.73,4.15l-1.2-.48a19.56,19.56,0,0,0,.77-3.89Zm1.88-.81v4.64c0,.47.08.55.56.55H106c.5,0,.59-.32.66-1.88a3.63,3.63,0,0,0,1.12.5c-.14,1.9-.46,2.49-1.68,2.49h-1.67c-1.3,0-1.66-.4-1.66-1.66V48.75Zm-.09-2.68a18.13,18.13,0,0,1,2.77,2.06l-.88.93a16.89,16.89,0,0,0-2.7-2.18Zm4.36,3a18.58,18.58,0,0,1,1.29,3.72l-1.21.5a17,17,0,0,0-1.19-3.77Z"
          fill="#002a5e"
        />
        <path
          d="M113.61,53.24c-1.1.39-2.3.77-3.26,1.08l-.27-1.21,1.22-.33V50.43h-1V49.34h1V47.58h-1.1v-1.1h3.3v1.1h-1.07v1.76h.9v1.09h-.9v2l1-.31Zm5.94.61v1.08h-6.43V53.85h2.76v-1h-2.07V51.83h2.07V51h-2V46.33h5.22V51h-2v.86h2.12V52.9h-2.12v1ZM115,48.17h1v-.84h-1ZM115,50h1V49.1h-1Zm3-2.63h-1v.84h1Zm0,1.77h-1V50h1Z"
          fill="#002a5e"
        />
        <path
          d="M129.33,53.41h-3.82v.72c0,.53-.12.79-.53.95a5.72,5.72,0,0,1-1.71.15,3.85,3.85,0,0,0-.36-1c.47,0,1.07,0,1.22,0s.21,0,.21-.15v-.68h-4v-.94h4v-.29a7.29,7.29,0,0,0,1.1-.58h-3.21v-.83h4.35l.25-.06.67.61a14,14,0,0,1-1.91,1.15h3.74Zm-7.73-1.88h-1.13V49.48h.75l-.18-3a13.91,13.91,0,0,0,2-.56l.49.69a13.39,13.39,0,0,1-1.37.38l0,.29h1.27v.73h-1.23l0,.34h1.23v.51a6.64,6.64,0,0,0,.7-.38l-.56-.33.55-.47.63.34a3.08,3.08,0,0,0,.39-.42l.78.24a4.78,4.78,0,0,1-.53.59,4.83,4.83,0,0,1,.61.46l-.6.52a6.66,6.66,0,0,0-.58-.49,7.34,7.34,0,0,1-.81.52h3.24l0-.35h-1.18v-.74h1.25l0-.34h-1.22v-.73h1.27l0-.33h-1.34v-.75h2.5c0,1-.13,2.31-.21,3.24h.74v2.05H128V50.34H121.6Zm.72-2.05h1.62a6.38,6.38,0,0,0-.45-.46v.11H122.3Zm3.1-1.84c-.13-.13-.32-.26-.52-.42a5.08,5.08,0,0,1-.81.5,4.72,4.72,0,0,0-.62-.52,5.45,5.45,0,0,0,.76-.39l-.54-.3.53-.46.61.32a3.33,3.33,0,0,0,.36-.4l.78.23a3.66,3.66,0,0,1-.5.55,5,5,0,0,1,.52.4Z"
          fill="#002a5e"
        />
        <circle
          cx="162.94"
          cy="114.09"
          r="44.13"
          fill="#f8a908"
          stroke="#002a5e"
        />
        <path
          d="M155.45,125c.44,0,.52-.19.59-1.16a3.23,3.23,0,0,0,1.06.45c-.15,1.38-.49,1.8-1.56,1.8h-1.91c-1.3,0-1.65-.36-1.65-1.61v-2.1l-.45.2-.45-1.06c-.05,2.69-.12,3.74-.38,4.08a.9.9,0,0,1-.7.46,5.36,5.36,0,0,1-1.13.06,3.4,3.4,0,0,0-.32-1.17,5.45,5.45,0,0,0,.8,0,.32.32,0,0,0,.31-.18c.14-.22.21-1.15.25-3.73h-.86a9.84,9.84,0,0,1-1.42,5,3.36,3.36,0,0,0-.93-.91c1.09-1.51,1.19-3.92,1.22-6.47h-1.06v-1.21h1.92a11,11,0,0,0-.45-1.42l1.16-.28a15.16,15.16,0,0,1,.52,1.44l-.89.26h2.18v1.15a7.68,7.68,0,0,0,1.21-2.84l1.27.26a10.72,10.72,0,0,1-.34,1.14h3.48v1.19h-4a8.38,8.38,0,0,1-.63,1.07h.81v1.05l.59-.26v-1.59h1.09v1.07l.81-.37.19-.14.86.24-.06.17a34.07,34.07,0,0,1-.26,3.81l-.93-.18c.06-.66.09-1.64.1-2.58l-.71.33v3.08h-1.09v-2.57l-.59.28v2.65c0,.45.07.52.62.52ZM152,121.09v-1.15l-.1.11a6.68,6.68,0,0,0-1-.93,4.4,4.4,0,0,0,.35-.39h-2.1l0,1.17h2v.51l0,1.1Z"
          fill="#002a5e"
        />
        <path
          d="M168,125.77h-3.85v.36h-1.18v-4.26l-.11.11a7,7,0,0,0-1-.72,6.23,6.23,0,0,0,1.66-2.43h-3.14l.59.24a10.8,10.8,0,0,1-1.24,1.53l.81,0a10.66,10.66,0,0,0,.71-1l1,.56a15.12,15.12,0,0,1-2.15,2.25l1.26-.12c-.09-.2-.18-.38-.25-.55l.86-.31a7.58,7.58,0,0,1,.71,1.81l-.92.35c0-.15-.07-.31-.11-.48a17.11,17.11,0,0,0-3.17.45,6.06,6.06,0,0,0-.4-.82,1.92,1.92,0,0,0,.9-.54,10.17,10.17,0,0,0,.75-.69,5.93,5.93,0,0,0-1.35.19,5.4,5.4,0,0,0-.42-.83,1.13,1.13,0,0,0,.64-.41,7.75,7.75,0,0,0,1.16-1.6h-1.27v-2.62h8.92v2.62H163.7l1,.24a9.42,9.42,0,0,1-.45,1h1.07a8.16,8.16,0,0,0-.36-.84l1-.3a5.57,5.57,0,0,1,.52,1.14h1.36v1h-1.44v.58h1.14v.94h-1.14v.59h1.14v.94h-1.14v.6H168Zm-8.5-1.85a8,8,0,0,1-.53,2,9.19,9.19,0,0,0-1-.23,5.4,5.4,0,0,0,.52-1.87Zm1.2-.16a11,11,0,0,1,.33,1.8l-1,.19a8.86,8.86,0,0,0-.28-1.82Zm.34-6.62h-1.29v.75H161Zm.92,6.4a7.89,7.89,0,0,1,.69,1.67l-1,.23a7.85,7.85,0,0,0-.63-1.69Zm.28-5.65h1.32v-.75h-1.32Zm3,3.18h-1.13v.58h1.13Zm0,1.52h-1.13v.59h1.13Zm-1.13,2.13h1.13v-.6h-1.13Zm.6-6.83h1.35v-.75h-1.35Z"
          fill="#002a5e"
        />
        <path
          d="M172.22,118.68a14.73,14.73,0,0,1-.86,1.53h0v5.91h-1.22v-4.19a9.21,9.21,0,0,1-.79.9,9.72,9.72,0,0,0-.72-1.22,11.81,11.81,0,0,0,2.4-3.35Zm-.2-2.35a18.45,18.45,0,0,1-2.69,3.07,8.81,8.81,0,0,0-.61-1.08,11.15,11.15,0,0,0,2.13-2.47Zm-.56,8.82a6.22,6.22,0,0,0,.86-2l1,.38a8.49,8.49,0,0,1-.87,2.21Zm7.59-2.47h-3.33c.23.38.48.85.63,1.16l-1,.37a13.72,13.72,0,0,0-.66-1.35l.56-.18H172v-1.05h7.09Zm-4.33-4.46c0-.18,0-.36.06-.56H172.1v-1h2.77c0-.29,0-.56,0-.81l1.39,0-.11.77H179v1h-3c0,.2-.07.38-.09.56h2.72v2.92h-6.19v-2.92ZM176.1,125c.31,0,.38-.12.41-.92a3.42,3.42,0,0,0,1,.39c-.12,1.2-.43,1.49-1.28,1.49h-1.36c-1.13,0-1.41-.3-1.41-1.26v-1.34h1.15v1.33c0,.27.06.31.43.31Zm-1.84-5.86h-.74v1.07h.74Zm1.67,0h-.77v1.07h.77Zm.88,1.07h.8v-1.07h-.8Zm1.17,2.84a8.06,8.06,0,0,1,1.17,2.08l-1,.46a7.39,7.39,0,0,0-1.1-2.11Z"
          fill="#002a5e"
        />
        <path
          d="M139.81,133a13,13,0,0,1-1,4.06,4.7,4.7,0,0,0,1.89,2,4.09,4.09,0,0,0-.82,1,5.44,5.44,0,0,1-1.74-1.91,6.18,6.18,0,0,1-2.38,2,6,6,0,0,0-.36-.64l-.33.3a9.63,9.63,0,0,0-1.32-.64,9.07,9.07,0,0,1-3,1,3.33,3.33,0,0,0-.5-.85,10.35,10.35,0,0,0,2.3-.57c-.51-.18-1-.33-1.52-.45a7.27,7.27,0,0,0,.6-.84h-1.16v-.88h1.73l.26-.49h-1.53v-1.95h1.95v-.32h-1.76v-1.21h-.76v-.92h.76V130.5h1.76v-.66h1v.66h1.89v1.14h.57v.92h-.57v1.21h-1.89v.32h2v.44a17.22,17.22,0,0,0,1.33-4.69l1.08.2c-.1.61-.23,1.23-.38,1.84h2.67V133ZM132,135.31h.87v-.48H132Zm.08-4v.38h.79v-.38Zm.79,1.63v-.45H132V133Zm-.06,4.46c-.07.13-.16.26-.24.38.35.1.7.2,1,.32a2,2,0,0,0,.57-.7Zm2.47,0a2.38,2.38,0,0,1-.6,1.09l1,.44a5,5,0,0,0,2-1.89,15.72,15.72,0,0,1-.65-2.27,8.24,8.24,0,0,1-.45.94,6.36,6.36,0,0,0-.64-.65v1h-2.31c-.07.16-.16.33-.25.49h1v-.32h1v.32h.92v.88Zm-1.42-6.09v.38h.86v-.38Zm.86,1.63v-.45h-.86V133Zm-.86,1.88v.48h.9v-.48Zm3.76-1.81a14.49,14.49,0,0,0,.63,2.65,13.48,13.48,0,0,0,.49-2.65Z"
          fill="#002a5e"
        />
        <path
          d="M143.64,135.92v2.83c0,.61-.1.93-.44,1.13a2.67,2.67,0,0,1-1.48.24,5.2,5.2,0,0,0-.33-1.19c.39,0,.77,0,.9,0s.18,0,.18-.2v-2.48c-.36.11-.7.2-1,.28l-.28-1.24,1.31-.33v-1.85h-1.12v-1.21h1.12v-2.07h1.17v2.07h.85v1.21h-.85v1.53l.84-.23.16,1.18Zm2.23-.89c0,1.55-.15,3.7-1.12,5.08a4.44,4.44,0,0,0-.95-.65,7.87,7.87,0,0,0,.95-4.43v-3.27h2.39v-1.88h1.25v.54h2.51v.9h-2.51v.44h2.08l.18,0,.8.15a11.84,11.84,0,0,1-.54,1.78l-1-.21a5,5,0,0,0,.22-.78h-2v.41l1.49-.13.08.7-1.57.14c0,.21.07.3.32.3h1.25a6.56,6.56,0,0,0,.92-.06c0,.27.05.51.08.77a4.87,4.87,0,0,1-.93.06h-1.56c-.77,0-1-.25-1.11-.76l-1,.11-.1-.93,1.11-.1v-.51h-1.26Zm5.63,1.66c-.33.18-.7.36-1.07.52a3.32,3.32,0,0,0,1.25,1.79,3.61,3.61,0,0,0-.71.8,4.1,4.1,0,0,1-1.3-2.27c-.13.06-.26.09-.39.14a2.08,2.08,0,0,1-.25,2.11,1.07,1.07,0,0,1-.87.36h-.57a2.11,2.11,0,0,0-.25-.91,5,5,0,0,0,.61,0,.42.42,0,0,0,.39-.16,1,1,0,0,0,.15-.65,11.54,11.54,0,0,1-2.57,1.31,4.87,4.87,0,0,0-.47-.68,8.36,8.36,0,0,0,2.88-1.37c0-.09-.08-.15-.12-.24a10.71,10.71,0,0,1-2.08,1,2.48,2.48,0,0,0-.43-.64,6.94,6.94,0,0,0,2.15-.84c-.06-.05-.11-.12-.17-.17a10.6,10.6,0,0,1-1.43.5,3.88,3.88,0,0,0-.35-.63,9.81,9.81,0,0,0,2-.72h-1.82v-.84h5.34V136h-2.29a5.17,5.17,0,0,1-.69.46,2.91,2.91,0,0,1,.52.6,20.24,20.24,0,0,0,1.86-.91Z"
          fill="#002a5e"
        />
        <path
          d="M154.22,135a15.65,15.65,0,0,0-2-1.14l.66-1a11.52,11.52,0,0,1,2.05,1Zm-1.72,4a30.15,30.15,0,0,0,1.84-3.37l1,.86c-.5,1.14-1.11,2.34-1.7,3.39Zm.95-9.06a14.72,14.72,0,0,1,2,1.05l-.76,1.07a13,13,0,0,0-1.91-1.16Zm8.75,8.62c0,.66-.11,1-.51,1.26a3.78,3.78,0,0,1-1.79.25,4.33,4.33,0,0,0-.39-1.32c.5,0,1,0,1.22,0s.22-.05.22-.23v-7.18h-4.32v8.7h-1.22v-9.88h6.79Zm-1.7-5.38h-3.41v-1.07h3.41Zm-2.23,4.45v.64h-1V134h3v3.72Zm0-2.64v1.55h1v-1.55Z"
          fill="#002a5e"
        />
        <path
          d="M171.88,138.79c.37,0,.44-.19.49-1.46a4.57,4.57,0,0,0,1.2.51c-.14,1.74-.48,2.19-1.57,2.19h-1.45c-1.31,0-1.62-.4-1.62-1.63v-1.76H167.6c-.23,1.75-.88,2.91-3.6,3.52a4.1,4.1,0,0,0-.72-1.16c2.25-.42,2.72-1.16,2.91-2.36h-1.34v-6.46H172v6.46h-1.79v1.75c0,.35.07.4.48.4ZM166.11,132h4.6v-.68h-4.6Zm0,1.74h4.6v-.68h-4.6Zm0,1.75h4.6v-.68h-4.6Z"
          fill="#002a5e"
        />
        <path
          d="M184.05,130.21v9.9H182.8v-.53H176v.55h-1.18v-9.92Zm-1.25,8.39v-7.4H176v7.4Zm-.24-1.57h-1.42v.69c0,.42-.09.55-.4.65a4.75,4.75,0,0,1-1.34.1,2.6,2.6,0,0,0-.26-.66h.77c.11,0,.15,0,.15-.13V137h-1.95a4.32,4.32,0,0,1,.74.88l-.87.44a4.1,4.1,0,0,0-.76-1l.7-.33h-1.71v-.71h3.85V136h1.08v.32h.58a2.46,2.46,0,0,0-.32-.37c-1.81.07-3.63.12-5,.14l0-.69h2.45V135h-2.18v-2.25h2.18v-.32h-2.49v-.71h2.49v-.41h1.07v.41h2.51v.71h-2.51v.32h2.21V135h-.55a6.37,6.37,0,0,1,.91.85l-.58.43h.66Zm-4.9-3.38h1.18v-.32h-1.18Zm1.18.84v-.32h-1.18v.32Zm1.07-1.16v.32h1.16v-.32Zm1.16.84h-1.16v.32h1.16Zm-1.16,1.22h.87l-.11-.09L181,135h-1.13Z"
          fill="#002a5e"
        />
        <path
          d="M189.16,130.76c-.29.93-.66,2.13-1,3A3.44,3.44,0,0,1,189,136c0,.72-.15,1.17-.5,1.41a1.28,1.28,0,0,1-.63.24,5.19,5.19,0,0,1-.7,0,2.66,2.66,0,0,0-.29-1.11h.48a.49.49,0,0,0,.3-.1c.14-.1.18-.32.18-.61a2.94,2.94,0,0,0-.76-1.94c.22-.68.44-1.65.63-2.4h-.86v8.64h-1.13v-9.8h2.38l.19-.06Zm6.26,3.14a15.07,15.07,0,0,1-1.3.81,8.93,8.93,0,0,0,1.66,3,4.29,4.29,0,0,0-.82.83,11.3,11.3,0,0,1-1.75-3.36l-.05,0c.53,1.75.29,3.59-.33,4.2a1.5,1.5,0,0,1-1.28.56c-.23,0-.59,0-.89,0a2.83,2.83,0,0,0-.31-1.16,9.11,9.11,0,0,0,1,0,.56.56,0,0,0,.58-.3,2.37,2.37,0,0,0,.34-1.42,11.53,11.53,0,0,1-2.76,1.56,4.43,4.43,0,0,0-.77-.91,8.67,8.67,0,0,0,3.42-1.89c0-.17-.09-.37-.15-.57a12.39,12.39,0,0,1-2.14,1.36,4.49,4.49,0,0,0-.69-.93,9,9,0,0,0,2.46-1.35,5.48,5.48,0,0,0-.38-.6,11.79,11.79,0,0,1-1.77,1,8.87,8.87,0,0,0-.76-.93,8.35,8.35,0,0,0,2.26-1.22h-1.91v-1.12h1.74a7.26,7.26,0,0,0-1-1.11l1-.58A5.82,5.82,0,0,1,192,131.2l-.53.37h1a11,11,0,0,0,1-1.7l1.26.35c-.3.48-.63.95-.92,1.35h1.63v1.13h-2.93a3.84,3.84,0,0,1-.46.44,4.7,4.7,0,0,1,.74,1.15A14.58,14.58,0,0,0,194.6,133Z"
          fill="#002a5e"
        />
        <circle
          cx="164.69"
          cy="185.26"
          r="22.5"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="0.84"
        />
        <path
          d="M163.41,173.43v9.69h-1v-.41h-7.26v.41h-1v-9.69Zm-1,8.43v-7.57h-7.26v7.57Zm-.42-5v.59h-6.4v-.59h2.71v-.43H156.6v-1.72H161v1.72h-1.78v.43Zm-5.75,1h5.07v3.49h-5.07Zm.79,2.88h3.44v-2.29H157Zm.39-4.87h2.66v-.6h-2.66Zm.19,3h2.28v1.48h-2.28Zm.6,1h1.06v-.52h-1.06Z"
          fill="#fff"
        />
        <path
          d="M168,173.37a16.51,16.51,0,0,1-1.08,2.39v7.34h-1v-5.76a11.92,11.92,0,0,1-.81,1,5.75,5.75,0,0,0-.56-1,12.17,12.17,0,0,0,2.46-4.25Zm6.3,5.21c-.35.21-.75.42-1.14.62a4.64,4.64,0,0,0,1.74,2.5,3.12,3.12,0,0,0-.64.76,5.58,5.58,0,0,1-1.84-2.91l-.38.17a3.15,3.15,0,0,1-.25,2.95,1.29,1.29,0,0,1-1,.41l-.7,0a2.26,2.26,0,0,0-.23-.87c.28,0,.53.05.72.05a.55.55,0,0,0,.53-.24,1.75,1.75,0,0,0,.25-1.34,10.37,10.37,0,0,1-3.4,2,3,3,0,0,0-.58-.7,9.61,9.61,0,0,0,3.78-2.1c0-.16-.13-.31-.2-.48a11,11,0,0,1-3,1.66,4.63,4.63,0,0,0-.55-.65,9,9,0,0,0,3.13-1.58,3.16,3.16,0,0,0-.39-.43,10.51,10.51,0,0,1-2.29,1.2,3.66,3.66,0,0,0-.54-.65,10.6,10.6,0,0,0,2.45-1.12h-1.59v-1.63c-.17.13-.32.27-.5.4a2.87,2.87,0,0,0-.63-.67,7.89,7.89,0,0,0,2.45-2.83l.94.17a6,6,0,0,1-.33.6H172l.2-.06.59.44a11.42,11.42,0,0,1-.83,1.14h2.18v2.44h-3.23l-.11.1a3.84,3.84,0,0,1,1,1.17,16,16,0,0,0,1.86-1.11Zm-4.69-4c-.18.24-.38.49-.6.74h1.86a7.11,7.11,0,0,0,.55-.74Zm-.51,2.42h1.54v-.91h-1.54Zm4.08-.91h-1.62V177h1.62Z"
          fill="#fff"
        />
        <path
          d="M156.61,191.4a10.25,10.25,0,0,1-.56,2.32c.67,1.17,2,1.35,3.93,1.35,1.15,0,2.82,0,4-.16a3.39,3.39,0,0,0-.33.94c-1.07.07-2.45.1-3.67.1-2.13,0-3.45-.22-4.31-1.41a4.85,4.85,0,0,1-1.34,1.62,3,3,0,0,0-.68-.7,5.23,5.23,0,0,0,2-3.42h-.93a1.19,1.19,0,0,0-.66.17,4.77,4.77,0,0,0-.34-.87,1,1,0,0,0,.54-.34,16.34,16.34,0,0,0,1.18-1.61h-1.55v-.82H156l.14-.07.65.29c-.43.63-1.17,1.7-1.74,2.45H156l.16,0Zm-1.14-3.16a13.11,13.11,0,0,0-1.3-1.76L155,186a15.34,15.34,0,0,1,1.33,1.67Zm2.18.16h-.85v-2h6.6v2h-.78v.37h-2v.55h2.31v3.15h-2.31v.59h2.9v.78h-2.9v.81h-1v-.81h-2.75v-.78h2.75v-.59h-2.25v-3.15h2.25v-.55h-2Zm.08-1.24V188h1.91v-.48h1V188h1.79v-.87Zm.5,3.41h1.41V190h-1.41Zm1.41,1.23v-.6h-1.41v.6Zm1-1.82v.59h1.43V190Zm1.43,1.22h-1.43v.6h1.43Z"
          fill="#fff"
        />
        <path
          d="M174.75,187.52h-1.69a4.43,4.43,0,0,1,.6.74l-.63.28h.55v4.11h-1.31v.84h2.42v.82h-2.42v1.81h-1.05v-1.81H168.5c-.27.69-1,1.36-2.58,1.86a4.45,4.45,0,0,0-.64-.76,3.46,3.46,0,0,0,2.07-1.1h-2.42v-.82h2.69v-.84h-1.41v-4.11h1.59a4.71,4.71,0,0,0-.42-.75l.77-.25h-1.52a9.39,9.39,0,0,1-1.28,1.51c-.14-.18-.47-.61-.66-.8a6.29,6.29,0,0,0,1.72-2.29l.94.3c-.08.17-.17.33-.26.5h2.8v.78h-1.65a4.74,4.74,0,0,1,.46.75l-.73.25h2.3a5.63,5.63,0,0,0-.79-.43A4.91,4.91,0,0,0,171,186l1,.24c-.08.2-.17.39-.25.57h3Zm-2.24,1.66h-5.26v.52h5.26Zm0,1.13h-5.26v.54h5.26Zm0,1.15h-5.26V192h5.26Zm-1.29,1.19h-2.57v.84h2.57Zm1.56-4.11a3.71,3.71,0,0,0-.59-.76l.56-.26H171.3a6.26,6.26,0,0,1-.84,1Z"
          fill="#fff"
        />
        <circle
          cx="236.74"
          cy="110.91"
          r="22.5"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="0.84"
        />
        <path
          d="M230.11,104c-.32-.26-1-.78-1.56-1.14a4.64,4.64,0,0,1-2.11,1.66,3,3,0,0,0-.65-.68,3.37,3.37,0,0,0,2.1-1.75h-2v-.82h.75a6.92,6.92,0,0,0-.78-.46,5.08,5.08,0,0,0,1.21-2.17l.91.19c-.08.26-.17.49-.25.73h2.85v.81h-1.52v.57c0,.11,0,.22,0,.33h1.8v.82h-2l0,.1c.45.22,1.66.93,2,1.14Zm-2-2.73c0-.12,0-.23,0-.34v-.56h-.77a4.5,4.5,0,0,1-.64.9Zm-.66,3h6.9v4.53h-1.05v-.38h-4.84v.39h-1Zm1,.82v.86h4.84V105Zm4.84,2.51v-.88h-4.84v.88Zm2-8v3.93h-4V99.53Zm-1,.9h-2v2.12h2Z"
          fill="#fff"
        />
        <path
          d="M239,106.42a6.81,6.81,0,0,1-1.49,2.36c-.19-.17-.6-.46-.82-.61a4.79,4.79,0,0,0,1.33-2Zm2.24-.41h-3.93V99.09h3.93Zm-1-6.09h-2v1.21h2Zm0,2h-2v1.23h2Zm0,2h-2v1.25h2Zm.14,2.24a8.49,8.49,0,0,1,1.19,1.75l-.87.45a9.29,9.29,0,0,0-1.16-1.82Zm6.51-4H245.6v5.35c0,.55-.13.84-.46,1a4.49,4.49,0,0,1-1.67.2,3.57,3.57,0,0,0-.37-1c.58,0,1.09,0,1.25,0s.24-.06.24-.23v-4A10.61,10.61,0,0,1,242,107a4.43,4.43,0,0,0-.7-.74,9.83,9.83,0,0,0,2.94-4.09h-2.66v-1h3V98.7h1v2.54h1.31Z"
          fill="#fff"
        />
        <path
          d="M235.8,119.38h-2.54v1.35c0,.49-.1.75-.5.9a6.39,6.39,0,0,1-1.8.13,3.21,3.21,0,0,0-.32-.88c.61,0,1.23,0,1.4,0s.22-.06.22-.19v-1.32h-3.65a8.52,8.52,0,0,1,1.49,1.2l-.75.66a9.24,9.24,0,0,0-1.72-1.43l.49-.43h-2.23v-.82h6.37V118l-5.9.12,0-.77,4,0v-.51H227v-3h3.28v-.52H226.2v-.79h4.12v-.77h1v.77h4.06v.79h-4.06v.52h3.39v3h-1a8.39,8.39,0,0,1,1.7,1l-.6.55a4.68,4.68,0,0,0-.57-.41l-1,0v.6h2.54ZM228,115h2.31v-.55H228Zm2.31,1.2v-.57H228v.57Zm1-1.75V115h2.39v-.55Zm2.39,1.18h-2.39v.57h2.39Zm-2.39,1.72,1.77,0a4.77,4.77,0,0,0-.67-.28l.26-.21h-1.36Z"
          fill="#fff"
        />
        <path
          d="M240.11,114.93h2v.94h-2v.73c.54.39,1.7,1.38,2,1.65l-.58.87c-.31-.36-.89-1-1.41-1.45v4.08h-1v-4.37a11.66,11.66,0,0,1-1.9,2.94,6.73,6.73,0,0,0-.54-1,11,11,0,0,0,2.23-3.46h-2v-.94h2.2v-1.64c-.67.13-1.35.24-2,.32a2.89,2.89,0,0,0-.31-.8,20,20,0,0,0,4.48-1.09l.74.78a12.92,12.92,0,0,1-1.92.58Zm3.63,4.1h-1v-6h1Zm2.66-7.15v8.59c0,.64-.15.94-.53,1.11a6.51,6.51,0,0,1-2.07.19,4.62,4.62,0,0,0-.37-1.06,16.36,16.36,0,0,0,1.67,0c.2,0,.28-.06.28-.26v-8.59Z"
          fill="#fff"
        />
        <circle cx="155.29" cy="101.04" r="3.29" fill="#002a5e" />
        <circle cx="170.54" cy="101.04" r="3.29" fill="#002a5e" />
        <circle cx="163.05" cy="97.63" r="3.29" fill="#002a5e" />
        <path d="M169.77,109.25a6.72,6.72,0,0,0-13.44,0Z" fill="#002a5e" />
        <rect
          x="155.03"
          y="79.98"
          width="16.27"
          height="11.44"
          rx="1.02"
          fill="#002a5e"
        />
        <circle cx="159.63" cy="86.57" r="0.69" fill="#fff" />
        <circle cx="164.68" cy="85.73" r="0.69" fill="#fff" />
        <circle cx="162.7" cy="84.08" r="0.69" fill="#fff" />
        <polyline
          points="159.63 86.57 162.73 84.2 164.68 85.7 168.14 82.96"
          fill="none"
          stroke="#fff"
          strokeWidth="0.5"
        />
        <polyline
          points="169.31 88.65 157.56 88.65 157.56 82.3"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.5"
        />
        <polygon
          points="168.7 82.31 166.6 82.78 168.06 84.36 168.7 82.31"
          fill="#fff"
        />
        <polygon
          points="163.17 92.96 164.59 90.49 161.75 90.49 163.17 92.96"
          fill="#002a5e"
        />
        <path d="M163.44,113v-.37a7.1,7.1,0,0,1,14.2,0V113Z" fill="#002a5e" />
        <path
          d="M170.54,105.93a6.72,6.72,0,0,1,6.72,6.73H163.82a6.72,6.72,0,0,1,6.72-6.73m0-.75a7.49,7.49,0,0,0-7.47,7.48v.75H178v-.75a7.49,7.49,0,0,0-7.47-7.48Z"
          fill="#f8a908"
        />
        <path d="M148.19,113v-.37a7.1,7.1,0,1,1,14.19,0V113Z" fill="#002a5e" />
        <path
          d="M155.29,105.93a6.72,6.72,0,0,1,6.72,6.73H148.56a6.73,6.73,0,0,1,6.73-6.73m0-.75a7.49,7.49,0,0,0-7.48,7.48v.75h14.95v-.75a7.49,7.49,0,0,0-7.47-7.48Z"
          fill="#f8a908"
        />
      </g>
    </SVG>
  );
};

export default Chart23;
