import React from "react";
import SVG from "../../components/SVG";

const RadarCorner = () => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329 270">
      <g>
        <circle
          cx="93.13"
          cy="238.91"
          r="29.23"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M78,249.55V250h2.57v3.71h-1v-.37h-6v.37h-.93V250h2.76v-.43H71.52v-.69H81.58v.69Zm-5.82-5h-.34V244h4.87v.61H72.93v2.83c0,.38.09.52.47.52h2.18a6.25,6.25,0,0,0,1-.07c0,.22.06.47.09.64a5.84,5.84,0,0,1-1,.06H73.36c-1,0-1.23-.31-1.23-1.17Zm1.82,1a3.3,3.3,0,0,1-.37.44,3.11,3.11,0,0,0-.53-.32,3,3,0,0,0,.76-1.1l.6.14a2.84,2.84,0,0,1-.14.32h2v.52H75.18v.5H76.5v.54H75.21a8.76,8.76,0,0,1,1.18.82l-.4.46a7.11,7.11,0,0,0-1.11-.82,2.7,2.7,0,0,1-1.27.88,1.74,1.74,0,0,0-.44-.5,1.79,1.79,0,0,0,1.23-.84H73.14V246h1.37v-.5Zm5.61,7.2v-1.2H78.21c-.86,0-1.07-.15-1.07-.76v-.22h-1c-.12.56-.66,1-2.17,1.2a3,3,0,0,0-.44-.59c1.17-.11,1.62-.34,1.78-.61H73.55v2.18Zm-5.16-.89h4.46v.53H74.4Zm2.74-2.3h-1V250h1ZM77,246.09a3.08,3.08,0,0,0-.53-.59c.84-.31,1-.67,1-1.05v-.56h2.73v.92c0,.21,0,.28.15.28h.44a1.86,1.86,0,0,0,.48-.06c0,.21,0,.49.06.69a1.83,1.83,0,0,1-.5.05h-.6c-.69,0-.83-.28-.83-1v-.19H78.24a1.61,1.61,0,0,1-.88,1.34h2.78l.15,0,.49.21a3.79,3.79,0,0,1-1.1,1.48c.51.23,1,.44,1.31.63l-.37.59c-.39-.21-1-.48-1.57-.77a7.28,7.28,0,0,1-1.94.77,3.29,3.29,0,0,0-.42-.63,7.3,7.3,0,0,0,1.54-.5L77,247.19l.36-.52c.48.18,1,.4,1.57.63a3.63,3.63,0,0,0,.74-.72H77Zm1,4.47v.23c0,.21,0,.24.35.24h1.26v-.46Z"
          fill="#002a5e"
        />
        <path
          d="M92.33,247.56a6.27,6.27,0,0,1-.84.73,5.07,5.07,0,0,0,1.21.65,3.78,3.78,0,0,0-.59.73,7.8,7.8,0,0,1-.79-.44v2h-2v1.54c0,.45-.09.67-.42.8a4.3,4.3,0,0,1-1.51.15,2.72,2.72,0,0,0-.33-.83h1.09c.15,0,.19,0,.19-.15v-1.51h-2v-2c-.24.16-.52.33-.8.48a2.34,2.34,0,0,0-.58-.64,7.6,7.6,0,0,1-1.55,4.64,3.77,3.77,0,0,0-.71-.68A5.73,5.73,0,0,0,84,249.92c-.42.25-.83.51-1.25.73l-.32-.92c.43-.17,1-.49,1.66-.81,0-.3,0-.56,0-.84v-3.51h3.73c-.06-.26-.16-.52-.24-.77l1-.21c.13.3.27.68.37,1h3.64v.87H85v2.64c0,.3,0,.6,0,.93a6.88,6.88,0,0,0,1.19-.74,8.69,8.69,0,0,0-.75-.61l.53-.46c.25.17.54.38.79.59a7.31,7.31,0,0,0,.59-.7H85.45v-.79h2.36a3.42,3.42,0,0,0,.34-.86l1,.13a4.55,4.55,0,0,1-.27.73h3.63v.79H90.26a5.08,5.08,0,0,0,.7.77,9.08,9.08,0,0,0,.76-.72Zm-9.15.45c-.12-.54-.41-1.48-.65-2.17l.76-.29a17.23,17.23,0,0,1,.68,2.09Zm4.18,3.69a13.63,13.63,0,0,1-1.67,1.78,6.87,6.87,0,0,0-.78-.58,8.81,8.81,0,0,0,1.54-1.51Zm3.07-2.84H87.22v.56h3.21Zm0,1.17H87.22v.56h3.21Zm-2-2.94a6.62,6.62,0,0,1-.87,1.14h2.61a6.09,6.09,0,0,1-.84-1.14Zm2.34,4.28c.53.51,1.27,1.23,1.63,1.67l-.78.49c-.34-.43-1-1.19-1.59-1.73Z"
          fill="#002a5e"
        />
        <path
          d="M97.15,250.35a13.77,13.77,0,0,0-.86-1.45v4.8h-1v-4.45a10.18,10.18,0,0,1-1.41,2.56,6,6,0,0,0-.49-.94,11,11,0,0,0,1.76-3.23H93.54v-.95H95.3v-1.48c-.47.09-.94.15-1.38.22a3.43,3.43,0,0,0-.29-.79,17,17,0,0,0,3.5-.89l.58.83a9.58,9.58,0,0,1-1.42.42v1.69h1.54v.95H96.29v.16c.33.34,1.27,1.46,1.48,1.75Zm5.16.54v2.81h-1v-2.64l-3.47.59-.15-1,3.62-.61v-6.44h1v6.28l1.2-.2.15,1Zm-2.4-1.49a9.72,9.72,0,0,0-1.83-1.64l.65-.59a11,11,0,0,1,1.88,1.56Zm.28-2.89a9.24,9.24,0,0,0-1.71-1.6l.67-.58a9,9,0,0,1,1.75,1.53Z"
          fill="#002a5e"
        />
        <path
          d="M108.22,249.28l-1.26.36v2.9c0,.53-.11.8-.43,1a3.85,3.85,0,0,1-1.56.17,3.44,3.44,0,0,0-.31-.92h1.08c.16,0,.22-.06.22-.21v-2.61l-1.21.33-.26-1,1.47-.35v-2.21h-1.35v-.95H106v-2.14h1v2.14h1.16v.95H107v1.93l1.13-.29Zm5.93-1.34a8,8,0,0,1-2.15,3.64,7.82,7.82,0,0,0,2.71,1.28,3.51,3.51,0,0,0-.66.87,7.78,7.78,0,0,1-2.84-1.49,9.25,9.25,0,0,1-3,1.46,4.77,4.77,0,0,0-.55-.92,8.45,8.45,0,0,0,2.82-1.25,9.13,9.13,0,0,1-1.7-2.84l.14-.05h-.44v-.93h2.27V246.2h-2.47v-.93h2.47v-1.65h1v1.65h2.54v.93h-2.54v1.51h1.56l.18,0Zm-4.38.7a7.11,7.11,0,0,0,1.47,2.27,6.32,6.32,0,0,0,1.61-2.27Z"
          fill="#002a5e"
        />
        <path
          d="M71.81,222.26l2.29,3.22-7.07,5-2.29-3.21a4.35,4.35,0,0,1,1-6.06,4.36,4.36,0,0,1,6,1Z"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M74.1,225.48l2.29,3.21a4.34,4.34,0,1,1-7.06,5L67,230.52Z"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="82.68"
          y="206.06"
          width="24.13"
          height="32.54"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="78.56"
          cy="231.67"
          r="3.87"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="75.62"
          y1="229.16"
          x2="81.31"
          y2="234.39"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="77.38"
          cy="221.78"
          r="2.54"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="75.45"
          y1="220.13"
          x2="79.19"
          y2="223.56"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="87.04"
          y1="221.78"
          x2="102.44"
          y2="221.78"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="87.04"
          y1="225.6"
          x2="102.44"
          y2="225.6"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="87.04"
          y1="229.42"
          x2="102.44"
          y2="229.42"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="87.04"
          y1="233.24"
          x2="102.44"
          y2="233.24"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <polygon
          points="99.01 211.71 96.45 211.71 96.45 209.15 92.5 209.15 92.5 211.71 89.94 211.71 89.94 215.66 92.5 215.66 92.5 218.22 96.45 218.22 96.45 215.66 99.01 215.66 99.01 211.71"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="125.36"
          y1="210.02"
          x2="129.41"
          y2="213.02"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="111.63"
          y1="232.75"
          x2="108.78"
          y2="236.61"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="123.55"
          y="210.71"
          width="2.7"
          height="8.47"
          transform="translate(152.31 -32.13) rotate(36.49)"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="110.57"
          y="228.83"
          width="4.19"
          height="5.29"
          transform="translate(159.75 -21.62) rotate(36.49)"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="116"
          y="212.98"
          width="5.69"
          height="20.28"
          transform="translate(155.99 -26.94) rotate(36.49)"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="119.72"
          y1="217.58"
          x2="121.78"
          y2="219.1"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="114.33"
          y1="224.87"
          x2="116.39"
          y2="226.39"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="116.96"
          y1="221.31"
          x2="119.71"
          y2="223.34"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="121.39"
          y1="212.38"
          x2="128.37"
          y2="217.55"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="84.53"
          cy="228.73"
          r="3.87"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="81.84"
          y1="231.5"
          x2="87.42"
          y2="226.15"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="275.6"
          cy="122.98"
          r="29.23"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M267.31,140a12.82,12.82,0,0,0,1.91-.13,9.19,9.19,0,0,0,.15,1,12.06,12.06,0,0,1-2.09.11h-4.61c-1.79,0-2.63-.56-2.63-2.33v-5.94h-.44v-.9h9.23v.9H261v6c0,.93.38,1.31,1.53,1.31Zm-5.56-3.58h2.91v2.87h-2.91Zm.83,2.15h1.2v-1.42h-1.2Zm4.49-2.77h-4.16v-2.5h4.16Zm-1-1.81h-2.2v1.1h2.2Zm2.25,5.3h-3v-2.87h3Zm-.89-2.14h-1.26v1.42h1.26Z"
          fill="#002a5e"
        />
        <path
          d="M274,138.6c-1.16.5-2.42,1-3.39,1.39l-.31-1c.39-.12.88-.3,1.41-.5v-3.57H270.5v-1h1.24v-2.5h1v2.5h1.09v1H272.7v3.23l1.14-.43Zm5.47,1.81c.28,0,.33-.12.36-.79a2.42,2.42,0,0,0,.8.34c-.1,1-.36,1.27-1.08,1.27h-1.42c-1,0-1.2-.25-1.2-1.17V136.9h-.62c-.15,2-.56,3.59-2.3,4.49a2.77,2.77,0,0,0-.59-.77c1.52-.72,1.84-2.06,2-3.72h-1.21v-4.53h1.12a10.91,10.91,0,0,0,.34-1.14l1.09.15c-.14.34-.3.69-.45,1H280v4.53h-2.17v3.15c0,.32.06.36.43.36Zm-4.39-6.17h1.53v-1.05h-1.53Zm0,1.83h1.53v-1h-1.53Zm3.95-2.88h-1.52v1.05h1.52Zm0,1.83h-1.52v1h1.52Zm.89,4.65a2.55,2.55,0,0,0-.11-.33,7.94,7.94,0,0,0-1.78.28,3.74,3.74,0,0,0-.23-.63c.12,0,.23-.14.32-.32a5.49,5.49,0,0,0,.58-1.54l.65.2a9.21,9.21,0,0,1-.72,1.58l1-.08c-.12-.29-.24-.58-.36-.83l.48-.19a13.36,13.36,0,0,1,.72,1.62Z"
          fill="#002a5e"
        />
        <path
          d="M285,140.18l-3.36.86-.23-.92,1.5-.32v-3.09h-1.33v-.83h1.33v-1h-.81v-.55a3.82,3.82,0,0,1-.45.45,5.06,5.06,0,0,0-.39-.88,7.52,7.52,0,0,0,1.91-2.65l.77.26a3.22,3.22,0,0,1-.23.5,7.79,7.79,0,0,1,1.43,1.32l-.38.79a8.21,8.21,0,0,0-1.46-1.4,9.57,9.57,0,0,1-.95,1.35h2.24v.81h-.93v1h1.21v.83h-1.21v2.94l1.25-.3Zm-2.7-2.93a13.9,13.9,0,0,1,.39,2l-.63.19a13,13,0,0,0-.34-2Zm1.47,1.86c.14-.54.3-1.41.38-2l.69.22c-.18.66-.36,1.44-.51,1.95Zm3.26-2.56a12.71,12.71,0,0,1-.5,2.46c.54,1.17,1.51,1.38,2.8,1.38.65,0,1.59,0,2.24-.1a3,3,0,0,0-.28.89c-.58,0-1.33,0-2,0-1.44,0-2.43-.23-3.09-1.31a5.45,5.45,0,0,1-1,1.51,3.09,3.09,0,0,0-.61-.64,6.47,6.47,0,0,0,1.47-3.54h-.32a.93.93,0,0,0-.58.17,6.5,6.5,0,0,0-.32-.81.66.66,0,0,0,.42-.33,14.67,14.67,0,0,0,.63-1.61H285v-.87h1.25l.21-.09.6.17c-.24.6-.65,1.75-1,2.52h.47l.15,0Zm-1-3.14a13.18,13.18,0,0,0-.91-1.72l.77-.28a14.47,14.47,0,0,1,.92,1.68Zm2.66-.38H287v-.81h1.66v-.95h.87v.95h1.6V133h-1.6v.72h1.42v3.52h-1.4V138h1.62v.81h-1.62v1.28h-.86v-1.28H287V138h1.72v-.76h-1.44v-3.52h1.41Zm-.7,2.15h.85v-.77H288Zm0,1.42h.85v-.78H288Zm2.27-2.19h-.83v.77h.83Zm0,1.41h-.83v.78h.83Z"
          fill="#002a5e"
        />
        <polyline
          points="274.53 112.75 261.25 112.75 256.42 110.13"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <polyline
          points="274.61 118.82 261.33 118.82 256.5 121.45"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="274.6"
          y1="115.88"
          x2="252.14"
          y2="115.88"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="252.14"
          cy="115.88"
          r="1.86"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="256.73"
          cy="121.23"
          r="1.86"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="256.42"
          cy="110.13"
          r="1.86"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <polyline
          points="276.29 112.75 289.57 112.75 294.39 110.13"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <polyline
          points="276.21 118.82 289.49 118.82 294.31 121.45"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="276.21"
          y1="115.88"
          x2="298.68"
          y2="115.88"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="298.68"
          cy="115.88"
          r="1.86"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="294.09"
          cy="121.23"
          r="1.86"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="294.39"
          cy="110.13"
          r="1.86"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <polyline
          points="272.37 106.15 272.37 92.87 269.75 88.05"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <polyline
          points="278.44 106.23 278.44 92.95 281.06 88.13"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="275.5"
          y1="106.23"
          x2="275.5"
          y2="83.76"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="275.5"
          cy="83.76"
          r="1.86"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="280.85"
          cy="88.35"
          r="1.86"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="269.75"
          cy="88.05"
          r="1.86"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M283.82,106.6h-2.3a6.24,6.24,0,0,0-12.48,0h-2.3a8.54,8.54,0,0,1,17.08,0Z"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="265.34"
          y="106.1"
          width="19.92"
          height="18.72"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle cx="275.41" cy="116.06" r="1.87" fill="#002a5e" />
        <rect x="274.83" y="116.06" width="1.16" height="8.76" fill="#002a5e" />
        <circle
          cx="239.08"
          cy="238.91"
          r="29.23"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M219.52,248.3a10.71,10.71,0,0,1-1.13,5.36,3.22,3.22,0,0,0-.82-.56,9.26,9.26,0,0,0,1-4.81v-2.55H222v-2.12h1v.77h3.71v.77H223v.58h3.51l.16-.05.72.19a12.91,12.91,0,0,1-.62,1.77l-.86-.24a8.16,8.16,0,0,0,.28-.87h-3.29v.67l2.69-.22,0,.7-2.74.23V248c0,.3.07.42.45.42h2.32a7.08,7.08,0,0,0,1.2-.08c0,.27.06.57.09.79a7.68,7.68,0,0,1-1.21.06H223.3c-1.07,0-1.35-.34-1.35-1.19l-1.91.16-.06-.71,2-.17v-.75h-2.43Zm5.16,4.22h2.73v.87h-8.1v-.87h2.61v-.74h-1.81v-2.07h.8V251h1v-1.47h.87v3h1v-3h.88V251h1v-1.31h.81v2.07h-1.84Z"
          fill="#002a5e"
        />
        <path
          d="M233.41,250.84c0,.13,0,.25-.08.37a7.65,7.65,0,0,1,1,1l-.54.67A7.13,7.13,0,0,0,233,252a4.69,4.69,0,0,1-1.52,1.71,3.29,3.29,0,0,0-.59-.59,3.53,3.53,0,0,0,1.66-2.3h-1.4V250h1.5c0-.12,0-.24,0-.34v-.79h-.38a5.39,5.39,0,0,1-.51,1,6.31,6.31,0,0,0-.66-.44c.08-.13.17-.28.25-.43l-.86.33v3.22c0,.5-.1.76-.36.91a3,3,0,0,1-1.36.18,3.1,3.1,0,0,0-.26-.9c.41,0,.79,0,.9,0s.18,0,.18-.18v-2.88l-1,.35-.26-1c.35-.11.77-.26,1.22-.42v-2h-1.06v-1h1.06v-2.14h.9v2.14h.79v1h-.79v1.67l.8-.3.16.77a7.29,7.29,0,0,0,.48-1.68l.77.16c-.05.26-.1.52-.17.77h1.88v.8h-.94v.8c0,.1,0,.21,0,.33h1.07v.81Zm-.77-3.75c-.87,0-1.08-.33-1.08-1.2v-2.26h.85v1h1.84v.76h-1.84v.56c0,.32.05.42.26.42h1.13a4.65,4.65,0,0,0,.66,0,6.08,6.08,0,0,0,.09.76,3.06,3.06,0,0,1-.73.06Zm4.32,5.6a2.38,2.38,0,0,0,.61.06h1.18a2.92,2.92,0,0,0-.25.81h-1a2.23,2.23,0,0,1-2.26-1.28,4.38,4.38,0,0,1-.77,1.47,2.88,2.88,0,0,0-.65-.48c.83-.91,1-2.34,1.07-4.1l.78,0a15.41,15.41,0,0,1-.17,1.92,2.72,2.72,0,0,0,.64,1.16v-3.91h-1.57v-.83h2.61a16.72,16.72,0,0,0-2.21-1.66l.58-.56c.25.16.52.32.8.51a8.51,8.51,0,0,0,.8-1.07h-2.43V244h3l.16-.06.58.4a9.7,9.7,0,0,1-1.43,2,7.7,7.7,0,0,1,.79.64l-.58.62h.69l.12,0,.5.13c-.12.66-.28,1.44-.43,1.94l-.62-.17a9.21,9.21,0,0,0,.2-1H237V250h1.37v.82H237Z"
          fill="#002a5e"
        />
        <path
          d="M249.54,246.67v.75h-1.89l-.09.93h-6.29l.12-.93h-1.74v-.75h1.84l.11-.88h6.2l-.09.88Zm-3.37,5.55c1.12.29,2.27.64,3,.89l-.7.65c-.77-.3-2.06-.73-3.32-1.07l.43-.47h-2.23l.65.44a13,13,0,0,1-3.63,1.09,8,8,0,0,0-.55-.71,13.53,13.53,0,0,0,3.14-.82H241.1v-3.48h7v3.48Zm-5.26-5.87H240v-1.81h4.11c-.12-.26-.27-.53-.4-.75l.88-.25a4.61,4.61,0,0,1,.59,1h4v1.81h-1v-1h-7.31Zm1.18,3.37h5v-.42h-5Zm0,1h5v-.43h-5Zm0,1h5v-.44h-5Zm.16-3.86H244c0-.16,0-.31.05-.48H242.3Zm.18-1.45-.05.42h1.78l0-.42Zm4.25,1.45,0-.48H245c0,.17,0,.33,0,.48Zm.12-1.45h-1.75l0,.42h1.76Z"
          fill="#002a5e"
        />
        <path
          d="M254.06,251c-1.12.46-2.32.95-3.27,1.31l-.34-1c.38-.13.83-.27,1.32-.45v-3.63h-1.14v-1h1.14v-2.5h1v2.5h1v1h-1v3.27l1.08-.39Zm5.16,1.74a1.33,1.33,0,0,0,.32,0,.34.34,0,0,0,.15-.23,5.1,5.1,0,0,0,.06-.88,2.54,2.54,0,0,0,.8.42,4.41,4.41,0,0,1-.14,1,.71.71,0,0,1-.43.37,2.05,2.05,0,0,1-.67.09h-.73a1.3,1.3,0,0,1-.78-.22c-.2-.15-.27-.35-.27-1v-1.49h-.9c-.19,1.51-.74,2.37-2.78,2.88a2.33,2.33,0,0,0-.53-.8c1.75-.35,2.18-.94,2.34-2.08h-1v-3.19h5.08v3.19h-1.23v1.55c0,.15,0,.25.08.28a.33.33,0,0,0,.24.06Zm1-5.56h-6.12v-.86h1.45c-.08-.27-.19-.62-.3-.91l.83-.18a6.55,6.55,0,0,1,.37,1l-.21,0h1.55c.13-.34.31-.75.4-1.07l.93.18c-.15.31-.28.62-.42.89h1.52Zm-.28-2h-5.52v-.85h2.26a4.45,4.45,0,0,0-.24-.62l.95-.19a6.39,6.39,0,0,1,.35.81H260ZM255.56,249h3.16v-.58h-3.16Zm0,1.26h3.16v-.62h-3.16Z"
          fill="#002a5e"
        />
        <ellipse
          cx="231.48"
          cy="220.85"
          rx="21.43"
          ry="5.76"
          transform="translate(-88.37 228.37) rotate(-45)"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <ellipse
          cx="232.56"
          cy="220.85"
          rx="5.76"
          ry="21.43"
          transform="translate(-88.05 229.13) rotate(-45)"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="222.5"
          cy="206.52"
          r="1.24"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="221.65"
          cy="217.42"
          r="1.24"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="217.68"
          cy="229.27"
          r="1.24"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="223.74"
          cy="234.33"
          r="1.24"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="240.42"
          cy="234.66"
          r="1.24"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="246.19"
          cy="205.26"
          r="1.26"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="244.45"
          cy="213.84"
          r="1.24"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="229.31"
          y="222.65"
          width="3.62"
          height="5.88"
          rx="0.7"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="250.39"
          y="222.65"
          width="3.62"
          height="5.88"
          rx="0.7"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M247.91,223.07H235.25a1.89,1.89,0,0,1-1.83-2.37l.4-1.5a1.88,1.88,0,0,1,1.82-1.4h11.87a1.88,1.88,0,0,1,1.83,1.4l.39,1.5A1.88,1.88,0,0,1,247.91,223.07Z"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M252.17,222.08v7.36a1.36,1.36,0,0,1-1.36,1.36H244a1.36,1.36,0,0,1-1.18-.68h0a1.36,1.36,0,0,0-2.36,0h0a1.36,1.36,0,0,1-1.18.68h-6.8a1.36,1.36,0,0,1-1.36-1.36v-7.36a1.36,1.36,0,0,1,1.36-1.36h18.33A1.36,1.36,0,0,1,252.17,222.08Z"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <line
          x1="250.39"
          y1="224.28"
          x2="250.39"
          y2="222.93"
          fill="none"
          stroke="#002a5e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.5"
        />
        <path
          d="M245.07,229.27h4.26a1.07,1.07,0,0,0,1.06-1.06v-1.92"
          fill="none"
          stroke="#002a5e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.5"
        />
        <rect
          x="252.2"
          y="209.79"
          width="2.36"
          height="6.54"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="255.71"
          y="218.66"
          width="2.39"
          height="3.99"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="266.1"
          y="226.29"
          width="2.39"
          height="4.8"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="260.39"
          y="215.08"
          width="3.33"
          height="12.7"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="255.99"
          y="229.1"
          width="2.65"
          height="5.4"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="165.21"
          cy="37.72"
          r="29.23"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M153.29,49.9c0,.58-.12.9-.53,1.06a6,6,0,0,1-2,.18,4,4,0,0,0-.36-1c.67,0,1.38,0,1.58,0s.26-.07.26-.23V47.71a4.13,4.13,0,0,0-.72.88A5.49,5.49,0,0,1,149,45.3a5.3,5.3,0,0,1-2.67,3.35,4,4,0,0,0-.63-.78v3.28h-1V43.48h3.94c-.05-.34-.08-.7-.11-1.06h-1.91v-.91h2.8a14.41,14.41,0,0,0,.2,2h3.75Zm-7.64-2.09a4.76,4.76,0,0,0,2.54-3.33h-2.54Zm4.11-3.33a4.62,4.62,0,0,0,2.49,3.22V44.48Z"
          fill="#002a5e"
        />
        <path
          d="M160.68,44.68c-.07.1-.12.2-.19.3A11.18,11.18,0,0,0,165,47.8a3,3,0,0,0-.65.85c-.45-.19-.88-.4-1.29-.62v3.09h-1v-.31h-4.16v.34h-1v-3a11.59,11.59,0,0,1-1.33.64,3.9,3.9,0,0,0-.65-.83,9.71,9.71,0,0,0,4.94-3.65Zm-4.35-.54h-1V42h4.15a8.63,8.63,0,0,0-.37-.81l1-.24a6.4,6.4,0,0,1,.52,1h3.83v2.13h-1V42.93h-7.14Zm2.66-.49a9.33,9.33,0,0,1-2.82,2.43,7.11,7.11,0,0,0-.71-.77,7.53,7.53,0,0,0,2.57-2Zm3,6.26V48.37h-4.16v1.54Zm.1-2.44a10.94,10.94,0,0,1-2.15-1.8,10.39,10.39,0,0,1-2,1.8Zm-.65-4.13a15.4,15.4,0,0,1,2.8,2.06l-.73.67a16,16,0,0,0-2.78-2.16Z"
          fill="#002a5e"
        />
        <path
          d="M175.34,46.18v3.65h-1.1V49.4h-2.8v1.75h-1.1V49.4h-2.72v.44h-1.07V46.18h3.79v-.8H167V42.3h3.35V41.05h1.1V42.3h3.43v3.08h-3.43v.8Zm-5,2.31V47.13h-2.72v1.36Zm0-4V43.19H168v1.29Zm1.1-1.29v1.29h2.32V43.19Zm2.8,5.3V47.13h-2.8v1.36Z"
          fill="#002a5e"
        />
        <path
          d="M178.88,46.14a7.48,7.48,0,0,0-2-1.21l.54-.78a9.38,9.38,0,0,1,2.05,1.12Zm1.06,1.38c-.58,1.15-1.31,2.46-2,3.5l-.86-.69a32.9,32.9,0,0,0,2.07-3.49Zm-.7-4.25a8.19,8.19,0,0,0-1.88-1.34l.57-.75a8.22,8.22,0,0,1,1.92,1.28Zm1.65,3h.92v1.11a4.35,4.35,0,0,1-1.58,3.75,3.26,3.26,0,0,0-.72-.67,3.54,3.54,0,0,0,1.38-3.11Zm5.19,0a9.11,9.11,0,0,0-.53-.75c-4.28.28-4.81.33-5.16.47a9.2,9.2,0,0,0-.33-1c.24,0,.5-.31.82-.69a11.68,11.68,0,0,0,.74-1H180v-.92h2.29a15.6,15.6,0,0,0,.77-1.38l1,.4c-.18.33-.39.66-.59,1h3.33v.92h-4c-.41.55-.81,1.06-1.22,1.52l3.36-.2c-.25-.28-.48-.57-.68-.83l.77-.44c.62.72,1.47,1.72,1.89,2.32Zm-3.29.08h.9v4.35h-.9Zm3.56,3.79a1.68,1.68,0,0,0,.5-.07,6,6,0,0,0,.08.79,1.66,1.66,0,0,1-.56.07h-.65c-.86,0-1-.36-1-1.24V46.25h.95v3.44c0,.32,0,.43.16.43Z"
          fill="#002a5e"
        />
        <rect
          x="161.17"
          y="28.04"
          width="6.55"
          height="3.55"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="149.2"
          y="8.74"
          width="30.78"
          height="20.55"
          rx="1.14"
          fill="#fff"
          stroke="#002a5e"
        />
        <path
          d="M157.83,34.59a1.26,1.26,0,0,1,0-2.52h13.52a1.26,1.26,0,1,1,0,2.52Z"
          fill="#002a5e"
        />
        <path
          d="M171.35,32.32a1,1,0,1,1,0,2H157.83a1,1,0,0,1,0-2h13.52m0-.5H157.83a1.51,1.51,0,0,0,0,3h13.52a1.51,1.51,0,1,0,0-3Z"
          fill="#002a5e"
        />
        <rect
          x="149.2"
          y="25.48"
          width="30.78"
          height="3.81"
          rx="0.49"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="164.59"
          cy="17.99"
          r="5.14"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <polygon
          points="166.84 18 163.14 15.86 163.14 20.13 166.84 18"
          fill="#fff"
        />
        <rect
          x="178.31"
          y="13.56"
          width="14.7"
          height="24.68"
          rx="2.89"
          transform="translate(25.19 -71) rotate(23.19)"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="180.36"
          y="15.87"
          width="11.92"
          height="16.97"
          transform="translate(24.64 -71.39) rotate(23.19)"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="181.96"
          cy="34.53"
          r="1.58"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <polygon
          points="187.86 25.01 186.13 22.7 185 25.35 187.86 25.01"
          fill="#002a5e"
        />
        <circle
          cx="186.33"
          cy="24.36"
          r="3.1"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="140.56"
          y="12.16"
          width="11.04"
          height="21.97"
          rx="3.19"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="146.08"
          cy="22.89"
          r="2.87"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle cx="146.08" cy="22.89" r="0.75" fill="#002a5e" />
        <circle cx="143.38" cy="14.9" r="0.79" fill="#002a5e" />
        <circle cx="146.08" cy="14.9" r="0.79" fill="#002a5e" />
        <circle cx="148.77" cy="14.9" r="0.79" fill="#002a5e" />
        <circle cx="143.38" cy="17.61" r="0.79" fill="#002a5e" />
        <circle cx="146.08" cy="17.61" r="0.79" fill="#002a5e" />
        <circle cx="148.77" cy="17.61" r="0.79" fill="#002a5e" />
        <circle cx="143.38" cy="28.17" r="0.79" fill="#002a5e" />
        <circle cx="146.08" cy="28.17" r="0.79" fill="#002a5e" />
        <circle cx="148.77" cy="28.17" r="0.79" fill="#002a5e" />
        <circle cx="143.38" cy="30.94" r="0.79" fill="#002a5e" />
        <circle cx="146.08" cy="30.94" r="0.79" fill="#002a5e" />
        <circle cx="148.77" cy="30.94" r="0.79" fill="#002a5e" />
        <circle
          cx="146.02"
          cy="9.32"
          r="1.56"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M143.6,6.89a3.45,3.45,0,0,1,2.43-1,3.38,3.38,0,0,1,2.42,1"
          fill="none"
          stroke="#002a5e"
          strokeLinecap="round"
          strokeWidth="0.5"
        />
        <path
          d="M142.2,5.49a5.44,5.44,0,0,1,7.65,0"
          fill="none"
          stroke="#002a5e"
          strokeLinecap="round"
          strokeWidth="0.5"
        />
        <path
          d="M140.76,4A7.46,7.46,0,0,1,151.3,4"
          fill="none"
          stroke="#002a5e"
          strokeLinecap="round"
          strokeWidth="0.5"
        />
        <circle
          cx="54.38"
          cy="122.98"
          r="29.23"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M47.31,140.21c.41,0,.47-.28.52-1.91a3.55,3.55,0,0,0,1,.44c-.11,1.9-.37,2.44-1.39,2.44H45.92c-1.09,0-1.37-.34-1.37-1.42v-3.67H42.67c-.2,2.32-.7,4.22-3.28,5.25a2.77,2.77,0,0,0-.64-.82c2.3-.86,2.67-2.5,2.83-4.43H38.93v-1H48.6v1h-3v3.66c0,.4.07.46.47.46Zm.33-7.14H39.89v-1h7.75Z"
          fill="#002a5e"
        />
        <path
          d="M51,134.84H50v-2.45h4.19a6,6,0,0,0-.38-.81l1-.29a9.33,9.33,0,0,1,.54,1.1h4v2.45H58.37v-1.5H51Zm8.41,3h-4.1v2.19c0,.62-.17.9-.62,1.06a7.15,7.15,0,0,1-2.11.17,3.63,3.63,0,0,0-.43-1c.75,0,1.55,0,1.78,0s.29-.05.29-.24v-2.18h-4.2v-1h4.2V135.4H51.6v-.94h6.22v.94H55.35v1.51h4.1Z"
          fill="#002a5e"
        />
        <path
          d="M70.3,132.47v2.43h-1v-1.49h-7v1.49h-1v-2.43h4.09a7.35,7.35,0,0,0-.46-1l1.08-.32a10.29,10.29,0,0,1,.63,1.3Zm-4.07,2.93h3.31v5.94h-1v-.47H63v.47H62V135.4h3.19v-1.56h1Zm-3.21.94v1.31h2.2v-1.31Zm0,3.59h2.2v-1.4H63Zm3.21-3.59v1.31h2.28v-1.31Zm2.28,3.59v-1.4H66.23v1.4Z"
          fill="#002a5e"
        />
        <polyline
          points="40.76 90.54 20.48 104.16 27.2 120.92 89.78 110.57 40.76 90.54 71.23 122.61 27.2 120.92 15.89 127.5"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <polyline
          points="87.7 108.1 91.33 128.47 69.92 122.61"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="87.7"
          y="124.51"
          width="5.99"
          height="5.99"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="23.84"
          y="114.97"
          width="8"
          height="8"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="86.33"
          y="107.88"
          width="4.54"
          height="4.54"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="65.76"
          y="118.33"
          width="6.85"
          height="6.85"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="37.82"
          y="87.15"
          width="5.89"
          height="5.89"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="14.6"
          y="124.94"
          width="4.71"
          height="4.71"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <rect
          x="17.47"
          y="101.15"
          width="6.03"
          height="6.03"
          fill="#002a5e"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M54,104.28a48.66,48.66,0,0,1-5.9,5.3,49,49,0,0,1-6.61,4.4,8.24,8.24,0,0,1-3-3.91,7.23,7.23,0,0,1-.37-1.25A8.29,8.29,0,0,1,51,100.37,8.08,8.08,0,0,1,54,104.28Z"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M54.39,105.53a8.29,8.29,0,0,1-6.5,9.78A8.2,8.2,0,0,1,41.51,114a49,49,0,0,0,6.61-4.4,48.66,48.66,0,0,0,5.9-5.3A6.45,6.45,0,0,1,54.39,105.53Z"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M38.49,110.07a8.24,8.24,0,0,0,3,3.91c-3,1.63-5.31,2.27-5.94,1.46S36.14,112.57,38.49,110.07Z"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M54,104.28a8.08,8.08,0,0,0-3-3.91c3-1.63,5.32-2.27,6-1.47S56.37,101.78,54,104.28Z"
          fill="none"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="53.08"
          cy="121.76"
          r="1.37"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <path
          d="M77.92,105.45c-.13.17-.28.35-.43.51a5.94,5.94,0,0,1-.52.49A6.53,6.53,0,0,1,73.52,108h0a6.36,6.36,0,0,1-.86.07,6.66,6.66,0,0,1-5.78-10,3.56,3.56,0,0,1,.31-.48l.13-.18a4.56,4.56,0,0,1,.69-.76,2.47,2.47,0,0,1,.45-.4,6.34,6.34,0,0,1,2.89-1.35A6.65,6.65,0,0,1,78.75,104a2.57,2.57,0,0,1-.25.53A5.73,5.73,0,0,1,77.92,105.45Z"
          fill="#002a5e"
        />
        <path
          d="M70.34,98.06A23.59,23.59,0,0,0,68,96.63a2.47,2.47,0,0,1,.45-.4,22.08,22.08,0,0,1,2.21,1.34c2.28,1.56,4.5,3,5.38,2.7.13,0,.3-.12.43-.45S76.37,98.71,73,97A6.88,6.88,0,0,1,70.71,95a6.42,6.42,0,0,1,.63-.15,6.8,6.8,0,0,0,1.92,1.55c3.23,1.68,4.18,2.59,3.75,3.63a1.31,1.31,0,0,1-.8.78C75.27,101.11,73.74,100.38,70.34,98.06Z"
          fill="#fff"
        />
        <path
          d="M75.93,103.33a47.16,47.16,0,0,1-9.06-5.28,3.56,3.56,0,0,1,.31-.48,48.16,48.16,0,0,0,9,5.24c.21.09.47.21.76.32.55.24,1.2.52,1.81.86a2.57,2.57,0,0,1-.25.53,17.58,17.58,0,0,0-1.79-.84C76.41,103.55,76.14,103.44,75.93,103.33Z"
          fill="#fff"
        />
        <path
          d="M68.55,102.53c-.25.26-.19.88.43,1.57A46.64,46.64,0,0,0,73.51,108a6.36,6.36,0,0,1-.86.07,42.74,42.74,0,0,1-4.11-3.6c-.9-1-.84-1.93-.39-2.36s1.55-.58,3,.73A13.15,13.15,0,0,0,77.49,106a5.94,5.94,0,0,1-.52.49,13.45,13.45,0,0,1-6.2-3.16C69.69,102.32,68.86,102.22,68.55,102.53Z"
          fill="#fff"
        />
        <circle
          cx="30.47"
          cy="97.57"
          r="1.37"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
        <circle
          cx="89.78"
          cy="119.71"
          r="1.37"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="0.5"
        />
      </g>
    </SVG>
  );
};

export default RadarCorner;
