import React from "react";
import SVG from "../../../components/SVG";

const Chart23 = () => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1121.03 458.45">
      <g>
        <ellipse
          cx="690.79"
          cy="203.01"
          rx="197.29"
          ry="87.91"
          transform="translate(-20.02 88.31) rotate(-7.21)"
          fill="none"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <circle
          cx="588.3"
          cy="284.67"
          r="42.15"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <ellipse
          cx="694.54"
          cy="218.03"
          rx="320.69"
          ry="166.33"
          transform="translate(-21.18 85.13) rotate(-6.91)"
          fill="none"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <circle
          cx="453.99"
          cy="337.48"
          r="34.8"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <circle
          cx="727.43"
          cy="291.7"
          r="42.15"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <circle
          cx="503.99"
          cy="203.12"
          r="42.15"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <circle
          cx="865.14"
          cy="139.58"
          r="42.15"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <path
          d="M855,124.83c-.52-.43-1.69-1.28-2.55-1.87a7.84,7.84,0,0,1-3.46,2.72,5.3,5.3,0,0,0-1.06-1.12,5.56,5.56,0,0,0,3.44-2.86h-3.26v-1.34h1.22a10.42,10.42,0,0,0-1.28-.75,8.42,8.42,0,0,0,2-3.55l1.5.31c-.13.41-.27.79-.42,1.19h4.67v1.33h-2.49v.93a4.87,4.87,0,0,1,0,.54h3v1.34H853l-.05.16c.74.36,2.72,1.53,3.2,1.87Zm-3.31-4.47a5.15,5.15,0,0,0,0-.55v-.92H850.4a7.27,7.27,0,0,1-1,1.47Zm-1.08,4.86h11.29v7.41h-1.71V132h-7.93v.63h-1.65Zm1.65,1.34V128h7.93v-1.4Zm7.93,4.1v-1.44h-7.93v1.44Zm3.22-13.12V124h-6.5v-6.42ZM861.7,119h-3.27v3.48h3.27Z"
          fill="#fff"
        />
        <path
          d="M869.53,128.83a11.26,11.26,0,0,1-2.45,3.85,13.5,13.5,0,0,0-1.33-1,7.92,7.92,0,0,0,2.18-3.26Zm3.66-.69h-6.43V116.82h6.43Zm-1.64-10h-3.21v2h3.21Zm0,3.28h-3.21v2h3.21Zm0,3.28h-3.21v2.05h3.21Zm.23,3.67a13.47,13.47,0,0,1,2,2.86l-1.43.74a14.7,14.7,0,0,0-1.89-3Zm10.66-6.5H880.3v8.75c0,.9-.22,1.39-.76,1.68a7.29,7.29,0,0,1-2.73.32,6.39,6.39,0,0,0-.6-1.64c.94,0,1.78,0,2,0s.39-.09.39-.38v-6.61a17.44,17.44,0,0,1-4.27,5.77,7.28,7.28,0,0,0-1.15-1.21,16.06,16.06,0,0,0,4.81-6.7h-4.34v-1.56h4.95v-4.16h1.66v4.16h2.14Z"
          fill="#fff"
        />
        <path
          d="M864.26,151.72H860.1v2.21c0,.8-.16,1.23-.81,1.46a10.22,10.22,0,0,1-2.95.22,5.81,5.81,0,0,0-.52-1.44c1,0,2,0,2.28,0s.36-.09.36-.31v-2.16h-6a14.24,14.24,0,0,1,2.44,2l-1.22,1.08a15.25,15.25,0,0,0-2.83-2.34l.81-.7H848v-1.35h10.42v-.92c-3.6.09-7.09.16-9.65.2l0-1.26c1.73,0,4,0,6.53,0v-.84h-5.36v-5h5.36v-.85h-6.75v-1.29h6.75v-1.26h1.64v1.26h6.65v1.29h-6.65v.85h5.55v5H860.8a14,14,0,0,1,2.79,1.62l-1,.9a9.08,9.08,0,0,0-.93-.67l-1.57,0v1h4.16Zm-12.75-7.22h3.78v-.9h-3.78Zm3.78,2v-.93h-3.78v.93Zm1.64-2.86v.9h3.91v-.9Zm3.91,1.93h-3.91v.93h3.91Zm-3.91,2.82,2.9,0a10.38,10.38,0,0,0-1.1-.47l.42-.34h-2.22Z"
          fill="#fff"
        />
        <path
          d="M871.31,144.43h3.26V146h-3.26v1.19c.89.64,2.78,2.26,3.26,2.7l-1,1.42c-.51-.58-1.46-1.57-2.31-2.38v6.68h-1.64v-7.15a18.33,18.33,0,0,1-3.11,4.81,11,11,0,0,0-.88-1.62,17.61,17.61,0,0,0,3.65-5.65h-3.26v-1.55h3.6v-2.68c-1.09.21-2.21.39-3.25.52a5.17,5.17,0,0,0-.51-1.3,33.07,33.07,0,0,0,7.33-1.78l1.21,1.28a22.3,22.3,0,0,1-3.14.93Zm5.94,6.71h-1.62v-9.9h1.62Zm4.36-11.7V153.5c0,1.05-.25,1.53-.88,1.82a11,11,0,0,1-3.38.31,8.29,8.29,0,0,0-.6-1.73c1.23,0,2.36,0,2.72,0s.47-.1.47-.43V139.44Z"
          fill="#fff"
        />
        <path
          d="M503.28,197.74v1.57h-9.56v-1.57h1.22V186.13H494v1.15h-2.23v2.65c0,.23,0,.46,0,.72h2.54v1.54h-2.65c-.05.45-.13.92-.22,1.39.74.76,2.36,2.7,2.79,3.22l-1.06,1.37a35.19,35.19,0,0,0-2.16-2.95,9.93,9.93,0,0,1-3.06,4.66,5.32,5.32,0,0,0-1.17-1.15,9.38,9.38,0,0,0,3.28-6.54h-3.21v-1.54h3.3c0-.26,0-.51,0-.76v-2.61h-.93a14.6,14.6,0,0,1-1.08,2.75,9.06,9.06,0,0,0-1.35-.9,15.49,15.49,0,0,0,1.62-5.67l1.57.27c-.11.67-.22,1.35-.37,2H494v-1.15h9.1v1.55h-6.47v2.43h5.85v6.25h-5.85v2.93Zm-6.7-7.62v3.14h4.25v-3.14Z"
          fill="#fff"
        />
        <path
          d="M510.8,184.81c-.49,1.53-1.13,3.41-1.69,4.83a5.49,5.49,0,0,1,1.49,3.76,2.51,2.51,0,0,1-.75,2.16,2,2,0,0,1-1,.38,8.11,8.11,0,0,1-1.22,0,3.4,3.4,0,0,0-.4-1.5,5.1,5.1,0,0,0,.94,0,1,1,0,0,0,.54-.14,1.43,1.43,0,0,0,.34-1.14,4.92,4.92,0,0,0-1.46-3.36c.45-1.25.92-2.88,1.26-4.11H507V200h-1.48V184.22h4l.27-.06Zm10.46,12.68h-4.64V200H515v-2.5h-4.74V196H515V194.5h-3.8V188H515v-1.44h-4.2V185.1H515v-1.64h1.62v1.64H521v1.46h-4.37V188h3.94v6.5h-3.94V196h4.64Zm-8.53-6.84h2.37v-1.39h-2.37Zm0,2.59h2.37v-1.41h-2.37Zm6.23-4h-2.43v1.39H519Zm0,2.57h-2.43v1.41H519Z"
          fill="#fff"
        />
        <path
          d="M502,211.34a20.32,20.32,0,0,1-1.74,6.77,7.85,7.85,0,0,0,3.22,3.48,5.91,5.91,0,0,0-1.06,1.31,8.68,8.68,0,0,1-3-3.33,10.72,10.72,0,0,1-4,3.44,6.63,6.63,0,0,0-.59-1l-.49.43a18,18,0,0,0-2.23-1.08,15.54,15.54,0,0,1-4.73,1.55,4.2,4.2,0,0,0-.69-1.1,16.3,16.3,0,0,0,3.86-1.08c-.87-.3-1.73-.58-2.53-.79a16.38,16.38,0,0,0,1-1.48h-2.11V217.3h2.83c.18-.34.36-.66.52-1h-2.57v-3h3.26v-.69H488v-2h-1.22V209.4H488v-1.85h2.92v-1.12h1.29v1.12h3.1v1.85h1v1.24h-1v2h-3.1v.69h3.42v.86a25.45,25.45,0,0,0,2.4-7.74l1.4.27c-.19,1.06-.43,2.12-.7,3.17h4.54v1.47Zm-12.91,4h1.88v-1h-1.88Zm.26-6.65v.83h1.62v-.83Zm1.62,2.85v-1h-1.62v1Zm-.4,7c-.18.31-.38.6-.54.87.61.18,1.24.39,1.86.61a3.09,3.09,0,0,0,1.17-1.48Zm3.89,0a4.14,4.14,0,0,1-1.17,2,15.64,15.64,0,0,1,1.91.9,8.77,8.77,0,0,0,3.49-3.39,24.69,24.69,0,0,1-1.22-4.27,15.71,15.71,0,0,1-.87,1.79,8.83,8.83,0,0,0-.92-.87v1.62h-3.9c-.16.33-.34.65-.52,1h2v-.61h1.33v.61h1.65v1.19Zm-2.2-9.83v.83H494v-.83Zm1.77,2.85v-1h-1.77v1Zm-1.77,2.81v1h2v-1Zm6.09-3-.05.15a27,27,0,0,0,1.18,4.86,20.36,20.36,0,0,0,1.08-5Z"
          fill="#fff"
        />
        <path
          d="M505.13,207.29H514v1.55h-4.18c-.21.78-.47,1.59-.75,2.38h3.15l.28-.06,1,.35c-.94,5.87-3.42,9.59-6.72,11.48a5.63,5.63,0,0,0-1.26-1.22,10.8,10.8,0,0,0,4.27-4.12,29.92,29.92,0,0,0-3-2.07,9.93,9.93,0,0,1-1.24,1.47,10.2,10.2,0,0,0-1.17-1.1,15.12,15.12,0,0,0,3.62-7.11h-2.92Zm3.29,5.46a13.15,13.15,0,0,1-.77,1.55,28.59,28.59,0,0,1,2.9,1.91,16.91,16.91,0,0,0,1.08-3.46Zm7.78,5.49h-1.64v-9.78h1.64Zm4.29-11.47v14.12c0,1-.24,1.47-.87,1.76a10.07,10.07,0,0,1-3.27.31,7.63,7.63,0,0,0-.58-1.68c1.17,0,2.27,0,2.61,0s.45-.11.45-.43v-14.1Z"
          fill="#fff"
        />
        <path
          d="M574,274.34a16.55,16.55,0,0,1-1.82,3.62,9,9,0,0,0-1.15-.79,11.07,11.07,0,0,0,1.66-3.23Zm2.33-1.17v6.1h-1.63v-6.1h-3.45v-1.42h3.45v-1.64h-3.56V268.7h2a8.82,8.82,0,0,0-.63-2.25l1.37-.34a11,11,0,0,1,.67,2.22l-1.34.37H576a18.64,18.64,0,0,0,.88-2.61l1.55.33c-.31.79-.61,1.62-.92,2.28h2.11v1.41h-3.31v1.64h3.06v1.42Zm3-7.1h-7.92v-1.38h3.29c-.19-.51-.43-1.08-.64-1.53l1.65-.43c.31.61.67,1.36.9,2h2.72Zm-1.28,7.73a14.93,14.93,0,0,1,1.5,2.7l-1.17.7a19.64,19.64,0,0,0-1.44-2.79Zm9.47-3.42h-1.83v8.91h-1.64v-8.91H582v.43c0,2.59-.23,6.19-1.84,8.48a5.5,5.5,0,0,0-1.27-1c1.42-2.09,1.54-5.13,1.54-7.49v-6.23a27.34,27.34,0,0,0,5.51-1.46l1.32,1.23a30.59,30.59,0,0,1-5.26,1.33v3.15h5.58Z"
          fill="#fff"
        />
        <path
          d="M591.55,269.23v10.15h-1.64v-15.9h6.68v5.75Zm0-4.6v1.16H595v-1.16Zm3.47,3.43v-1.14h-3.47v1.14Zm5.46,8.87v1.95H599v-1.95h-6.51v-1.2h1.63v-4.51h-1.22V270h8.82v1.2h-1.2v4.51H602v1.2Zm-4.92-5.71v.83H599v-.83Zm0,2.65H599V273h-3.4Zm3.4,1.86v-.85h-3.4v.85Zm5.82,1.83c0,.83-.16,1.25-.67,1.5a6.32,6.32,0,0,1-2.46.29,5.25,5.25,0,0,0-.49-1.48c.7,0,1.46,0,1.67,0s.31-.09.31-.33v-8.33H598v-5.75h6.81Zm-5.2-12.93v1.16h3.56v-1.16Zm3.56,3.43v-1.14h-3.56v1.14Z"
          fill="#fff"
        />
        <path
          d="M582.3,288.52a1.68,1.68,0,0,1,0,.43c1,1,2.9,1.6,5.28,1.75a4.82,4.82,0,0,0-.91,1.37,8.82,8.82,0,0,1-4.82-2c-.65.82-2.07,1.6-5.17,2a4.68,4.68,0,0,0-.84-1.24h-4.58v-1.32h5.22v1.19c3.91-.5,4.32-1.51,4.32-2.28v-.24h-1.49a8.9,8.9,0,0,1-1.31,1.68,7.3,7.3,0,0,0-1.26-.85,7.51,7.51,0,0,0,2.07-3.31l1.49.32c-.11.31-.21.59-.34.9h5.62l.23-.07,1.15.38a16.68,16.68,0,0,1-1.56,2.66l-1.32-.47a11.84,11.84,0,0,0,.76-1.24h-2.5Zm-.15,10.91a47.21,47.21,0,0,1,5.08,2l-1.51.92a35.59,35.59,0,0,0-4.85-2l1-.85h-5.39l1.5.68a24.68,24.68,0,0,1-5.51,2.26,13.47,13.47,0,0,0-1.24-1.27,19.23,19.23,0,0,0,5.18-1.67h-2.9v-7.24H585.3v7.24ZM576,288.07H571.6v-1.26H576Zm-.83,6.21h8.45v-1h-8.45Zm0,2h8.45v-1h-8.45Zm0,2.07h8.45v-1.05h-8.45Z"
          fill="#fff"
        />
        <path
          d="M595.65,290.16h-6.48v-1.33h3.08a19.25,19.25,0,0,0-1.23-2.44l1.21-.66a18.13,18.13,0,0,1,1.24,2.36l-1.19.74h3.37Zm-5.76,2.37v-1.31H595v1.31Zm0,1.07H595v1.29h-5.13Zm5.13,7.7H591.2v.81h-1.31V296H595Zm-3.82-3.92V300h2.49v-2.57Zm12.59-10.59c-.13,6.66-.15,13.36.7,13.61a7.76,7.76,0,0,0,.29-2.34,7.71,7.71,0,0,0,.92,1.28c-.22,2.56-1,3.13-1.89,2.74-1.75-.61-1.71-6.65-1.7-13.7h-2.66v5.07h2.11V295h-2.11v7.35h-1.57V295h-2.23v-1.55h2.23v-5.07h-2v-1.59Z"
          fill="#fff"
        />
        <path
          d="M726,272.11V288h-1.62v-.67H712.49V288h-1.57V272.11Zm-1.62,13.79V273.52H712.49V285.9Zm-.68-8.15v1H713.21v-1h4.44v-.7h-2.8v-2.81H722v2.81h-2.9v.7Zm-9.42,1.64h8.3v5.71h-8.3Zm1.3,4.71h5.63v-3.74h-5.63Zm.64-8h4.34v-1h-4.34Zm.31,4.88h3.73v2.43h-3.73Zm1,1.64h1.75v-.85h-1.75Z"
          fill="#fff"
        />
        <path
          d="M733.55,272a25.79,25.79,0,0,1-1.77,3.9v12H730.2V278.5a17.56,17.56,0,0,1-1.33,1.66,9,9,0,0,0-.92-1.69,19.83,19.83,0,0,0,4-7Zm10.31,8.53c-.57.34-1.22.68-1.87,1a7.65,7.65,0,0,0,2.85,4.09,5.66,5.66,0,0,0-1.05,1.24,9.16,9.16,0,0,1-3-4.76c-.22.09-.44.2-.63.27.66,2,.41,4.05-.4,4.83a2.09,2.09,0,0,1-1.67.68l-1.16,0a3.4,3.4,0,0,0-.38-1.43,9.68,9.68,0,0,0,1.19.08.91.91,0,0,0,.87-.38,3,3,0,0,0,.41-2.2,17.46,17.46,0,0,1-5.56,3.21,5.48,5.48,0,0,0-1-1.16,15.45,15.45,0,0,0,6.2-3.43c-.09-.26-.22-.51-.34-.78a17.77,17.77,0,0,1-4.85,2.72,5.89,5.89,0,0,0-.9-1.06,15.11,15.11,0,0,0,5.13-2.59,3.94,3.94,0,0,0-.65-.71,17.74,17.74,0,0,1-3.74,2,5.22,5.22,0,0,0-.88-1.07,17.62,17.62,0,0,0,4-1.83h-2.61v-2.67c-.27.22-.52.43-.81.65a5.07,5.07,0,0,0-1-1.1,12.86,12.86,0,0,0,4-4.63l1.55.29a7.18,7.18,0,0,1-.54,1h3l.32-.08,1,.71a16.4,16.4,0,0,1-1.36,1.86h3.58v4h-5.3l-.17.16a6.33,6.33,0,0,1,1.58,1.93,26.23,26.23,0,0,0,3-1.82ZM736.19,274c-.3.4-.63.81-1,1.21h3a10.56,10.56,0,0,0,.9-1.21Zm-.84,4h2.52v-1.49h-2.52Zm6.68-1.49h-2.65V278H742Z"
          fill="#fff"
        />
        <path
          d="M714.86,303.2A16.81,16.81,0,0,1,714,307c1.09,1.93,3.24,2.21,6.42,2.21,1.89,0,4.63-.09,6.54-.27a5.48,5.48,0,0,0-.54,1.55c-1.75.11-4,.16-6,.16-3.48,0-5.64-.36-7.05-2.3a8.23,8.23,0,0,1-2.19,2.65,5.61,5.61,0,0,0-1.12-1.16,8.57,8.57,0,0,0,3.21-5.58h-1.52a2,2,0,0,0-1.08.27,8.55,8.55,0,0,0-.55-1.42,1.71,1.71,0,0,0,.88-.56,27.3,27.3,0,0,0,1.93-2.63h-2.54v-1.35h3.54l.24-.11,1.06.47c-.7,1-1.91,2.79-2.85,4h1.55l.27,0ZM713,298a20,20,0,0,0-2.12-2.88l1.27-.74a22,22,0,0,1,2.18,2.74Zm3.58.27h-1.4V295H726v3.29h-1.28v.6h-3.25v.9h3.78V305h-3.78v1h4.75v1.28h-4.75v1.33h-1.63V307.2h-4.5v-1.28h4.5v-1h-3.67V299.8h3.67v-.9h-3.24Zm.13-2v1.42h3.11v-.79h1.63v.79h2.93v-1.42Zm.81,5.58h2.3v-1h-2.3Zm2.3,2v-1h-2.3v1Zm1.63-3v1h2.34v-1Zm2.34,2h-2.34v1h2.34Z"
          fill="#fff"
        />
        <path
          d="M744.55,296.86h-2.77a7.44,7.44,0,0,1,1,1.21l-1,.45h.9v6.73H740.5v1.37h4V308h-4v2.95h-1.73V308h-4.45c-.45,1.14-1.6,2.22-4.23,3a6.52,6.52,0,0,0-1-1.24,5.67,5.67,0,0,0,3.38-1.8h-4v-1.35h4.41v-1.37h-2.3v-6.73h2.59a6.85,6.85,0,0,0-.68-1.23l1.26-.41h-2.49a15.08,15.08,0,0,1-2.09,2.48c-.23-.3-.77-1-1.08-1.31a10.49,10.49,0,0,0,2.81-3.74l1.53.48c-.12.29-.27.54-.41.83h4.57v1.26h-2.7a7.68,7.68,0,0,1,.76,1.24l-1.19.4h3.76a10.42,10.42,0,0,0-1.3-.7,8,8,0,0,0,2.53-3.53l1.6.39c-.13.33-.27.63-.42.94h4.9Zm-3.67,2.72h-8.61v.85h8.61Zm0,1.84h-8.61v.88h8.61Zm0,1.89h-8.61v.9h8.61Zm-2.11,1.94h-4.2v1.37h4.2Zm2.56-6.73a6.83,6.83,0,0,0-1-1.24l.92-.42H738.9a10.1,10.1,0,0,1-1.37,1.66Z"
          fill="#fff"
        />
        <path
          d="M453.13,329.24v2.11H436.94v-2.11h7v-3.62H439v-2.07h4.88v-3.17h-3.73a14.79,14.79,0,0,1-1.68,2.62,16,16,0,0,0-1.92-1.28,15.57,15.57,0,0,0,3.18-6.45l2.22.49c-.25.86-.54,1.71-.85,2.54h2.78v-3.24h2.25v3.24h6.06v2.08h-6.06v3.17h5.38v2.07h-5.38v3.62Z"
          fill="#002a5e"
        />
        <path
          d="M461.64,324.87c-.65.18-1.28.39-1.94.59v6.41h-2v-5.81c-1,.28-1.88.57-2.7.79l-.53-2c.9-.19,2-.48,3.23-.82v-3.44h-1.21a15.65,15.65,0,0,1-.67,2.3,15.77,15.77,0,0,0-1.51-1,22.71,22.71,0,0,0,1-5.8l1.78.31c-.07.72-.16,1.42-.27,2.14h.85v-3.45h2v3.45h1.49v2H459.7v2.9l1.66-.47Zm9.69-7s0,.72,0,1c-.34,8-.66,11-1.35,11.87a1.94,1.94,0,0,1-1.4.93,10.74,10.74,0,0,1-2,.06,5.91,5.91,0,0,0-.59-2.09,15,15,0,0,0,1.6.07.76.76,0,0,0,.67-.34c.45-.58.77-3,1.06-9.49h-.09c-.88,4.54-2.74,9.22-5.63,11.61a8.55,8.55,0,0,0-1.75-1.24c2.88-2.07,4.68-6.16,5.58-10.37h-.85c-.79,2.93-2.3,6.16-4.12,7.88a7.31,7.31,0,0,0-1.64-1.2c1.79-1.41,3.19-4.07,4-6.68h-.92A13.34,13.34,0,0,1,462,323a13.39,13.39,0,0,0-1.62-1.21,14.89,14.89,0,0,0,2.95-6.78l2,.36c-.18.82-.42,1.64-.67,2.43Z"
          fill="#002a5e"
        />
        <path
          d="M439,341.21c1,1,2.88,1.4,5.31,1.53a6.91,6.91,0,0,0-1.19,1.71,8.6,8.6,0,0,1-4.72-1.82c-.68.79-2.18,1.5-5.2,1.84a6.4,6.4,0,0,0-.72-1.17h-4.77v-1.64h5.43v1.1c3.44-.36,3.89-1.17,3.89-1.84v-.27H436a7.88,7.88,0,0,1-1.26,1.55,10.29,10.29,0,0,0-1.58-1.1,7.34,7.34,0,0,0,2.11-3.2l1.87.41c-.09.27-.2.54-.31.79h5.12l.32-.09,1.46.45a15.72,15.72,0,0,1-1.66,2.85l-1.66-.62a9.47,9.47,0,0,0,.64-1h-2v.56Zm.48,10.59c1.66.61,3.42,1.33,4.49,1.85l-1.93,1.15a34.21,34.21,0,0,0-4.68-2.12l1.1-.88h-5.08l1.57.72a23.92,23.92,0,0,1-5.62,2.34,13.89,13.89,0,0,0-1.59-1.57,20.37,20.37,0,0,0,4.92-1.49h-2.59v-7.31h12v7.31Zm-6.75-11.35h-4.61v-1.56h4.61Zm-.59,6.09H440v-.78h-7.79Zm0,2H440v-.78h-7.79Zm0,2H440v-.82h-7.79Z"
          fill="#002a5e"
        />
        <path
          d="M452.32,342.67h-6.61V341h2.86a15.31,15.31,0,0,0-1.06-2.26l1.53-.78a18.63,18.63,0,0,1,1.21,2.4l-1.1.64h3.17Zm-5.87,2.43v-1.62h5.22v1.62Zm0,.79h5.26v1.62h-5.26Zm5.26,7.94h-3.6v.76h-1.64v-6.22h5.24Zm-3.6-3.74v2H450v-2Zm12.53-11.17c-.16,5.93-.15,13.31.52,13.52a9.39,9.39,0,0,0,.23-2.28,10,10,0,0,0,1.12,1.73c-.27,2.63-1.19,3.18-2.3,2.71-1.72-.72-1.73-7-1.72-13.64h-2.17v4.75H458v2h-1.72v7.09h-2v-7.09h-2v-2h2V341h-1.77v-2Z"
          fill="#002a5e"
        />
        <path
          d="M480.11,351.53h-6.87v1.29c0,1-.22,1.42-1,1.71a10.39,10.39,0,0,1-3.08.27,6.81,6.81,0,0,0-.65-1.8c.85,0,1.93,0,2.2,0s.38,0,.38-.27v-1.22h-7.29v-1.7h7.29v-.52a13.47,13.47,0,0,0,2-1h-5.78v-1.5h7.83l.45-.11,1.21,1.1a25.27,25.27,0,0,1-3.44,2.07h6.73Zm-13.91-3.39h-2v-3.69h1.35l-.32-5.35a23.86,23.86,0,0,0,3.63-1l.89,1.25a17.66,17.66,0,0,1-2.47.68l0,.52h2.28v1.34h-2.21l0,.61h2.22v.92a13.67,13.67,0,0,0,1.26-.69c-.34-.21-.69-.41-1-.59l1-.85c.36.18.74.4,1.14.61a7,7,0,0,0,.7-.75l1.4.41a7.35,7.35,0,0,1-.95,1.08,9.23,9.23,0,0,1,1.1.83l-1.08.94a12.25,12.25,0,0,0-1-.89,12.41,12.41,0,0,1-1.46.94h5.84l.07-.63h-2.13v-1.33h2.25l0-.61h-2.2v-1.34h2.29l0-.59h-2.42V338.6h4.51c-.09,1.89-.24,4.17-.38,5.85h1.33v3.69h-2.13V346H466.2Zm1.29-3.69h2.92c-.2-.23-.54-.58-.81-.83v.2h-2.14Zm5.58-3.31a8.11,8.11,0,0,0-.93-.76,8.87,8.87,0,0,1-1.46.9,9,9,0,0,0-1.12-.95,8.09,8.09,0,0,0,1.37-.71l-1-.54,1-.82c.35.16.72.36,1.1.57a5.42,5.42,0,0,0,.65-.72l1.4.42a6.35,6.35,0,0,1-.9,1,10.81,10.81,0,0,1,.94.72Z"
          fill="#002a5e"
        />
        <circle
          cx="377.43"
          cy="224.07"
          r="34.8"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <path
          d="M358,225.51c-.64.18-1.27.4-1.94.6v6.41h-2V226.7c-1,.29-1.88.58-2.7.79l-.53-2c.9-.2,2-.49,3.23-.83v-3.44H352.9a15.8,15.8,0,0,1-.67,2.31,14.07,14.07,0,0,0-1.51-1,22.44,22.44,0,0,0,1-5.8l1.78.3c-.07.72-.16,1.43-.27,2.15h.85V215.7h2v3.46h1.49v2h-1.49v2.9l1.66-.47Zm9.69-7s0,.72,0,1c-.35,8-.67,11-1.36,11.87a2,2,0,0,1-1.4.94,10,10,0,0,1-2,.05,6.06,6.06,0,0,0-.6-2.09,15,15,0,0,0,1.6.07.76.76,0,0,0,.67-.34c.45-.57.77-3,1.06-9.49h-.09c-.88,4.54-2.73,9.22-5.63,11.62a8.22,8.22,0,0,0-1.75-1.25c2.88-2.07,4.68-6.15,5.58-10.37h-.84c-.8,2.94-2.31,6.16-4.13,7.89a7.66,7.66,0,0,0-1.64-1.21c1.79-1.4,3.19-4.07,4-6.68h-.92a13.42,13.42,0,0,1-1.85,3.23,11.58,11.58,0,0,0-1.62-1.21,14.93,14.93,0,0,0,2.95-6.79l2,.36c-.18.83-.42,1.64-.67,2.43Z"
          fill="#002a5e"
        />
        <path
          d="M375.19,228.9c-2,.7-4.15,1.38-5.87,1.94l-.49-2.18,2.2-.59v-4.23h-1.82v-2H371v-3.17h-2v-2H375v2h-1.93v3.17h1.62v2h-1.62v3.6c.6-.18,1.19-.36,1.77-.56Zm10.69,1.1v1.94H374.3V230h5v-1.71h-3.72v-1.93h3.72v-1.55h-3.6v-8.37h9.4v8.37h-3.62v1.55h3.82v1.93h-3.82V230Zm-8.28-10.23h1.85v-1.51H377.6Zm0,3.22h1.85v-1.55H377.6Zm5.44-4.73h-1.79v1.51H383Zm0,3.18h-1.79V223H383Z"
          fill="#002a5e"
        />
        <path
          d="M403.48,229.2h-6.87v1.3c0,.95-.22,1.42-1,1.71a10.39,10.39,0,0,1-3.08.27,6.88,6.88,0,0,0-.64-1.8c.84,0,1.92,0,2.19,0s.38-.06.38-.27V229.2h-7.29v-1.69h7.29V227a12.65,12.65,0,0,0,2-1.05H390.7v-1.49h7.83l.45-.11,1.21,1.1a24.67,24.67,0,0,1-3.44,2.07h6.73Zm-13.91-3.38h-2v-3.69h1.35l-.32-5.35a25.57,25.57,0,0,0,3.64-1l.88,1.24a17.26,17.26,0,0,1-2.47.69l0,.52h2.28v1.33h-2.21l0,.61H393v.92a11.6,11.6,0,0,0,1.26-.68c-.34-.22-.68-.42-1-.59l1-.85c.36.18.74.39,1.14.61a6.24,6.24,0,0,0,.7-.76l1.4.42a7.35,7.35,0,0,1-.95,1.08,9.23,9.23,0,0,1,1.1.83l-1.08.93a12.34,12.34,0,0,0-1-.88,12.41,12.41,0,0,1-1.46.94h5.84l.07-.63H397.8v-1.34h2.25l0-.61h-2.19v-1.33h2.28l0-.59H397.8v-1.35h4.5c-.09,1.89-.24,4.17-.38,5.85h1.33v3.69h-2.12v-2.14H389.57Zm1.29-3.69h2.92a10.39,10.39,0,0,0-.81-.83v.2h-2.14Zm5.58-3.32a9.38,9.38,0,0,0-.93-.75,9.61,9.61,0,0,1-1.46.9,8.47,8.47,0,0,0-1.12-1,8,8,0,0,0,1.37-.7c-.34-.2-.65-.38-1-.54l.95-.83c.35.17.72.36,1.1.58a5.42,5.42,0,0,0,.65-.72l1.41.41a6,6,0,0,1-.91,1c.37.26.69.51.94.72Z"
          fill="#002a5e"
        />
        <circle
          cx="675.44"
          cy="383.67"
          r="34.8"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <path
          d="M660.5,370.52h-2v-4.06h6.8v-.72h-6v-1.4h14.11v1.4h-6v.72h7v4.06h-2.07l-.38,1.27a29,29,0,0,0-4.16-1l.42-1.2a24.25,24.25,0,0,1,4,.84v-2.57h-1l.65.92a18.66,18.66,0,0,1-3.21.86l-.67-1a20.44,20.44,0,0,0,2.47-.77h-3.08v3.74h-2.09v-3.74H661.9a18.25,18.25,0,0,1,2.81.74l-.57,1.15a21.36,21.36,0,0,0-3.1-.88l.5-1h-1v2.47c1.1-.13,2.61-.35,4.16-.58l.07,1.22-3.81.87ZM672,378.64c.92,0,1.08-.27,1.2-1.79a5.63,5.63,0,0,0,1.79.67c-.26,2.16-.85,2.74-2.81,2.74h-3.78c-2.45,0-3.16-.51-3.16-2.38v-.05h-3v.77h-2.06v-6.54h12.48v5.77h-5.38v.05c0,.65.21.76,1.29.76Zm-9.74-5.14v.81h3v-.81Zm3,2.89v-.82h-3v.82Zm5.32-2.89h-3.26v.81h3.26Zm0,2.89v-.82h-3.26v.82Z"
          fill="#002a5e"
        />
        <path
          d="M682,378.31c0,.92-.14,1.48-.68,1.78a4.62,4.62,0,0,1-2.45.38,7.3,7.3,0,0,0-.52-1.89c.66,0,1.33,0,1.56,0s.31-.07.31-.32v-4.2c-.36.38-.74.76-1.1,1.13l-.5-1.22a21.52,21.52,0,0,1-1.26,6.61,7.17,7.17,0,0,0-1.44-1.21c1-2.46,1-6,1-8.64v-6.43h5Zm-3.29-7.56c0,.77,0,1.64-.06,2.57.45-.3,1-.72,1.57-1.15v-1.78l-.68.47a9.89,9.89,0,0,0-.83-1.25Zm0-4.5v2.25l.56-.36c.3.34.66.74,1,1.12v-3Zm5.81,4.52a19.83,19.83,0,0,0-2.23-3.66,29.26,29.26,0,0,0,2-3.36l1.85.43c-.54,1-1.21,2-1.76,2.91a24.54,24.54,0,0,1,2,3Zm4.09-.33c-.22.35-.45.69-.68,1h4.46v9h-2v-.63h-5.65v.7h-1.91v-9.06h2.95a13.36,13.36,0,0,0,.56-1.42l1.26.23a18.61,18.61,0,0,0-2.09-3.17,27.17,27.17,0,0,0,1.89-3.18l1.86.45c-.54.92-1.17,1.89-1.73,2.72a18,18,0,0,1,1.94,2.75l-1.28.54Zm1.8,2.74h-1.78l1.42.76a11.39,11.39,0,0,1-1.13,1.45c.55.46,1.08.91,1.46,1.28l-1.21,1.19a12.33,12.33,0,0,0-1.44-1.31,12.84,12.84,0,0,1-1.93,1.38,5.7,5.7,0,0,0-1-1.11v1.26h5.65Zm-5.65,0v3.58a12.45,12.45,0,0,0,1.73-1.2c-.53-.38-1-.74-1.5-1.05l1.12-1c.48.28,1,.63,1.49.95a7,7,0,0,0,1-1.26Zm6.35-2.68a18.09,18.09,0,0,0-2.39-3.41,28.71,28.71,0,0,0,2-3.16l1.89.45c-.54.9-1.17,1.87-1.74,2.68a20.07,20.07,0,0,1,2,2.66Z"
          fill="#002a5e"
        />
        <path
          d="M664,398.15a21.27,21.27,0,0,0-1.08-2v7.31h-2.07V397a16.62,16.62,0,0,1-2,3.57,11.65,11.65,0,0,0-1-2,16.92,16.92,0,0,0,2.67-4.82h-2.43v-2h2.72v-2c-.71.11-1.37.22-2,.29a6.26,6.26,0,0,0-.58-1.65,28.94,28.94,0,0,0,5.8-1.48l1.21,1.75a16.25,16.25,0,0,1-2.32.66v2.47h2.37v2h-2.37v.14c.52.51,2,2.2,2.34,2.61Zm8.87.77v4.54h-2.1V399.3l-5.44.93-.34-2,5.78-1V386.66h2.1v10.19l1.79-.31.34,2Zm-4.52-2.5a15.48,15.48,0,0,0-2.86-2.76l1.37-1.22a15.39,15.39,0,0,1,2.95,2.61Zm.47-4.76a13.59,13.59,0,0,0-2.68-2.7l1.4-1.22a14.32,14.32,0,0,1,2.78,2.59Z"
          fill="#002a5e"
        />
        <path
          d="M692.56,400.18h-6.88v1.3c0,.95-.21,1.42-.95,1.71a10.39,10.39,0,0,1-3.08.27,6.9,6.9,0,0,0-.65-1.8c.85,0,1.93,0,2.2,0s.38-.05.38-.27v-1.22h-7.29v-1.69h7.29V398a13.55,13.55,0,0,0,2-1.05h-5.78v-1.49h7.83l.45-.11,1.21,1.1a25.27,25.27,0,0,1-3.44,2.07h6.73Zm-13.92-3.38h-2v-3.7H678l-.33-5.34a24.85,24.85,0,0,0,3.64-1l.88,1.24a17.51,17.51,0,0,1-2.46.68l0,.53H682v1.33H679.8l0,.61h2.22v.92a13.63,13.63,0,0,0,1.26-.68c-.35-.22-.69-.42-1-.6l1-.85c.36.18.74.4,1.13.62a8.32,8.32,0,0,0,.71-.76l1.4.42a8.55,8.55,0,0,1-.95,1.08,7.53,7.53,0,0,1,1.09.82l-1.08.94a11.78,11.78,0,0,0-1-.88,13.17,13.17,0,0,1-1.46.93h5.83l.08-.63h-2.13v-1.33h2.25l0-.61H687V389.2h2.29l0-.6h-2.41v-1.35h4.5c-.09,1.89-.23,4.18-.37,5.85h1.33v3.7H690.2v-2.15H678.64Zm1.3-3.7h2.92c-.2-.23-.54-.57-.81-.82v.19H679.9Zm5.58-3.31a11,11,0,0,0-.93-.76,9.31,9.31,0,0,1-1.46.9A9.94,9.94,0,0,0,682,389a8,8,0,0,0,1.37-.7c-.34-.2-.65-.38-1-.54l1-.83c.34.16.72.36,1.1.58a7.16,7.16,0,0,0,.65-.72l1.4.41a6.89,6.89,0,0,1-.9,1,10.81,10.81,0,0,1,.94.72Z"
          fill="#002a5e"
        />
        <circle
          cx="854.53"
          cy="354.22"
          r="34.8"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <path
          d="M843.16,335.17a27.42,27.42,0,0,1-1.73,3.74v12.34h-2.16v-9c-.38.45-.76.9-1.12,1.29a14.6,14.6,0,0,0-1.18-2.28,18.92,18.92,0,0,0,4.1-6.74Zm5.71,4.25v1.89h4.66v1.94h-4.66v2h4.9v2h-4.9v4h-2.2V339.42H846a20.2,20.2,0,0,1-2.36,3.49,14.76,14.76,0,0,0-1.64-1.49,18,18,0,0,0,3.82-6.79l2.07.52a20.24,20.24,0,0,1-.88,2.23h7v2Z"
          fill="#002a5e"
        />
        <path
          d="M862,348.46a13.93,13.93,0,0,1-5.23,2.39,8.63,8.63,0,0,0-1.27-1.55,14.91,14.91,0,0,0,5-1.53Zm9.55-3v1.77h-7v4.1h-2.12v-4.1H855.6v-1.77h6.86v-.79h-5.19v-1.55h5.19v-.81h-6.09v-1.62h3.31a10,10,0,0,0-.66-1.31l1.2-.27h-4.59v-1.68h2.45a17.52,17.52,0,0,0-1.15-2.23l1.84-.61a11.65,11.65,0,0,1,1.36,2.52l-.86.32h1.66v-2.93h2v2.93h1.35v-2.93h2v2.93h1.61l-.65-.19a15.82,15.82,0,0,0,1.15-2.6l2.25.53c-.5.82-1,1.63-1.44,2.26h2.32v1.68h-4.75l1.69.43c-.39.43-.77.83-1.1,1.15h3.48v1.62h-6.25v.81H870v1.55h-5.4v.79Zm-10.39-6.35a8.6,8.6,0,0,1,.72,1.35l-1,.23h4.91l-.65-.2c.29-.41.67-.95.92-1.38Zm5.62,8.61c1.45.54,3.58,1.44,4.73,2L870,350.85c-1-.59-3.15-1.55-4.66-2.18Z"
          fill="#002a5e"
        />
        <path
          d="M841.27,360.21a31,31,0,0,1-1,3.58h3.36v8.28h-3.43v1.32h-1.79v-5.58c-.23.34-.47.66-.72,1a11.69,11.69,0,0,0-.74-2.56,15.08,15.08,0,0,0,2.29-6h-1.91v-1.95h6.54v1.95Zm.58,5.47h-1.62v4.5h1.62Zm12.1,1.08h-1.9v7.49h-2v-7.49h-2c-.11,2.65-.63,5.65-2.49,7.62a8.41,8.41,0,0,0-1.62-1.25c1.55-1.67,2-4.17,2.11-6.37H844.3v-2H846V360.3h-1.24v-2h8.83v2h-1.54v4.44H854Zm-3.93-2V360.3h-2v4.44Z"
          fill="#002a5e"
        />
        <path
          d="M869.53,371.82c.42,0,.49-.32.56-2.56a6.55,6.55,0,0,0,2,.92c-.22,2.95-.78,3.75-2.32,3.75h-1.7c-1.76,0-2.32-.63-2.32-2.74v-2.32h-2.36c-.45,2.1-2,4.28-6.68,5.49a10.48,10.48,0,0,0-1.51-1.84c3.73-.93,5.24-2.28,5.78-3.65h-4.41v-2h4.71v-2h2.27v2H868v4.28c0,.58.07.65.49.65ZM858,362.67h-2.16V359h6.57c-.12-.45-.25-.9-.38-1.28l2.27-.36c.18.51.38,1.1.54,1.64h6.56v3.51h-2.26v-1.58h-2.53v2.3c0,.47.07.67.54.67h2.18a6.66,6.66,0,0,0,1.56-.15c.06.58.11,1.3.18,1.8a6.8,6.8,0,0,1-1.66.15H867c-2,0-2.52-.72-2.52-2.49v-2.28h-1.85c-.34,2.55-1.37,4.08-5.49,4.95a5.28,5.28,0,0,0-1.12-1.75c3.4-.56,4.11-1.51,4.38-3.2H858Z"
          fill="#002a5e"
        />
        <circle
          cx="990.81"
          cy="127.68"
          r="34.8"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <path
          d="M989,111.64a21.47,21.47,0,0,1-1.67,6.65,7.72,7.72,0,0,0,3.1,3.27,6.6,6.6,0,0,0-1.35,1.66,9,9,0,0,1-2.85-3.11,10.4,10.4,0,0,1-3.89,3.2,8.93,8.93,0,0,0-.59-1l-.54.48a16.91,16.91,0,0,0-2.16-1,15.16,15.16,0,0,1-5,1.55,5.45,5.45,0,0,0-.83-1.39,16.86,16.86,0,0,0,3.76-.94c-.83-.28-1.69-.54-2.48-.73.32-.4.66-.87,1-1.37h-1.91v-1.44h2.83c.16-.27.3-.54.43-.81h-2.5v-3.19h3.18v-.52h-2.88v-2h-1.24v-1.52h1.24v-1.85h2.88v-1.08h1.62v1.08h3.1v1.85h.94v1.52h-.94v2h-3.1v.52h3.35v.72a28.86,28.86,0,0,0,2.18-7.67l1.76.32c-.16,1-.37,2-.63,3h4.38v1.87Zm-12.87,3.75h1.42v-.79h-1.42Zm.13-6.54v.63h1.29v-.63Zm1.29,2.67v-.74h-1.29v.74Zm-.09,7.31c-.12.21-.27.41-.39.61.57.16,1.15.32,1.71.52a3.14,3.14,0,0,0,.93-1.13Zm4,0a3.9,3.9,0,0,1-1,1.78c.58.25,1.14.49,1.64.72a8.12,8.12,0,0,0,3.21-3.1,23.6,23.6,0,0,1-1.07-3.71,14.63,14.63,0,0,1-.73,1.53,11.4,11.4,0,0,0-1.05-1.06v1.59h-3.78c-.13.27-.27.54-.41.81h1.6v-.53h1.67v.53h1.5v1.44Zm-2.32-10v.63h1.41v-.63Zm1.41,2.67v-.74h-1.41v.74Zm-1.41,3.08v.79h1.48v-.79Zm6.16-3a24.64,24.64,0,0,0,1,4.34,22.7,22.7,0,0,0,.81-4.34Z"
          fill="#002a5e"
        />
        <path
          d="M1007.93,120h-6.88v1.3c0,.95-.21,1.42-.95,1.71a10.39,10.39,0,0,1-3.08.27,6.9,6.9,0,0,0-.65-1.8c.85,0,1.93,0,2.2,0s.38-.06.38-.27V120h-7.29v-1.69H999v-.52a13.55,13.55,0,0,0,2-1h-5.78v-1.49H1003l.45-.11,1.21,1.1a25.27,25.27,0,0,1-3.44,2.07h6.73ZM994,116.58h-2v-3.69h1.35l-.33-5.35a24.85,24.85,0,0,0,3.64-1l.88,1.24a17.49,17.49,0,0,1-2.46.69l0,.52h2.29v1.33h-2.21l0,.61h2.22v.92a13.63,13.63,0,0,0,1.26-.68c-.34-.22-.69-.42-1-.6l1-.84c.36.18.74.39,1.13.61a7.12,7.12,0,0,0,.71-.76l1.4.42a8.55,8.55,0,0,1-.95,1.08,7.53,7.53,0,0,1,1.09.82l-1.08.94a11.78,11.78,0,0,0-1-.88,13.29,13.29,0,0,1-1.46.94h5.84l.07-.63h-2.13v-1.34h2.25l0-.61h-2.2V109h2.29l0-.6h-2.41V107h4.5c-.09,1.89-.23,4.18-.37,5.86h1.33v3.69h-2.13v-2.15H994Zm1.3-3.69h2.92a10.39,10.39,0,0,0-.81-.83v.2h-2.15Zm5.58-3.32a9.38,9.38,0,0,0-.93-.75,10.13,10.13,0,0,1-1.46.9,9,9,0,0,0-1.12-1,8,8,0,0,0,1.37-.7c-.34-.2-.65-.38-1-.54l1-.83c.34.16.72.36,1.1.58a7.16,7.16,0,0,0,.65-.72l1.4.41a6.4,6.4,0,0,1-.9,1c.36.25.69.51.94.72Z"
          fill="#002a5e"
        />
        <path
          d="M966.58,141.51a20.14,20.14,0,0,1-.79,4.39,7.05,7.05,0,0,0-1.58-.58,15.3,15.3,0,0,0,.71-4.08Zm3.21-1.07c0-.25-.09-.5-.17-.81-3.6.63-4.19.83-4.61,1a12.86,12.86,0,0,0-.66-1.91c.49-.13.86-.5,1.4-1.19a12.68,12.68,0,0,0,1.1-1.42,6.42,6.42,0,0,0-2,.38c-.11-.42-.43-1.35-.65-1.89.34-.09.65-.43,1-1a21.33,21.33,0,0,0,2.11-4.42l1.91.83a30.11,30.11,0,0,1-2.51,4.49l1.19-.09a29.89,29.89,0,0,0,1.39-2.7l1.74,1.09a29.74,29.74,0,0,1-3.72,5.53l1.82-.29c-.17-.47-.35-.93-.54-1.33l1.44-.56a16.21,16.21,0,0,1,1.26,3.6Zm-1.34.63a31.89,31.89,0,0,1,.49,3.71l-1.6.4c0-1-.2-2.54-.38-3.71Zm1.91-.52a26.94,26.94,0,0,1,.9,2.92l-1.51.56a21.25,21.25,0,0,0-.83-3ZM979,144c.33,0,.38-.34.43-2.45a5.61,5.61,0,0,0,1.8.79c-.18,2.77-.66,3.55-2,3.55H978c-1.64,0-2-.63-2-2.52V138l-1,.13c-.15,3.85-.63,6.55-3.57,8.16a5.46,5.46,0,0,0-1.3-1.68c2.38-1.22,2.74-3.2,2.85-6.21a4.16,4.16,0,0,0-1.22.29,18,18,0,0,0-.73-2.06c.44-.12.8-.55,1.25-1.29a14.77,14.77,0,0,0,1.08-1.88h-2.15v-2h3.07c.28-.71.57-1.43.81-2.16l2.32.61c-.22.52-.43,1-.67,1.55h4.23v2h-5.24a30.24,30.24,0,0,1-1.71,2.85l3.8-.36c-.29-.49-.57-1-.83-1.4l1.73-.78a23.73,23.73,0,0,1,2.43,4.47l-1.87.86a12.32,12.32,0,0,0-.56-1.4l-.7.09v5.58c0,.56,0,.63.34.63Z"
          fill="#002a5e"
        />
        <path
          d="M990.45,134.07h-7.58v-1.69H986a21.25,21.25,0,0,0-1.17-2.27l1.68-.78a18.7,18.7,0,0,1,1.31,2.4l-1.17.65h3.78Zm-6.79,2.43v-1.62h6v1.62Zm6,2.41h-6v-1.62h6Zm.05,6.32h-4.19V146h-1.8v-6.21h6Zm-4.19-3.74v2h2.34v-2Zm13.55-4h-3.33v8.73h-2.23v-8.73h-3.15v-2.11h3.15v-5.65h2.23v5.65h3.33Z"
          fill="#002a5e"
        />
        <path
          d="M1016.93,142.93h-6.88v1.29c0,1-.21,1.43-.95,1.71a10,10,0,0,1-3.08.28,7.11,7.11,0,0,0-.65-1.81c.85,0,1.93,0,2.2,0s.38,0,.38-.27v-1.22h-7.29v-1.69H1008v-.53a13.47,13.47,0,0,0,2-1h-5.78v-1.5H1012l.45-.1,1.21,1.1a26.32,26.32,0,0,1-3.44,2.07h6.73ZM1003,139.54h-2v-3.69h1.35l-.33-5.35a24.85,24.85,0,0,0,3.64-1l.88,1.24a17.51,17.51,0,0,1-2.46.68l0,.52h2.29v1.34h-2.21l0,.61h2.22v.92a11.65,11.65,0,0,0,1.26-.69c-.34-.21-.69-.41-1-.59l1-.85c.36.18.74.4,1.13.62a7.12,7.12,0,0,0,.71-.76l1.4.41A7.35,7.35,0,0,1,1010,134a8.32,8.32,0,0,1,1.09.83l-1.08.94a11.78,11.78,0,0,0-1-.88,13.17,13.17,0,0,1-1.46.93h5.84l.07-.63h-2.13v-1.33h2.25l0-.61h-2.2v-1.34h2.29l0-.59h-2.41V130h4.5c-.09,1.89-.23,4.18-.37,5.85h1.33v3.69h-2.13V137.4H1003Zm1.3-3.69h2.92c-.2-.23-.54-.57-.81-.83v.2h-2.15Zm5.58-3.31a9.33,9.33,0,0,0-.93-.76,9.31,9.31,0,0,1-1.46.9,9,9,0,0,0-1.12-1,8,8,0,0,0,1.37-.7c-.34-.2-.65-.38-1-.54l1-.83c.34.16.72.36,1.1.57a5.49,5.49,0,0,0,.65-.72l1.4.42a6.89,6.89,0,0,1-.9,1,10.81,10.81,0,0,1,.94.72Z"
          fill="#002a5e"
        />
        <circle
          cx="990.81"
          cy="252.71"
          r="34.8"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <path
          d="M977.78,242c-.15-.45-.44-1.1-.74-1.75v8.36H975.2v-6.92a17.11,17.11,0,0,1-1.22,3.19,12.09,12.09,0,0,0-.85-2.11,17.44,17.44,0,0,0,2-5.63h-1.47v-2h1.58v-3.35H977v3.35h1.22v2H977v.79c.39.54,1.42,2.21,1.67,2.65Zm11.38,1.24a10.22,10.22,0,0,1-2,2.65,1.8,1.8,0,0,0,1.12.64c.34,0,.56-.61.68-1.87a5.57,5.57,0,0,0,1.35,1.14c-.46,2.23-1.18,2.86-2,2.81a3.73,3.73,0,0,1-2.64-1.54,13.3,13.3,0,0,1-2.85,1.5,8.16,8.16,0,0,0-1.1-1.37,11.27,11.27,0,0,0,3.1-1.62,14.79,14.79,0,0,1-.92-2.88h-2.45c0,.13,0,.27,0,.4.73.59,1.76,1.42,2.28,1.92l-1.29,1.28c-.31-.36-.83-.83-1.35-1.31a7.23,7.23,0,0,1-2.45,3.62,6.5,6.5,0,0,0-1.43-1.23,5.83,5.83,0,0,0,2.45-4.68h-1.06V241h5a58.42,58.42,0,0,1-.62-6.3,23.91,23.91,0,0,1-2.45,3.74l1-.1c-.07-.29-.16-.58-.23-.81l1.24-.33a16.1,16.1,0,0,1,.7,2.59l-1.31.4a6.18,6.18,0,0,0-.11-.61,10.45,10.45,0,0,0-2.83.56,14.47,14.47,0,0,0-.48-1.46c.29-.07.57-.36.88-.81a12.37,12.37,0,0,0,.86-1.3,5,5,0,0,0-1.44.31,11.84,11.84,0,0,0-.5-1.44,1.3,1.3,0,0,0,.65-.65,13.16,13.16,0,0,0,1.19-2.88l1.54.41a18.67,18.67,0,0,1-1.54,3l.81-.05c.18-.38.36-.76.5-1.12l1.49.49c0-.87-.07-1.8-.1-2.74h1.76a59.12,59.12,0,0,0,.76,9.11h1.65c-.3-.27-.64-.54-.93-.77l.81-.67a4.18,4.18,0,0,0-1.28.32,12,12,0,0,0-.49-1.47,2.08,2.08,0,0,0,.89-.83,9.11,9.11,0,0,0,.82-1.28,4.76,4.76,0,0,0-1.4.31c-.09-.33-.34-1-.5-1.44a1.31,1.31,0,0,0,.61-.61,14.67,14.67,0,0,0,1.08-2.72l1.53.41a14.41,14.41,0,0,1-1.41,2.77l.81,0c.17-.36.33-.76.47-1.12l1.55.51a22.27,22.27,0,0,1-2.41,3.81l1-.12c-.09-.25-.17-.52-.26-.74l1.23-.34a23.2,23.2,0,0,1,.83,2.61l-1.28.43c0-.2-.09-.45-.16-.72l-1.14.18a13.9,13.9,0,0,1,1.37,1.1l-.47.43h1.44v1.64h-2.09Zm-3.37-.54a12.18,12.18,0,0,0,.49,1.57,8.65,8.65,0,0,0,1.06-1.57Z"
          fill="#002a5e"
        />
        <path
          d="M1004.85,240.89a20.25,20.25,0,0,1,3.46,2.45l-1.44,1.25c-.15-.15-.31-.31-.49-.47v4.34h-2V248h-2.12v.54h-1.86v-5.78h4.21a22.43,22.43,0,0,0-3.27-1.9H998a13,13,0,0,1-2.21,1.9h3.46v5.67h-2V248H995.2v.54h-1.85v-4.38l-1,.43a6.22,6.22,0,0,0-1.11-1.62,14.5,14.5,0,0,0,3.92-2.11h-3.51v-1.78H997a8.87,8.87,0,0,0,.92-1.37h-5V232.5H999v4.92l1.21.23a12.94,12.94,0,0,1-.81,1.46h4a11.9,11.9,0,0,0-1.64-.81l.58-.56h-1.67V232.5h6.14v5.24h-3a11.93,11.93,0,0,1,1.53.9l-.47.47H1008v1.78ZM997,234.18h-2.23v1.87H997Zm.25,10.28H995.2v1.85h2.07Zm7.15,1.85v-1.85h-2.12v1.85Zm-1.91-10.26h2.34v-1.87h-2.34Z"
          fill="#002a5e"
        />
        <path
          d="M989.93,268.26h-6.88v1.29c0,1-.21,1.42-.95,1.71a10.39,10.39,0,0,1-3.08.27,7.18,7.18,0,0,0-.65-1.8c.85,0,1.93,0,2.2,0s.38-.05.38-.27v-1.22h-7.3v-1.7H981V266a14,14,0,0,0,2-1h-5.78v-1.5H985l.45-.11,1.21,1.1a25.27,25.27,0,0,1-3.44,2.07h6.73ZM976,264.87h-2v-3.69h1.35l-.33-5.35a23.67,23.67,0,0,0,3.64-1l.88,1.25a17.51,17.51,0,0,1-2.46.68l0,.52h2.29v1.33h-2.21l0,.62h2.22v.92a13.67,13.67,0,0,0,1.26-.69c-.35-.22-.69-.41-1-.59l1-.85c.36.18.74.4,1.13.61a6.11,6.11,0,0,0,.7-.75l1.41.41a7.43,7.43,0,0,1-1,1.08,8.48,8.48,0,0,1,1.1.83l-1.08.94a11.72,11.72,0,0,0-1-.89,13.29,13.29,0,0,1-1.46.94h5.83l.08-.63h-2.13v-1.33h2.25l0-.62h-2.2v-1.33h2.29l0-.59h-2.41v-1.35h4.5c-.09,1.89-.23,4.17-.38,5.85h1.34v3.69h-2.13v-2.14H976Zm1.3-3.69h2.92a10.39,10.39,0,0,0-.81-.83v.2h-2.15Zm5.58-3.31a8.3,8.3,0,0,0-.94-.76,8.8,8.8,0,0,1-1.45.9,9,9,0,0,0-1.12-.95,8.09,8.09,0,0,0,1.37-.71l-1-.54,1-.82c.34.16.72.36,1.1.57a6.22,6.22,0,0,0,.65-.72l1.4.42a6.89,6.89,0,0,1-.9,1,10.81,10.81,0,0,1,.94.72Z"
          fill="#002a5e"
        />
        <path
          d="M996.48,261.09c-1.44.63-3,1.26-4.25,1.78l-.74-1.74c1.17-.36,2.94-1,4.67-1.57Zm4.48,1.6a14.74,14.74,0,0,1-.7,1.32H1006v7.58h-2.23v-.65H996v.65H993.9V264h4.24c.14-.35.27-.72.37-1.05l0,0a7.1,7.1,0,0,1-2.45.23,5.57,5.57,0,0,0-.54-1.51c.61,0,1.32,0,1.51,0s.27-.07.27-.25v-4.29h-3.47a11.74,11.74,0,0,1,2,1.35l-1.08,1.37a9.78,9.78,0,0,0-2.27-1.64l.9-1.08H992v-1.65h7.22v6a2.42,2.42,0,0,1-.14.94Zm2.78,2.94H996v1h7.71Zm0,3.69v-1.05H996v1.05Zm3.58-7.89c0,.87-.18,1.3-.79,1.57a6.63,6.63,0,0,1-2.63.27,6.14,6.14,0,0,0-.58-1.62c.69,0,1.48,0,1.69,0s.31-.07.31-.27v-4.23h-3.44a11.2,11.2,0,0,1,2,1.28l-1,1.3c.54-.2,1.1-.38,1.65-.58l.33,1.53c-1.55.6-3.21,1.21-4.54,1.69l-.72-1.6c.83-.25,1.93-.59,3.1-1a11.9,11.9,0,0,0-2.27-1.57l.86-1.06h-1.17v-1.65h7.17Z"
          fill="#002a5e"
        />
        <circle
          cx="501.99"
          cy="97.43"
          r="34.8"
          fill="#fff"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <path
          d="M479.17,94.82a47.56,47.56,0,0,1-1.31,7.48l-2.16-.87a35,35,0,0,0,1.38-7Zm3.39-1.45v8.35c0,.85.14,1,1,1h2.58c.9,0,1.06-.58,1.19-3.38a6.85,6.85,0,0,0,2,.9c-.25,3.42-.82,4.48-3,4.48h-3c-2.34,0-3-.72-3-3V93.37Zm-.17-4.85a32.73,32.73,0,0,1,5,3.73l-1.58,1.67A32.27,32.27,0,0,0,480.94,90Zm7.85,5.33a33.57,33.57,0,0,1,2.33,6.7l-2.18.9a30.84,30.84,0,0,0-2.14-6.79Z"
          fill="#002a5e"
        />
        <path
          d="M499.82,101.45c-2,.7-4.14,1.39-5.87,1.94l-.49-2.17c.63-.17,1.37-.36,2.2-.6V96.39h-1.82v-2h1.82V91.26h-2v-2h5.94v2h-1.93v3.17h1.62v2h-1.62V100c.6-.18,1.19-.36,1.77-.56Zm10.69,1.1v1.94H498.94v-1.94h5v-1.71h-3.72V98.91h3.72V97.36h-3.6V89h9.4v8.37h-3.62v1.55h3.82v1.93h-3.82v1.71Zm-8.28-10.23h1.86V90.81h-1.86Zm0,3.22h1.86V94h-1.86Zm5.44-4.73h-1.78v1.51h1.78Zm0,3.19h-1.78v1.54h1.78Z"
          fill="#002a5e"
        />
        <path
          d="M528.12,101.76h-6.88v1.29c0,1-.22,1.42-1,1.71a10,10,0,0,1-3.07.27,7.18,7.18,0,0,0-.65-1.8c.84,0,1.92,0,2.19,0s.38,0,.38-.27v-1.22h-7.29v-1.7h7.29v-.52a13.47,13.47,0,0,0,2-1h-5.78V97h7.84l.45-.11,1.2,1.1a24.67,24.67,0,0,1-3.44,2.07h6.74ZM514.2,98.37h-2V94.68h1.35l-.32-5.35a24.31,24.31,0,0,0,3.64-1l.88,1.25a17.29,17.29,0,0,1-2.47.68l0,.52h2.29v1.34h-2.22l0,.61h2.21v.92a13.67,13.67,0,0,0,1.26-.69c-.34-.21-.68-.41-1-.59l1-.85c.36.18.74.4,1.14.61a7,7,0,0,0,.7-.75l1.41.41a7.43,7.43,0,0,1-1,1.08,9.23,9.23,0,0,1,1.1.83l-1.08.94a12.25,12.25,0,0,0-1-.89,13.19,13.19,0,0,1-1.45.94h5.83l.07-.63h-2.12V92.72h2.25l0-.61h-2.19V90.77h2.28l0-.59h-2.41V88.83h4.5c-.09,1.89-.24,4.18-.38,5.85h1.33v3.69h-2.12V96.23H514.2Zm1.3-3.69h2.91c-.2-.23-.54-.58-.81-.83v.2h-2.14Zm5.58-3.31a9.31,9.31,0,0,0-.94-.76,8.87,8.87,0,0,1-1.46.9,8.21,8.21,0,0,0-1.11-1,8.39,8.39,0,0,0,1.36-.71c-.34-.19-.64-.37-1-.54l1-.82c.34.16.72.36,1.09.57a5.42,5.42,0,0,0,.65-.72l1.41.42a6.89,6.89,0,0,1-.9,1,10.53,10.53,0,0,1,.93.72Z"
          fill="#002a5e"
        />
        <circle
          cx="840.29"
          cy="241.71"
          r="42.15"
          fill="#7d3787"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <path
          d="M827.94,232.46a10.11,10.11,0,0,0,4.45.7c1,0,6,0,7.27,0a5.41,5.41,0,0,0-.68,1.65h-6.61c-3.71,0-5.92-.55-7.33-3.06a10.52,10.52,0,0,1-1.24,3.5,3.42,3.42,0,0,0-1.06-1c1.19-2.09,1.3-5,1.22-7.4l1.39.07c0,.66,0,1.37,0,2.07a6.78,6.78,0,0,0,1.12,2.32v-5.78h-3.24V224H826V222.2h-2.37v-1.48H826v-2h1.5v2h2.21v1.22a8.57,8.57,0,0,0,2.31-3.35l1.44.36c-.11.26-.24.51-.36.76H839s0,.34,0,.54c-.18,3.15-.35,4.32-.74,4.79a1.33,1.33,0,0,1-1,.43,9.32,9.32,0,0,1-1.69,0,2.8,2.8,0,0,0-.31-1.06c.6.05,1.12.07,1.35.07a.54.54,0,0,0,.47-.16c.22-.25.38-1.17.51-3.44h-5.12c-.16.25-.32.51-.5.74h.14V223h1.12v-1.64h1.12V223h1.09v-1.35h1.16v2.32h-2.35a2.4,2.4,0,0,1-1.44,1.44l.56.15a6.86,6.86,0,0,1-.38.77h6.14s0,.38,0,.58c-.16,3.42-.34,4.68-.78,5.16a1.29,1.29,0,0,1-1,.45,11.93,11.93,0,0,1-1.75,0,3.05,3.05,0,0,0-.3-1.11c.59,0,1.13,0,1.36,0a.49.49,0,0,0,.49-.16c.23-.31.41-1.3.56-3.82H832.3c-.2.29-.4.56-.59.81h.23v1.41h1.13V228h1.21v1.73h1.14v-1.41h1.17v2.43h-2.47c-.32.85-1.19,1.62-3.26,2.11a3.24,3.24,0,0,0-.72-.88,4,4,0,0,0,2.61-1.23h-1.91v-1.47l-.18.16a6.06,6.06,0,0,0-.7-.52V229h-2Zm0-7v2.07h2v.49a9.1,9.1,0,0,0,1.64-2.24c-.22.06-.45.09-.69.15a2.51,2.51,0,0,0-.61-.83c1.62-.25,2.4-.7,2.72-1.19h-2v-1.2l-.19.19c-.26-.19-.72-.52-1.08-.73h-2.2V224h2.63v1.48Z"
          fill="#fff"
        />
        <path
          d="M844.65,222.68a3.54,3.54,0,0,1-2.81,2.47,5,5,0,0,0-.65-1c1.12-.24,1.93-.69,2.2-1.48h-2.06v-1.11h3.21v-.83H842v-1.06h2.56v-1h1.53v1h2.59v1.06h-2.59v.83h3.29v1.11h-2.23v.52c0,.35,0,.47.27.47h.79a3.4,3.4,0,0,0,.83-.09c0,.33,0,.67.09,1a3.28,3.28,0,0,1-.9.09h-1.13c-1,0-1.23-.47-1.23-1.47v-.51ZM856,220.76c0,3.87,0,6.85.58,6.85.23,0,.3-.91.32-2.14a5.59,5.59,0,0,0,1,.94c-.12,1.87-.37,2.65-1.4,2.65-1.71,0-1.89-2.88-1.93-6.95H853a18.67,18.67,0,0,1-.36,2.48,20.51,20.51,0,0,1,1.78,1.17l-.76,1.19c-.36-.27-.88-.65-1.46-1a5.68,5.68,0,0,1-2.28,2.81,4.26,4.26,0,0,0-.87-1v.61c-2.78.22-5.66.42-7.69.56l-.09-1.23c.92,0,2.07-.1,3.33-.18v-1H842v-1.06h2.58v-.92h1.53v.92h2.65v1.06h-2.65v1l2.93-.22v.48a4.85,4.85,0,0,0,1.93-2.48c-.52-.32-1.06-.67-1.53-.95l.72-1.05,1.19.7a14.6,14.6,0,0,0,.23-1.72h-1.78v-1.35h1.85c0-.64,0-1.34.06-2.06h1.42c0,.72,0,1.41-.05,2.06Zm0,9.07s0,.47-.08.67c-.28,2.55-.57,3.69-1.08,4.17a2,2,0,0,1-1.38.53c-.53,0-1.5,0-2.54,0a3.41,3.41,0,0,0-.49-1.5c1,.09,2,.11,2.34.11a1,1,0,0,0,.7-.16c.29-.24.49-1,.67-2.49h-4.7c-.83,2.17-2.68,3.48-7.33,4.13a4.56,4.56,0,0,0-.75-1.37c3.85-.45,5.51-1.32,6.3-2.76H842.4v-1.31h5.76c.07-.32.12-.67.18-1H850c0,.36-.11.71-.18,1Z"
          fill="#fff"
        />
        <path
          d="M822.9,256.65a20.78,20.78,0,0,0,.78-3.89l.9.29a25.29,25.29,0,0,1-.72,4Zm7.08-5.47c-.13,4.34-.25,5.9-.63,6.41a1.16,1.16,0,0,1-.94.5,6.27,6.27,0,0,1-1.42,0,3.5,3.5,0,0,0-.36-1.26,11.17,11.17,0,0,0,1.13.05.46.46,0,0,0,.45-.2,5.66,5.66,0,0,0,.31-1.94l-.67.27c-.18-.63-.57-1.62-.88-2.36l.65-.22a17.86,17.86,0,0,1,.9,2.22c0-.67.07-1.57.11-2.7h-4.77v-9.55h6v1.37h-2.06v1.44h1.75v.69a13,13,0,0,0,4.66-4.45l1.53.48a2.94,2.94,0,0,1-.36.58,15.31,15.31,0,0,0,4.2,2.88,7.73,7.73,0,0,0-.67,1.5,15.15,15.15,0,0,1-1.64-1v1H832l0-.86c-.47.34-.94.65-1.42.94a6.88,6.88,0,0,0-1-1v.52h-1.75v1.35h1.78v1.3h-1.78v1.42H830Zm-4.4,1.85a29.84,29.84,0,0,1,.24,3.24l-.81.09c0-.88-.06-2.23-.16-3.24Zm-.41-9.22v1.44h1.37v-1.44Zm1.37,2.72h-1.37v1.35h1.37Zm-1.37,4.07h1.37v-1.42h-1.37Zm1.48,2.21c.23.92.5,2.13.61,2.9l-.76.17c-.11-.82-.36-2-.59-2.94Zm6.64.29a13.94,13.94,0,0,1-.4,1.42,9.33,9.33,0,0,1,1.86,1.45l-.87,1.09a9.33,9.33,0,0,0-1.49-1.29,8.47,8.47,0,0,1-1.69,2.43,12.38,12.38,0,0,0-1.12-1,8,8,0,0,0,2.29-4.41Zm-2.68-4.95h3.67v4.23h-3.67Zm1.13,3.12h1.35v-2h-1.35Zm5.24-5.62a17.83,17.83,0,0,1-2.34-2,15.2,15.2,0,0,1-2,2Zm.79,7.34a11.22,11.22,0,0,1-.52,2,17.28,17.28,0,0,1,2.23,1.8l-1,1.16a12,12,0,0,0-1.8-1.61,7.21,7.21,0,0,1-1.34,1.86,10.59,10.59,0,0,0-1.22-1,7.71,7.71,0,0,0,2.16-4.48ZM835,248.15h3.82v4.23H835Zm1.15,3.12h1.46v-2h-1.46Z"
          fill="#fff"
        />
        <path
          d="M847.15,246h-6.1v-1.33h3.07a15.89,15.89,0,0,0-1.36-2.43l1.15-.71a17.17,17.17,0,0,1,1.4,2.31l-1.17.83h3Zm-.49,11.14H843V258h-1.32v-6.08h5Zm-.07-8.76h-4.84v-1.32h4.84Zm-4.84,1.06h4.84v1.29h-4.84Zm1.26,3.78v2.57h2.34v-2.57Zm7.06-5.87a8.56,8.56,0,0,1-2.15,2.14,6,6,0,0,0-1-1.13,7.16,7.16,0,0,0,1.92-1.89,14.89,14.89,0,0,0-1.55-1.16l.89-1.06a13.68,13.68,0,0,1,1.44.94,8,8,0,0,0,.64-1.57h-2.39V242.3h3l.27-.05,1,.32a13.1,13.1,0,0,1-1.58,4.12h3.73a15.66,15.66,0,0,1-1.84-4.61l1.39-.25a13.18,13.18,0,0,0,.41,1.37c.43-.4.88-.81,1.21-1.13l1,.91a15.63,15.63,0,0,1-1.69,1.37c.14.31.3.61.45.9a14.9,14.9,0,0,0,1.4-1.11l1,.93a20.3,20.3,0,0,1-1.73,1.17,7.34,7.34,0,0,0,2,1.86,6,6,0,0,0-1.14,1.22,7.85,7.85,0,0,1-2.1-2.12V248h-4.5Zm5.49,6.53c-.42.85-.88,1.81-1.32,2.53h3.15v1.35H847.28v-1.35h2.91a8.6,8.6,0,0,0-1-2.47l1.42-.38a8,8,0,0,1,1.06,2.49l-1.26.36h2.27a19.66,19.66,0,0,0,1.19-2.94Zm-6.83-4.82h7.13v4.37h-7.13Zm1.44,3.06h4.2v-1.76h-4.2Z"
          fill="#fff"
        />
        <circle
          cx="686.18"
          cy="112.97"
          r="102.52"
          fill="#f8a908"
          stroke="#002a5e"
          strokeWidth="2"
        />
        <path
          d="M673.7,145.55c.72,0,.86-.31,1-1.89a6,6,0,0,0,1.73.74c-.23,2.25-.79,2.93-2.54,2.93h-3.13c-2.13,0-2.7-.57-2.7-2.63v-3.44l-.74.33-.74-1.73c-.07,4.39-.2,6.12-.61,6.68a1.57,1.57,0,0,1-1.15.76,10,10,0,0,1-1.86.09,4.86,4.86,0,0,0-.52-1.91,10.42,10.42,0,0,0,1.31.07.51.51,0,0,0,.51-.29c.23-.36.34-1.89.41-6.1h-1.4c-.25,3.24-.83,6.14-2.32,8.21a6.28,6.28,0,0,0-1.54-1.5c1.79-2.46,2-6.41,2-10.58h-1.72v-2h3.13a16.85,16.85,0,0,0-.74-2.33l1.91-.45a24.41,24.41,0,0,1,.84,2.36l-1.45.42h3.56v1.89a12.69,12.69,0,0,0,2-4.65L671,131a19.43,19.43,0,0,1-.56,1.88h5.69v1.94h-6.48a11.69,11.69,0,0,1-1,1.75h1.32v1.73l1-.44v-2.59h1.78V137l1.34-.61.3-.22,1.41.38-.09.29a54,54,0,0,1-.44,6.23l-1.51-.29c.09-1.08.15-2.69.16-4.23l-1.17.54v5h-1.78v-4.2l-1,.45v4.34c0,.74.12.85,1,.85ZM668,139.16v-1.89l-.16.18a12.28,12.28,0,0,0-1.61-1.52,6,6,0,0,0,.58-.64H663.4c0,.64,0,1.27,0,1.92h3.24V138l0,1.8Z"
          fill="#002a5e"
        />
        <path
          d="M694.38,146.81h-6.3v.59h-1.94v-7l-.18.19a11.76,11.76,0,0,0-1.59-1.19,10.21,10.21,0,0,0,2.72-4H682l.95.39a18,18,0,0,1-2,2.51l1.31-.06a15.73,15.73,0,0,0,1.17-1.62l1.6.92a24.32,24.32,0,0,1-3.53,3.67l2.07-.19c-.14-.33-.28-.62-.41-.9l1.4-.51a11.89,11.89,0,0,1,1.18,3l-1.52.56a7,7,0,0,0-.18-.77c-4,.43-4.71.55-5.18.73a9,9,0,0,0-.65-1.35,3.24,3.24,0,0,0,1.48-.88c.23-.2.68-.59,1.22-1.13a8.87,8.87,0,0,0-2.21.32,9.25,9.25,0,0,0-.69-1.37,1.81,1.81,0,0,0,1-.66,12.89,12.89,0,0,0,1.91-2.63h-2.08v-4.29h14.6v4.29h-6l1.62.39a14.11,14.11,0,0,1-.72,1.59H690a12.27,12.27,0,0,0-.59-1.37l1.64-.49a10,10,0,0,1,.85,1.86h2.23v1.69h-2.36v.95h1.87v1.54h-1.87v1h1.87v1.53h-1.87v1h2.59Zm-13.92-3a12.79,12.79,0,0,1-.86,3.23c-.4-.13-1.19-.29-1.64-.38a9,9,0,0,0,.85-3.06Zm2-.25a18.63,18.63,0,0,1,.54,2.94l-1.66.3a16,16,0,0,0-.45-3Zm.55-10.84h-2.1v1.23H683Zm1.52,10.48a13.33,13.33,0,0,1,1.11,2.72l-1.56.38a14.11,14.11,0,0,0-1-2.76Zm.45-9.25h2.16v-1.23H685Zm5,5.2h-1.84v.95h1.84Zm0,2.49h-1.84v1h1.84Zm-1.84,3.49h1.84v-1h-1.84Zm1-11.18h2.22v-1.23h-2.22Z"
          fill="#002a5e"
        />
        <path
          d="M701.51,135.21a26.69,26.69,0,0,1-1.41,2.51h0v9.67h-2v-6.87a15.37,15.37,0,0,1-1.29,1.48,19.15,19.15,0,0,0-1.17-2,19.42,19.42,0,0,0,3.92-5.47Zm-.33-3.85a29.17,29.17,0,0,1-4.41,5,13.36,13.36,0,0,0-1-1.76,18,18,0,0,0,3.49-4Zm-.92,14.44a9.82,9.82,0,0,0,1.41-3.33l1.62.63a13.46,13.46,0,0,1-1.42,3.62Zm12.43-4.05h-5.46a20.85,20.85,0,0,1,1,1.91l-1.66.59a21.88,21.88,0,0,0-1.08-2.19l.92-.31h-5.36V140h11.61Zm-7.1-7.29c0-.29.08-.6.11-.92h-4.39v-1.69h4.54c0-.49,0-.92.07-1.34l2.27.08-.18,1.26h4.61v1.69h-4.85c0,.32-.1.63-.14.92h4.45v4.77H702v-4.77Zm2.27,11.09c.51,0,.62-.2.67-1.5a5.28,5.28,0,0,0,1.64.63c-.2,2-.7,2.45-2.09,2.45h-2.23c-1.86,0-2.31-.5-2.31-2.07v-2.19h1.87V145c0,.45.11.51.71.51Zm-3-9.58h-1.23v1.75h1.23Zm2.72,0h-1.27v1.75h1.27Zm1.44,1.75h1.31V136H709Zm1.92,4.64a13.17,13.17,0,0,1,1.91,3.41l-1.67.75a11.87,11.87,0,0,0-1.8-3.46Z"
          fill="#002a5e"
        />
        <path
          d="M647.85,157.93a21.69,21.69,0,0,1-1.67,6.64,7.79,7.79,0,0,0,3.09,3.28,6.77,6.77,0,0,0-1.35,1.65,9,9,0,0,1-2.84-3.11,10.31,10.31,0,0,1-3.89,3.2,8.61,8.61,0,0,0-.6-1l-.54.49a14.57,14.57,0,0,0-2.16-1,15.08,15.08,0,0,1-5,1.55,5.77,5.77,0,0,0-.82-1.39,17.08,17.08,0,0,0,3.76-.93c-.83-.29-1.69-.54-2.48-.74.32-.4.66-.87,1-1.37h-1.91v-1.44h2.82a8.06,8.06,0,0,0,.44-.81h-2.51v-3.19h3.19v-.52h-2.88v-2h-1.24v-1.51h1.24V153.8h2.88v-1.08H638v1.08h3.1v1.86h.93v1.51h-.93v2H638v.52h3.35v.72a28.86,28.86,0,0,0,2.18-7.67l1.76.33c-.16,1-.38,2-.63,3h4.38v1.88ZM635,161.67h1.42v-.79H635Zm.12-6.53v.63h1.3v-.63Zm1.3,2.66v-.74h-1.3v.74Zm-.09,7.31c-.13.22-.27.41-.4.61.58.16,1.16.33,1.71.52a3.08,3.08,0,0,0,.94-1.13Zm4,0a3.85,3.85,0,0,1-1,1.78l1.64.72a8.14,8.14,0,0,0,3.2-3.09,25.09,25.09,0,0,1-1.06-3.71,13.65,13.65,0,0,1-.74,1.53,11,11,0,0,0-1-1.07v1.59h-3.78q-.2.4-.42.81h1.61v-.52h1.67v.52H642v1.44Zm-2.32-10v.63h1.4v-.63Zm1.4,2.66v-.74H638v.74Zm-1.4,3.08v.79h1.48v-.79Zm6.16-2.95a25.08,25.08,0,0,0,1,4.34,21.38,21.38,0,0,0,.81-4.34Z"
          fill="#002a5e"
        />
        <path
          d="M654.29,162.66v4.65c0,1-.16,1.51-.72,1.83a4.29,4.29,0,0,1-2.43.4,7.56,7.56,0,0,0-.54-1.94c.65,0,1.26,0,1.48,0s.3-.07.3-.32v-4.07c-.59.18-1.15.32-1.69.47l-.47-2q.94-.21,2.16-.54v-3h-1.83v-2h1.83v-3.39h1.91v3.39h1.39v2h-1.39v2.5l1.37-.38.27,1.93ZM658,161.2c0,2.54-.26,6.07-1.84,8.32a7.21,7.21,0,0,0-1.57-1.06c1.44-2.05,1.57-5,1.57-7.26v-5.34H660v-3.08h2.05v.88h4.1v1.48h-4.1v.72h3.4l.29,0,1.32.25a21.28,21.28,0,0,1-.89,2.92l-1.6-.34a10.78,10.78,0,0,0,.36-1.28h-3.22V158l2.43-.21.12,1.15-2.55.23c0,.35.11.49.52.49h2a10.14,10.14,0,0,0,1.52-.11c0,.45.07.85.12,1.26a7.05,7.05,0,0,1-1.51.11h-2.56c-1.26,0-1.69-.41-1.81-1.24l-1.7.18-.16-1.53,1.82-.16v-.83h-2Zm9.2,2.72a19,19,0,0,1-1.75.87,5.51,5.51,0,0,0,2.05,2.91,6.71,6.71,0,0,0-1.17,1.32,6.82,6.82,0,0,1-2.12-3.71c-.22.09-.43.14-.63.21a3.45,3.45,0,0,1-.42,3.46,1.75,1.75,0,0,1-1.42.6c-.29,0-.63,0-.93,0a3.47,3.47,0,0,0-.42-1.5,6.85,6.85,0,0,0,1,.08.67.67,0,0,0,.63-.28,1.6,1.6,0,0,0,.25-1.06A17.63,17.63,0,0,1,658,169a7.15,7.15,0,0,0-.78-1.1A13.7,13.7,0,0,0,662,165.6a2.14,2.14,0,0,0-.2-.4,16.83,16.83,0,0,1-3.4,1.6,4.13,4.13,0,0,0-.7-1,11.86,11.86,0,0,0,3.53-1.37c-.11-.09-.18-.2-.29-.29a18.74,18.74,0,0,1-2.34.83,5.88,5.88,0,0,0-.58-1,16.16,16.16,0,0,0,3.3-1.17h-3v-1.37H667v1.37h-3.75a7.39,7.39,0,0,1-1.13.75,4.52,4.52,0,0,1,.85,1,29.92,29.92,0,0,0,3-1.47Z"
          fill="#002a5e"
        />
        <path
          d="M671.79,161.22a25.58,25.58,0,0,0-3.28-1.85l1.08-1.61a18.68,18.68,0,0,1,3.35,1.68ZM669,167.81a47.14,47.14,0,0,0,3-5.53l1.65,1.43c-.81,1.85-1.82,3.81-2.77,5.54ZM670.53,153a25.36,25.36,0,0,1,3.18,1.73l-1.24,1.75a20.8,20.8,0,0,0-3.13-1.91Zm14.31,14.12c0,1.08-.18,1.68-.83,2a6.21,6.21,0,0,1-2.93.42,7.4,7.4,0,0,0-.63-2.16c.81,0,1.69,0,2,0s.36-.09.36-.37V155.3h-7.06v14.24h-2V153.37h11.11Zm-2.77-8.8h-5.58v-1.77h5.58Zm-3.66,7.27v1h-1.63v-7.13h5v6.09Zm0-4.32v2.54h1.64v-2.54Z"
          fill="#002a5e"
        />
        <path
          d="M700.86,167.36c.61,0,.72-.3.81-2.39a6.83,6.83,0,0,0,2,.84c-.23,2.85-.79,3.59-2.57,3.59h-2.38c-2.14,0-2.64-.65-2.64-2.67v-2.88h-2.18c-.38,2.86-1.44,4.75-5.89,5.76a7,7,0,0,0-1.19-1.91c3.69-.68,4.47-1.89,4.77-3.85h-2.19V153.28h11.77v10.57H698.2v2.86c0,.58.1.65.79.65Zm-9.43-11.09H699v-1.12h-7.52Zm0,2.84H699V158h-7.52Zm0,2.87H699v-1.12h-7.52Z"
          fill="#002a5e"
        />
        <path
          d="M721,153.32v16.2h-2v-.86H707.77v.9h-1.93V153.32Zm-2,13.74V154.94H707.77v12.12Zm-.4-2.58H716.2v1.13c0,.69-.15.91-.65,1.07a8.09,8.09,0,0,1-2.2.16,4.43,4.43,0,0,0-.43-1.08c.5,0,1.1,0,1.26,0s.25-.06.25-.22v-1.06h-3.19a7.45,7.45,0,0,1,1.21,1.44l-1.42.72a7.78,7.78,0,0,0-1.24-1.62l1.13-.54h-2.79v-1.15h6.3v-.52h1.77v.52h.95a6.32,6.32,0,0,0-.52-.61c-3,.1-5.94.19-8.14.23l-.07-1.14h4v-.59h-3.56v-3.67h3.56V157h-4.07v-1.17h4.07v-.67h1.75v.67h4.1V157h-4.1v.52h3.62v3.67h-.9a10.27,10.27,0,0,1,1.49,1.41l-.95.7h1.08Zm-8-5.53h1.92v-.52h-1.92Zm1.92,1.37v-.52h-1.92v.52Zm1.75-1.89V159h1.91v-.52Zm1.91,1.37h-1.91v.52h1.91Zm-1.91,2,1.42,0-.18-.14.61-.42h-1.85Z"
          fill="#002a5e"
        />
        <path
          d="M729.5,154.22c-.47,1.53-1.08,3.49-1.57,4.89a5.64,5.64,0,0,1,1.32,3.62c0,1.17-.24,1.91-.82,2.31a2.18,2.18,0,0,1-1,.39,7,7,0,0,1-1.15,0,4.51,4.51,0,0,0-.47-1.82c.32,0,.57,0,.77,0a1,1,0,0,0,.51-.16c.21-.16.28-.52.28-1a4.82,4.82,0,0,0-1.24-3.17c.36-1.12.72-2.7,1-3.92h-1.41v14.13h-1.85v-16h3.91l.3-.09Zm10.24,5.15a23.05,23.05,0,0,1-2.12,1.31,14.62,14.62,0,0,0,2.72,5A6.64,6.64,0,0,0,739,167a18.43,18.43,0,0,1-2.87-5.49l-.07,0c.87,2.86.47,5.86-.54,6.86a2.44,2.44,0,0,1-2.11.91c-.37,0-.95,0-1.45,0a4.62,4.62,0,0,0-.51-1.91,14.31,14.31,0,0,0,1.59.09.91.91,0,0,0,1-.51,3.91,3.91,0,0,0,.56-2.32A18.43,18.43,0,0,1,730,167.2a7.12,7.12,0,0,0-1.26-1.5,14.06,14.06,0,0,0,5.6-3.07c-.07-.29-.15-.62-.25-.94a20.26,20.26,0,0,1-3.5,2.21,7.53,7.53,0,0,0-1.13-1.51,14.84,14.84,0,0,0,4-2.21,7.39,7.39,0,0,0-.63-1,17.2,17.2,0,0,1-2.9,1.7,12.74,12.74,0,0,0-1.24-1.52,14.22,14.22,0,0,0,3.71-2h-3.13v-1.84h2.84a11.63,11.63,0,0,0-1.71-1.82l1.57-.95a9.67,9.67,0,0,1,2.14,2.16l-.86.61h1.62a17.62,17.62,0,0,0,1.63-2.79l2.06.57c-.49.8-1,1.57-1.5,2.22h2.67v1.85H735a8.62,8.62,0,0,1-.76.72,7.36,7.36,0,0,1,1.21,1.88,25.07,25.07,0,0,0,3-2Z"
          fill="#002a5e"
        />
        <circle cx="667.44" cy="92.33" r="8" fill="#002a5e" />
        <circle cx="704.52" cy="92.33" r="8" fill="#002a5e" />
        <circle cx="686.32" cy="84.05" r="8" fill="#002a5e" />
        <path d="M702.66,112.3a16.35,16.35,0,0,0-32.69,0Z" fill="#002a5e" />
        <rect
          x="666.82"
          y="41.12"
          width="39.57"
          height="27.81"
          rx="2.49"
          fill="#002a5e"
        />
        <circle cx="677.99" cy="57.15" r="1.67" fill="#fff" />
        <circle cx="690.28" cy="55.11" r="1.67" fill="#fff" />
        <circle cx="685.45" cy="51.1" r="1.67" fill="#fff" />
        <polyline
          points="677.99 57.15 685.52 51.4 690.28 55.03 698.7 48.38"
          fill="none"
          stroke="#fff"
          strokeWidth="0.75"
        />
        <polyline
          points="701.54 62.22 672.98 62.22 672.98 46.77"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <polygon
          points="700.06 46.79 694.95 47.95 698.5 51.79 700.06 46.79"
          fill="#fff"
        />
        <polygon
          points="686.61 72.68 690.07 66.68 683.14 66.68 686.61 72.68"
          fill="#002a5e"
        />
        <path
          d="M687.18,121.58v-1a17.35,17.35,0,0,1,34.69,0v1Z"
          fill="#002a5e"
        />
        <path
          d="M704.52,104.24a16.34,16.34,0,0,1,16.35,16.34H688.18a16.34,16.34,0,0,1,16.34-16.34m0-2a18.36,18.36,0,0,0-18.34,18.34v2h36.69v-2a18.37,18.37,0,0,0-18.35-18.34Z"
          fill="#f8a908"
        />
        <path
          d="M650.09,121.58v-1a17.35,17.35,0,0,1,34.69,0v1Z"
          fill="#002a5e"
        />
        <path
          d="M667.44,104.24a16.34,16.34,0,0,1,16.34,16.34H651.09a16.34,16.34,0,0,1,16.35-16.34m0-2a18.37,18.37,0,0,0-18.35,18.34v2h36.69v-2a18.36,18.36,0,0,0-18.34-18.34Z"
          fill="#f8a908"
        />
      </g>
    </SVG>
  );
};

export default Chart23;
